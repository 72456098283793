<template>

  <v-row class="align-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on" color="primary">
          <v-icon>
            mdi-dots-grid
          </v-icon>
        </v-btn>
      </template>
      <v-card width="400" class="pa-3">
        <v-list dense>
          <v-list-item>
            <strong>Switch to:</strong>
          </v-list-item>
          <v-list-item-group active-class="border">
            <v-list-item v-for="(item, i) in app_menu" :key="i" :to="item.link">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <div class="d-flex logo">
      <v-btn normal text color="white" to="/">
        <img src="@/assets/logo.png" alt="Logo" />
        <span>TRANSIMEX</span>
      </v-btn>
    </div>



    <Menu></Menu>
    <v-spacer></v-spacer>
    <v-btn class="mr-5" text small color="success" v-if="this.$store.getters.role.includes('admin')">Admin mode</v-btn>
    <v-btn small color="primary" class="d-flex" @click="checkSW">
      <span class="text-default font-weight-bold subtitle-1">S</span>
      <span class="text-success font-weight-bold subtitle-1">W</span>
    </v-btn>
    <v-btn text @click="darkMode()">
      <v-icon>
        mdi-theme-light-dark
      </v-icon>
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on" color="primary">
          <v-icon>
            mdi-bell
          </v-icon>
        </v-btn>
      </template>
      <v-card width="400" class="pa-3">
        <i>No notifications ...</i>
      </v-card>
    </v-menu>
    <v-menu bottom min-width="200px" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-avatar size="30">
            <v-icon>
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-card class="px-3">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ loggedUser.counterparties[0].name }}</v-list-item-title>
            <v-list-item-subtitle>{{ loggedUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-content class="justify-center">
          <div class="mx-auto">
            <v-btn small depressed text @click="profileDialog = !profileDialog">
              Manage account
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn @click="logout" small depressed rounded text>
              Log out
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
    <v-dialog v-model="profileDialog" width="700px" @click:outside="profileDialog = false">
      <Profile @close="profileDialog = false" :user="loggedUser" />
    </v-dialog>

    <v-dialog v-model="swDialog" width="1200px" >
      <sw :close.sync="swDialog" :carrier-data.sync="carrierData" ></sw>
    </v-dialog>
    <v-overlay :value="overlay" style="height:100vh">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>
<script>
import Menu from './menu/Menu.vue';
import Profile from './Profile.vue';
import CounterpartyEdit from '../counterparty/CounterpartyEdit.vue';
import { EventBus } from '@/helpers/eventBus';
export default {
  name: "Header",
  data: () => ({
    profileDialog: false,
    swDialog: false,
    carrierData: null,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    user: {
      initials: "JD",
      fullName: "John Doe",
      email: "john.doe@doe.com",
    },
    app_menu: [
      {
        icon: "mdi-view-list",
        text: "TMS Dispatch",
        link: "/dispatch/loads"
      },
      {
        icon: "mdi-view-dashboard-variant",
        text: "Dashboards",
        link: "/dashboard-beta"
      },
      {
        icon: "mdi-truck-fast-outline",
        text: "Load board",
        link: "/board"
      },
      {
        icon: "mdi-file-tree-outline",
        text: "CRM",
        link: "/crm"
      },
    ],
    overlay: false
  }),
  mounted() {
    EventBus.$on('update:carrier-data', (val) => {
      this.carrierData = {...this.carrierData, ...val};
      this.$store.state.users.inEdit = {...this.$store.state.users.inEdit, ...val}
    })
  },
  watch: {
    swDialog(val) {
      if (!val) {
        this.$store.commit('users/newInEdit', { roles: ['carrier'] });
      }
    }
  },
  methods: {
    checkSW () {
      this.swDialog = true
    },
    editProfile() {
      this.profileDialog = true
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      this.overlay = true
      setTimeout(() => {
        this.$router.push('/logout')
        this.overlay = false
      }, 2000)
    }
  },
  components: { Menu, Profile, CounterpartyEdit },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user
    }
  }
}
</script>
