<template>
  <div class="mt-5">
    <v-text-field append-icon="mdi-magnify" clearable @click:append="getItems()" v-model="search"
      v-on:keyup.enter="getItems" label="Quickbooks counterparty" hide-details="auto" dense outlined />
    <v-card outlined class="mt-3" :loading="loading || createLoading" loader-height="10">
      <v-list shaped dense>
        <v-subheader class="px-4 d-none" v-if="items === null">Searching ...</v-subheader>
        <v-subheader class="px-4" v-else-if="items.length >= 1">Results {{ items? items.length : '' }}</v-subheader>
        <div class="px-4" v-else>
          <div class="red--text" v-if="notFound">Quickbooks counterparty not found. </div>
          <strong v-if="notFound">Create it?</strong>
          <v-btn v-if="notFound" color="success" text class="my-1" @click="createQBCounterparty">Yes</v-btn>
        </div>

        <v-alert dense text type="success" v-if="createdQB">
          Counterparty created!
        </v-alert>
        <v-alert dense text type="success" v-if="syncedQB">
          Counterparty linked!
        </v-alert>
        <v-alert dense text type="error" v-if="syncedQBerr">
          Counterparty already linked!
        </v-alert>
        <v-list-item-group>
          <v-list-item v-for="item in items" @click="linkCounterparty(item)">
            <v-list-item-content>
              <v-list-item-title>{{ item.CompanyName }} ({{ item.CurrencyRef.value }})</v-list-item-title>
              <v-list-item-subtitle>{{ item.BillAddr?.City ?? '' }}, {{
                item.BillAddr?.CountrySubDivisionCode ?? ''
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import config from "@/config";
export default {
  name: 'QuickbooksSync',
  props: {
    accountCompanyId: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    counterparty: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'customers'
    }
  },
  data() {
    return {
      items: null,
      search: null,
      loading: false,
      createdQB: false,
      syncedQB: false,
      syncedQBerr: false,
      createLoading: false,
      notFound: false
    }
  },
  mounted() {
    this.search = this.counterparty.name
    if (this.search) {
      this.getItems()
    }
  },
  methods: {
    getItems() {
      if (this.search) {
        this.loading = true
        axios.get(config.apiUrl + '/api/quickbooks/' + this.type, {
          params: {
            company: this.accountCompanyId,
            search: this.search
          }
        }).then((response) => {
          this.items = response.data.filter((i) => {
            return (typeof i.Level === 'undefined' && i.CurrencyRef.value === this.currency)
          })
          this.loading = false
          this.notFound = true
        })
      }
    },
    linkCounterparty(data) {
      const ttype = this.type === 'customers' ? 'link-customer' : 'link-carrier'
      axios.post(config.apiUrl + '/api/quickbooks/' + ttype, {
        "accounting_company_id": this.accountCompanyId,
        "type": this.type === 'customers' ? "counterparty" : 'carrier',
        "local_identifier": this.counterparty.id,
        "remote_identifier": data.Id,
        "currency": this.currency
      }).then((response) => {
        this.syncedQB = true
        this.$emit('getLinked')
      }).catch(() => {
        this.syncedQBerr = true
      })
    },
    createQBCounterparty() {
      axios.get(config.apiUrl + '/api/counterparty/' + this.counterparty.id).then((response) => {
        this.counterparty = response.data
        const data = {
          FullyQualifiedName: this.counterparty.name,
          PrimaryEmailAddr: {
            Address: this.counterparty.counterparty_contacts.find((i) => { return i.primary && i.type === 'email' })?.contact || this.counterparty.counterparty_contacts.find((i) => { return i.type === 'email' })?.contact || null,
          },
          DisplayName: this.counterparty.name,
          PrimaryPhone: {
            FreeFormNumber: this.counterparty.counterparty_contacts.find((i) => { return i.primary && i.type === 'phone' })?.contact || this.counterparty.counterparty_contacts.find((i) => { return i.type === 'phone' })?.contact || null
          },
          CompanyName: this.counterparty.name,
          BillAddr: {
            Country: this.counterparty.counterparty_addresses.find((i) => { return i.is_primary })?.country_code || this.counterparty.counterparty_addresses[0]?.country_code || null,
            City: this.counterparty.counterparty_addresses.find((i) => { return i.is_primary })?.city || this.counterparty.counterparty_addresses[0]?.city || null,
            PostalCode: this.counterparty.counterparty_addresses.find((i) => { return i.is_primary })?.postcode || this.counterparty.counterparty_addresses[0]?.postcode || null,
            Line1: this.counterparty.counterparty_addresses.find((i) => { return i.is_primary })?.address1 || this.counterparty.counterparty_addresses[0]?.address1 || null,
          },
          CurrencyRef: {
            value: this.currency
          }
        }
        this.createLoading = true
        axios.post(config.apiUrl + '/api/quickbooks/' + (this.type === 'customers' ? 'customers' : 'carriers'), data, {
          params: {
            company: this.accountCompanyId
          }
        }).then((response) => {
          this.createdQB = true
          this.linkCounterparty(response.data[this.type === 'customers' ? 'Customer' : 'Vendor'])
          this.createLoading = false
          this.notFound = false
        }).catch((error) => {
          if (error.response.data.errors.common[0].code === '6240') {
            this.createLoading = false
            const prevCurrency = data.CurrencyRef.value
            data.FullyQualifiedName = data.FullyQualifiedName + (prevCurrency !== 'USD' ? ' (CAD)' : ' (USD)')
            data.DisplayName = data.DisplayName + (prevCurrency !== 'USD' ? ' (CAD)' : ' (USD)')
            data.CompanyName = data.CompanyName + (prevCurrency !== 'USD' ? ' (CAD)' : ' (USD)')
            data.CurrencyRef.value = prevCurrency !== 'USD' ? 'CAD' : 'USD'
            axios.post(config.apiUrl + '/api/quickbooks/'  + (this.type === 'customers' ? 'customers' : 'carriers'), data, {
              params: {
                company: this.accountCompanyId
              }
            }).then((response) => {
              this.createdQB = true
              this.linkCounterparty(response.data[this.type === 'customers' ? 'Customer' : 'Vendor'])
              this.createLoading = false
              this.notFound = false
            })
          }
        })
      })
    }
  }
}
</script>
