<template>
  <v-form class="invoice" :disabled="loading">
    <div class="gray-bg">
      <div class="invoice-header invoice-container">
        <v-row>
          <v-col md="8">
            <v-row class="mt-1">
              <v-col>
                <v-select dense outlined @change="QBchanged" :items="companies" item-text="name"
                  v-model="invoice.billing.vendor" :value="invoice.billing.vendor" item-id="id" return-object
                  label="Select company" hide-details="auto" />
              </v-col>
              <v-col>
                <v-select dense outlined @change="QBchanged" :items="currency" v-model="invoice.currency"
                  :value="invoice.currency" label="Currency" hide-details="auto" />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="forceUpdate">
              <v-col md="6">
                <CounterpartySelect class="my-3" v-model="invoice.billing.customer" :roles="['customer']"
                  @input="customerChanged" item-text="name" :quickbooks="true"
                  :account-company-id="invoice.billing?.vendor?.id" :currency="invoice.currency" />
                <CounterpartyPrimaryAddress label="Billing address" v-model="invoice.billing.address"
                  :addresses="invoice.billing.customer.counterparty_addresses" />
                <v-text-field class="my-3 mini" dense outlined v-model="invoice.billing.email" hide-details="auto"
                  label="Billing email" />
              </v-col>
              <v-col md="6">
                <v-menu v-model="showDate" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="invoice.date" hide-details="auto" label="Invoice date" readonly outlined
                      dense clearable class="my-3 mini" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="invoice.date" @input="showDate = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="showDue" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="invoice.due" hide-details="auto" label="Due date" readonly outlined dense
                      clearable class="my-3 mini" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="invoice.due" @input="showDue = false"></v-date-picker>
                </v-menu>
                <v-select label="Terms" @change="autoFillTerm(invoice.payment_term_id)" :items="paymentTerms" v-model="invoice.payment_term_id" item-id="id" hide-details="auto"
                  item-text="name" item-value="id" outlined class="my-3 mini" dense />
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <v-textarea outlined rows="4" class="mini my-4 invoice-bank" hide-details="auto"
              label="Wire Transfer / ACH / EFT" v-model="invoice.billing?.vendor?.meta.wire"></v-textarea>
            <v-textarea outlined rows="4" class="mini my-4 invoice-bank" hide-details="auto" label="Check Payments"
              v-model="invoice.billing?.vendor?.meta.check"></v-textarea>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="invoice-items invoice-container mt-3">
      <v-simple-table class="table-bordered table-items" dense>
        <thead>
          <tr>
            <th>#</th>
            <th>Product/Service</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Rate</th>
            <th>{{ taxes.length >= 1 ? 'Sales tax' : 'Tax' }}</th>
            <th v-if="taxes.length >= 1">Taxes</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in invoice.table">
            <td>{{ idx+ 1 }}</td>
            <td>
              <v-combobox :items="services" v-model="item.product" :value="item.product" flat solo dense
                hide-details="auto" />
            </td>
            <td>
              <v-text-field v-model="item.desc" flat solo dense hide-details="auto" clearable />
            </td>
            <td width="100">
              <v-text-field type="number" v-model="item.qty" flat solo dense hide-details="auto" />
            </td>
            <td width="200">
              <v-text-field type="number" v-model="item.rate" flat solo dense hide-details="auto" />
            </td>
            <td class="d-flex justify-content-center h-100">
              <v-select flat solo dense hide-details="auto" :items="taxes_list" v-model="invoice.table[idx].sales_tax" return-object
                    item-value="id" class="my-2">
                    <template v-slot:item="{ item }">
                      ({{ item.state }}) - {{ item.type }} {{ item.rate }}%
                    </template>
                    <template v-slot:selection="{ item }">
                      ({{ item.state }}) - {{ item.type }} {{ item.rate }}%
                    </template>
                  </v-select>
            </td>
            <td>
              <v-text-field disabled type="number" v-model="item.totalTaxes" flat solo dense hide-details="auto" />
            </td>
            <td >
              <v-text-field disabled type="number" v-model="item.amount" :value="calcRow" flat solo dense hide-details="auto" />
            </td>
            <td>
              <v-icon color="grey darken-1" @click="remove(idx)">mdi-trash-can-outline</v-icon>
            </td>

          </tr>
        </tbody>
      </v-simple-table>
      <div class="d-flex invoice-sub my-3">
        <v-btn small @click="add">Add new line</v-btn>
        <v-spacer></v-spacer>
        <div class="mr-10">
          <TaxIncludedSelect v-model="invoice.taxes" outlined dense />
        </div>
        <div>
          <!--<p class="my-0 text-right"  v-if="invoice.table[0].sales_tax && invoice.taxes !== 'Zero rate'">
            {{ ('Includes (' + invoice.table[0].sales_tax?.state + ') ' + invoice.table[0].sales_tax?.type + ' ' + invoice.table[0].sales_tax?.rate + '% ')}}
            on {{ parseFloat(calcTotal * 100 / (100 + invoice.table[0].sales_tax?.rate)).toFixed(2) }}
            <strong class="ml-3">{{ parseFloat(calcTotal - (calcTotal * 100 / (100 + invoice.table[0].sales_tax?.rate))).toFixed(2)
            }}</strong>
            Without Tax of <strong>{{ overallTax }} ({{ invoice.table[0].sales_tax?.state + " - " + invoice.table[0].sales_tax?.type + ' ' + invoice.table[0].sales_tax?.rate + '%' }})</strong> : {{ calcTotal - overallTax }}
          </p>-->
          <h5 class="text-right">Total: <span class="ml-3">{{ calcTotal }}</span></h5>
        </div>

      </div>
    </div>

    <div class="gray-bg">
      <div class="invoice-dir invoice-container mt-7" v-if="load.load_carriers.length >= 1">
        <div class="my-3" v-for="(carrier, idx) in load.load_carriers" :key="idx">
          <v-row class="mx-0">
            <v-col md="auto" v-if="load.load_carriers.length > 1">
              <v-chip label color="black" dark>
                {{ idx+ 1 }}
              </v-chip>
            </v-col>
            <v-col md="3" v-if="carrier.load_carrier_shippers.length !== 0"
              v-for="(shipper, idx) in carrier.load_carrier_shippers">
              <v-card outlined>
                <v-card-text>
                  <div class="text-overline d-flex">
                    Shipper {{ idx+ 1 }}
                    <span class="ml-auto">{{ shipper.date }}</span>
                  </div>
                  <span>{{ shipper.shipper !== null ? shipper.shipper.name : '' }}, {{
                    shipper? shipper.location : ''
                  }}</span> <br>
                  <span v-if="shipper">Type: {{ shipper.type ?? '-' }}, Weight: {{ shipper.weight ?? '-' }}, Qty: {{
                    shipper.quantity ??
                      '-'
                  }}</span>
                  <br>
                  <i v-if="shipper">{{ shipper.description ? (shipper.description + '\n') : '' }} </i>
                  <i v-if="shipper">{{ shipper.po_numbers ? (shipper.po_numbers) : '' }}</i>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" v-if="carrier.load_carrier_consignees.length !== 0"
              v-for="(consignee, idx) in carrier.load_carrier_consignees">
              <v-card outlined>
                <v-card-text>
                  <div class="text-overline d-flex">
                    Consignee {{ idx+ 1 }}
                    <span class="ml-auto">{{ consignee.date }}</span>
                  </div>
                  <span>{{ consignee.consignee !== null ? consignee.consignee.name : '' }}, {{
                    consignee?
                  consignee.location : '' }}</span> <br>
                  <span v-if="consignee">Type: {{ consignee.type ?? '-' }}, Weight: {{ consignee.weight ?? '-' }}, Qty:
                    {{
                      consignee.quantity ?? '-'
                    }}</span>
                  <br>
                  <i v-if="consignee">{{ consignee.description ? (consignee.description + '\n') : '' }} </i>
                  <i v-if="consignee">{{ consignee.po_numbers ?? '' }}</i>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="invoice-container">
      <v-row class="mx-0">
        <v-col md="4">
          <v-card outlined class="my-4" style="min-height: 107px;pointer-events:all" @click="files = true">
            <v-card-text>
              <v-icon class="mr-3">
                mdi-attachment
              </v-icon>Invoice attachments <span v-if="invoice.files.length >= 1">({{ invoice.files.length }})</span>

              <div v-if="invoice.files.length >= 1" class="invoice-attach" v-for="(file, idx) in invoice.files">
                {{ idx+ 1 }} - <span>{{ file.name }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-textarea outlined rows="3" class="my-4 invoice-bank" hide-details="auto" label="Message on invoice"
            v-model="invoice.message"></v-textarea>
        </v-col>
        <v-col md="4">
          <v-textarea outlined rows="3" class="my-4 invoice-bank" hide-details="auto" label="Invoice notes (internal)"
            v-model="invoice.notes"></v-textarea>
        </v-col>
      </v-row>
      <v-col>
        <v-alert
            color="blue-grey"
            dark
            style="font-size: 18px; line-height: 1;"
        >
          <v-checkbox id="createBills" v-model="createBills" class="d-inline-block mt-0" hide-details></v-checkbox> <label for="createBills" style="position: relative; top: -2px;">Create bills based on load data (existing bills will not be modified)</label>
        </v-alert>
      </v-col>

      <!-- <v-col>

        <div class="d-flex align-items-center">
          <v-checkbox v-model="createBill"></v-checkbox>
          Create Bill?
        </div>

        <div v-if="createBill">
          <v-row>
            <v-col md="12">
              <Bill
                  v-for="(item, idx) in allBills"
                  :item="item"
                  :idx="idx"
                  :invoice="invoice"
                  :payment-terms="paymentTerms"
                  :currency="currency"
                  :load="load"
                  @QBchanged="QBchanged"
                  @fucking-vuetify="QBchanged"
                  @remove="allBills.splice(idx, 1)"
              ></Bill>
            </v-col>
          </v-row>
          <v-btn @click="addBill">ADD BILL</v-btn>
        </div>
      </v-col> -->
    </div>
    <div class="invoice-bottom">
      <div class="invoice-container">
        <v-toolbar flat dense color="transparent">
          <v-btn class="px-10" @click="$emit('saved')" small color="error">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save(false)" class="ml-3 px-10" style="pointer-events:all" small dark color="secondary" :disabled="loading">Save</v-btn>
          <v-btn @click="openPreview" class="ml-3 px-10" style="pointer-events:all" small dark color="green">Save and send</v-btn>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            width="100"
            height="10"
            color="secondary"
          />
        </v-toolbar>
      </div>
    </div>

    <v-dialog v-model="sendPreview" width="1000px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>Send invoice to customer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="sendPreview = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form :disabled="loading">
            <v-row>
              <v-col md="6">
                <v-select label="From" outlined dense v-model="email.from" :items="fromEmails" />
                <v-combobox label="To" multiple outlined dense v-model="email.to" :items="[invoice.billing.email]" />
                <v-card outlined style="min-height: 107px;pointer-events:all" @click="files = true">
                  <v-card-text>
                    <v-icon class="mr-3">
                      mdi-attachment
                    </v-icon>Invoice attachments <span v-if="invoice.files.length >= 1">({{
                      invoice.files.length
                    }})</span>

                    <div v-if="invoice.files.length >= 1" class="invoice-attach" v-for="(file, idx) in invoice.files">
                      {{ idx+ 1 }} - <span>{{ file.name }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <strong>Document: Load #{{ load.id }} </strong>
                <div class="mt-3">
                  <v-textarea rows="7" label="Message" v-model="invoiceMessage" dense outlined></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-divider></v-divider>
          <div class="d-flex justify-content-center">
            <v-btn @click="save(true)" color="secondary" :disabled="loading" class="px-8">Send</v-btn>
          </div>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            width="100"
            height="10"
            color="secondary"
          />

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="load" v-model="files" width="1000px" max-height="800px" scrollable>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>File manager - #{{ load.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="files = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-3">
          <Files :type="'load'" :id="load.id" :select="true" @selectedAtt="getAttach" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      Invoice saved!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import config from "@/config";
import axios from "@/plugins/axios";
import CounterpartySelect from '@/components/counterparty/CounterpartySelect.vue';
import LoadLocationSelect from "@/components/loads/LoadLocationSelect";
import CounterpartyContact from "@/components/counterparty/CounterpartyContact";
import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import datePicker from 'vue-bootstrap-datetimepicker';
import dayjs from "dayjs";
import Files from "@/components/files/List.vue";
import CounterpartyPrimaryAddress from "@/components/counterparty/CounterpartyPrimaryAddress.vue";
import Bill from "@/views/accounting/Bill.vue";
import TaxIncludedSelect from "@/components/loads/TaxIncludedSelect.vue";

export default {
  name: "InvoiceEdit",
  props: {
    load: {
      type: Object,
      required: false
    },
    invoiceObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      allBills: [],
      email:
      {
        from: 'noreplay@shiptg.com',
        to: []
      },
      createBill: false,
      sendPreview: false,
      snackbar: false,
      loading: false,
      timeout: 2000,
      forceUpdate: true,
      invoiceMessage: 'Hello, ' + '\n' + 'We inform you that we have issued an invoice.' + '\n' + 'You can view the document by clicking on the button below.' + '\n' + 'Thank you for your cooperation!',
      invoice: {
        billing: {
          vendor: {
            id: 1,
            name: "US - Transimex Global Corp",
            meta: {
              wire: null,
              check: null
            }
          },
          customer: {},
          address: {},
          email: null
        },
        date: dayjs().format('YYYY-MM-DD'),
        due: dayjs().format('YYYY-MM-DD'),
        terms: null,
        currency: this.load.load_finances.currency,
        ach: 'Ameris Bank TD Bank' + '\n' + 'ABA: 061201754' + '\n' + 'Bank Account: 2049393107 ',
        check: 'Transimex Global Corp.' + '\n' + 'PO BOX 162022 ' + '\n' + 'Atlanta, GA 30321-2022',
        table: [
          {
            product: 'Freight services',
            desc: null,
            qty: 1,
            rate: this.load.load_finances ? this.load.load_finances.total : null,
            amount: this.load.load_finances ? this.load.load_finances.total : null,
            sales_tax: null,
            tax: false,
            totalTaxes: null
          }
        ],
        carriers: this.load.load_carriers ?? [],
        message: 'Thank you for your business and have a great day!',
        notes: null,
        files: [],
        totalDue: 0,
        loadData: this.load,
        taxes: null
      },
      files: false,
      showDate: false,
      showDue: false,
      companies: [],
      dates: {
        created: dayjs().format('YYYY-MM-DD'),
        due: dayjs().format('YYYY-MM-DD')
      },
      currency: ['USD', 'CAD'],
      paymentTerms: [],
      counterparty: {},
      services: ['', 'Freight services', 'Transportation services', 'Other charges'],
      taxes_list: [],
      bills: [],
      createBills: true,
    };
  },
  methods: {
    autoFillTerm: function (termId) {
      let newTerm = this.paymentTerms.find(term => term?.id === termId)
      if(this.invoice.date && newTerm?.days) {
        let date = dayjs(this.invoice.date)
        this.invoice.due = date.add(newTerm.days, 'days').format('YYYY-MM-DD')
        this.invoice.terms = termId
        this.invoice.payment_term_id = termId
      }
    },
    calcTaxes(value) {

    },
    fillBills () {
      this.allBills = []
      for (let bill of this.bills) {
        this.allBills.push(bill)
      }
      for (const loadCarrier of this.load.load_carriers) {
        let exist = this.allBills.find(b => b.carrier_id === loadCarrier.carrier.id)
        if (!exist) {
          this.allBills.push({
            "accounting_company_id": this.invoice.billing?.vendor?.id,
            "load_id": this.load.id,
            "carrier_id": loadCarrier.carrier.id,
            "carrier": loadCarrier.carrier.id,
            "payment_term_id": null,
            "bill_date": null,
            "due_date": null,
            "bill_serial": null,
            "bill_number": null,
            "amount": null,
            "currency": "USD",
            "balance": null,
            "state": "unpaid",
            "mailing_address": null,
            "items": [{
              "desc": "Services",
              "amount": 0
            }],
            "attachments": [],
            "memo": null,
            "qb_id": null,
            "type": 'carrier'
          })
        }
      }
    },
    addBill () {
      this.allBills.push({
        "accounting_company_id": this.invoice.billing?.vendor?.id,
        "load_id": this.load.id,
        "carrier_id": null,
        "carrier": null,
        "payment_term_id": null,
        "bill_date": null,
        "due_date": null,
        "bill_serial": null,
        "bill_number": null,
        "amount": null,
        "currency": "USD",
        "balance": null,
        "state": "unpaid",
        "mailing_address": null,
        "items": [{
          "desc": "Services",
          "amount": 0
        }],
        "attachments": [],
        "memo": null,
        "qb_id": null,
        "type": 'carrier'
      })
    },
    QBchanged() {
      this.forceUpdate = false
      this.$nextTick(() => {
        this.forceUpdate = true
      })
    },
    customerChanged(customer) {
      this.getEmail(customer)
      this.fillInvoiceTerms(customer)
      this.getAddress(customer)
    },
    getAddress(customer) {
      let address = customer.counterparty_addresses.find((i) => {
        return i.is_primary
      })
      this.invoice.billing.address = address || customer.counterparty_addresses[0] || null
    },
    fillInvoiceTerms(customer = false) {
      let item = null
      if(!this.invoice.payment_term_id) {
        if (this.invoice.billing.customer.customer_data) {
          const term = this.paymentTerms.find((i) => {
            return i.id === this.invoice.billing.customer.customer_data.payment_term_id
          });
          let date = dayjs(this.invoice.date)
          this.invoice.due = date.add(term.days, 'days').format('YYYY-MM-DD')
          this.invoice.payment_term_id = term ? term.id : 57
          this.invoice.terms = term ? term.id : 57
        } else {
          this.invoice.payment_term_id = 57
          this.invoice.terms = 57
          let date = dayjs(this.invoice.date)
          this.invoice.due = date.add(30, 'days').format('YYYY-MM-DD')
        }
      } else {
        if(customer) {
          this.autoFillTerm(this.invoice.billing.customer.customer_data.payment_term_id)
        } else this.autoFillTerm(this.invoice.payment_term_id)
      }
    },
    getAttach(files) {
      this.invoice.files = files
    },
    add() {
      this.invoice.table.push(
        {
          product: 'Freight services',
          desc: null,
          qty: 1,
          rate: null,
          amount: null,
          tax: this.isTaxes,
          totalTaxes: null
        }
      )
    },
    remove(idx) {
      this.invoice.table.splice(idx, 1)
    },
    getCounterparty() {
      return axios.get(config.apiUrl + '/api/counterparty/' + this.load.load_finances.customer.id)
        .then((response) => {
          this.counterparty = response.data
          this.invoice.billing.customer = response.data
          this.getAddress(response.data)
          this.getEmail(response.data)
        })
    },
    getTerms() {
      return axios.get(config.apiUrl + '/api/payment-term')
        .then((response) => {
          this.paymentTerms = response.data
          this.fillInvoiceTerms()
        })
    },
    getEmail(customer) {
      if (customer.counterparty_contacts) {
        for (let item of customer.counterparty_contacts) {
          if (item.type === 'email') {
            this.invoice.billing.email = item.contact
          } else {
            this.invoice.billing.email = null
          }
        }
      } else {
        this.invoice.billing.email = ''
      }
    },
    editItem(user) {
      this.$store.state.users.inEdit = user
    },
    openPreview() {
      this.email.to = []
      this.sendPreview = true
      if (this.invoice.billing.email) {
        this.email.to.push(this.invoice.billing.email)
      }
      this.email.to = [...new Set(this.email.to)]
    },
    save(send = false) {
      this.loading = true
      let method = 'post';
      let url = config.apiUrl + '/api/invoice';

      if (this.invoice.id) {
        method = 'patch';
        url += '/' + this.invoice.id;
      }

      let data = this.invoice;

      if (send) {
        data = Object.assign(data, {
          emails: this.email,
          invoiceMessage: this.invoiceMessage
        })
      }

      if (this.createBill) {
        data['bills'] = this.allBills
      }

      data['createBills'] = this.createBills

      axios[method](url, data, {
        params: {
          send: send ? 1 : null
        }
      })
        .then(response => {
          this.loading = false
          this.snackbar = true
          this.sendPreview = false
          this.$emit('saved')
        })
        .catch(error => {
          this.loading = false
          alert(error.response.data.message)
        })
    }
  },
  mounted() {
    this.invoice.due = this.dateChange
    /*setTimeout(() => {
        if (this.isTaxes) {
            this.invoice.table[0].tax = true
            this.invoice.table[0].sales_tax = this.taxes.find(t => t.country === this.destination.country && t.state === this.destination.state)
        } else {
            this.invoice.table[0].tax = false
            this.invoice.table[0].sales_tax = null
        }
    }, 500);*/
  },
  created() {
    if (this.load.load_finances.taxes && !this.invoice.taxes) {
      this.invoice.taxes = this.load.load_finances.taxes;
    }
    this.$nextTick(() => {
      if (this.invoice.billing.email) {
        this.email.to.push(this.invoice.billing.email)
      }
    })
    axios.get(config.apiUrl + '/api/taxes')
      .then(response => {
        this.taxes_list = response.data
        //this.invoice.table[0].sales_tax = { id: 14, state: this.destination.state ? this.destination.state :'CA', rate: 5, type: 'GST' }
      })
    if (this.invoiceObj === null) {
      this.getCounterparty().then(() => {
          this.getTerms()
      })
      axios.get(config.apiUrl + '/api/accounting-company').then((response) => {
        this.companies = response.data
        if (response.data[0]) {
          this.invoice.billing.vendor = response.data[0]
          this.invoice.table = [
            {
              product: 'Freight services',
              desc: this.load.wo ? this.load.wo : null,
              qty: 1,
              rate: this.load.load_finances ? this.load.load_finances.rate : null,
              amount: this.load.load_finances ? this.load.load_finances.rate : null,
              sales_tax: null,
              tax: this.load.load_carriers.at(-1).load_carrier_consignees.at(-1).country === 'CA',
              totalTaxes: null
            }
          ]

          if (this.invoice.loadData && this.invoice.loadData.load_finances.other_charges) {
            for (let tableItem of this.invoice.loadData.load_finances.other_charges) {
              this.invoice.table.push({
                product: 'Charges',
                desc: tableItem.name ? tableItem.name : null,
                qty: 1,
                rate: tableItem.value ? tableItem.value : null,
                amount: tableItem.value ? tableItem.value : null,
                sales_tax: null,
                tax: this.load.load_carriers.at(-1).load_carrier_consignees.at(-1).country === 'CA',
                totalTaxes: null
              })
            }
          }

          axios.get(config.apiUrl + '/api/taxes')
            .then(response => {
              this.taxes_list = response.data
              /*try {
                this.invoice.table[0].sales_tax = this.taxes.find(t => t.country === this.destination.country && t.state === this.destination.state)
                this.invoice.table[1].sales_tax = this.taxes.find(t => t.country === this.destination.country && t.state === this.destination.state)
                this.invoice.table[2].sales_tax = this.taxes.find(t => t.country === this.destination.country && t.state === this.destination.state)
                this.invoice.table[3].sales_tax = this.taxes.find(t => t.country === this.destination.country && t.state === this.destination.state)
              } catch (e) {}*/
            })
        }
      })
    } else {

      this.getTerms()
      this.invoice = this.invoiceObj
      axios.get(config.apiUrl + '/api/accounting-company').then((response) => {
        this.companies = response.data
      })
      this.QBchanged()
    }
    axios.get(config.apiUrl + '/api/bill', {
      params: {
        load_id: this.load.id
      }
    }).then((response) => {
      this.bills = response.data.data
      this.fillBills()
    })
  },
  computed: {
    overallTax() {
      return this.invoice.table.filter(el => el.tax).reduce((acc, row) => acc += parseFloat(row.totalTaxes), 0).toFixed(2)
    },
    dateChange() {
      const date = dayjs(this.invoice.date)
      return date.add(1, 'month').format('YYYY-MM-DD')
    },
    selectCompany() {
      return [this.companies, this.invoice.billing.address]
    },
    taxes() {
      return this.taxes_list.filter((i) => i.accounting_company_id === this.invoice.billing?.vendor?.id)
    },
    isTaxes() {
      return this.load.load_carriers.at(-1).load_carrier_consignees.at(-1).country === 'CA' && this.load.load_carriers.at(0).load_carrier_shippers.at(0).location.endsWith('CA')
    },
    appliedTaxRate () {
      return this.isTaxes ? (this.taxes.find(t => t.state === this.destination.state) || null) : null
    },
    destination() {
      return this.load.load_carriers.at(-1).load_carrier_consignees.at(-1)
    },
    calcTotal() {
      let sum = 0
      for (let item of this.invoice.table) {
        if (item.amount === null) {
          item.amount = 0
        }
        if(this.invoice.taxes === 'After taxes' && !item.tax) {
          sum += parseFloat(item.amount) //parseFloat(item.rate * item.qty)
        } else sum += parseFloat(item.amount)
      }
      this.invoice.totalDue = sum
      return sum
    },
    calcRow() {
      let i = 0
      for (let item of this.invoice.table) {
        if (item.rate === null) {
          item.rate = 0
        }
        if (item.qty === null) {
          item.rate = 0
        }
        item.tax = true
        item.amount = item.qty * (item.rate)

        if (this.invoice.taxes === "Before taxes") {
            item.totalTaxes = parseFloat((item.amount * this.invoice.table[i].sales_tax?.rate) / 100).toFixed(2)
            item.amount = (item.qty * (parseFloat(item.rate)) + parseFloat((item.amount * (this.invoice.table[i]?.sales_tax?.rate ?? 0)) / 100)).toFixed(2)
        } else if (this.invoice.taxes === "After taxes") {
            item.totalTaxes = (item.amount - parseFloat(item.amount * 100 / (100 + (this.invoice.table[i]?.sales_tax?.rate ?? 0)))).toFixed(2)
        } else {
          item.totalTaxes = null
        }

        i++
      }
    },
    fromEmails () {
      return [
        'ar@shiptg.com',
        this.$store.state.auth.user.email
      ]
    }
  },
  components: {TaxIncludedSelect, Bill, CounterpartySelect, LoadLocationSelect, CounterpartyContact, CounterpartyEdit, datePicker, Files, CounterpartyPrimaryAddress },
  watch: {
    'invoice.payment_term_id': {
      handler: function (id) {
          this.autoFillTerm(id)
      }
    },
    'invoice.table': {
      deep: true,
      handler: function(val) {
        if(val.every(el => el.tax === false)) {
          this.invoice.taxes = "Zero rate"
        }
      }
    },
    dateChange(val) {
      this.invoice.due = val
    },
    isTaxes(val) {
      if (val) {
        this.invoice.table[0].tax = true
        //this.invoice.table[0].sales_tax = this.taxes.find(t => t.country === this.destination.country && t.state === this.destination.state)
      } else {
          this.invoice.table[0].tax = false
          //this.invoice.table[0].sales_tax = null
      }
    },
    createBill () {
      this.fillBills()
    },
    selectCompany (value) {
      if (value[0] && value[1] && this.invoiceObj === null) {
        this.invoice.billing.vendor = value[0].find((v) => v.name === value[1].country_code)
      }
    },
    'invoice.taxes' (newValue, oldValue) {
      if (oldValue !== null) {
        if (oldValue === "Zero rate") {
          this.invoice.table.forEach((row) => row.tax = true)
        }
        if (newValue === 'Before taxes') {
          this.invoice.table.forEach((row) => {
            if(row.tax) {
              row.amount = (parseInt(row.rate) * row.qty).toFixed(2) //Math.round((row.amount + (row.amount * (this.appliedTaxRate?.rate ?? 0) / 100) + Number.EPSILON) * 100) / 100
              row.totalTaxes = ((parseInt(row.rate) * row.qty) * row.sales_tax?.rate ?? 0) / 100
            } else {
              row.amount = (parseInt(row.rate) * row.qty).toFixed(2)
              row.totalTaxes = null
            }
          })
        } else {
            this.invoice.table.forEach((row) => {
              row.totalTaxes = null
                if(row.tax) {
                  row.amount = (parseInt(row.rate) * row.qty).toFixed(2) //Math.round((row.amount / (1 + (this.appliedTaxRate?.rate ?? 0)/100) + Number.EPSILON) * 100) / 100
                } else {
                  row.amount = (parseInt(row.rate) * row.qty).toFixed(2)
                }
            })
        }
      }
    }
  }
}
</script>
