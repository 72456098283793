<template>
  <div>
    <v-chip small class="mr-2" v-for="role in counterparty['roles']" label>
      {{ humanize(role) }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "CounterpartyRolesColumn",
  props: {
    counterparty: {
      type: Object,
      required: true
    }
  },
  methods: {
    humanize (role) {
      return role.replace(/_/g, ' ')
          .trim()
          .replace(/\b[A-Z][a-z]+\b/g, function(word) {
            return word.toLowerCase()
          })
          .replace(/^[a-z]/g, function(first) {
            return first.toUpperCase()
          });
    }
  }
}
</script>

<style scoped>

</style>
