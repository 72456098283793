<template>
  <div>
    <v-card outlined tile>
      <div class="py-3 text-center">
        <v-icon x-large color="success">{{ icon }}</v-icon>
      </div>
      <hr class="m-0">
      <v-card-text class="text-center">
        <h4>{{ data.count }}</h4>
        <strong>{{ name }}</strong>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      required: true
    },
    count: {
      type: Boolean,
      required: true
    },
    setColors: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      default: 350
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        colors: this.setColors,
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals

            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          }
        },
        legend: {
          show: false,
        }
      },
    }
  },
  computed: {
  },
  methods: {

    colored(value) {
      return value < 0 ? 'red' : 'green'
    }
  }
}
</script>