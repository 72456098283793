import Vue from "vue";
import * as VueGoogleMaps from 'vue2-best-google-maps'
import config from "@/config";


const gmaps = Vue.use(VueGoogleMaps, {
  load: {
      key: config.googleApiKey,
  },
  installComponents: true,
});
export default gmaps
