<script>
import config from "@/config";
import axios from "@/plugins/axios";
const gmap_icon_path = 'https://maps.gstatic.com/mapfiles/ms2/micons/'

export default {
  name: "RouteMap",
  emits: ['sendMarkers'],
  props: {
    carrier: {
      type: Object,
      required: true,
    },
    load: {
      type: Object,
      required: false
    },
    currentLocation: {
      type: Object,
      required: false
    },
    traking_id: {
      type: [String],
      required: true
    },
    socket_connection: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      google: '',
      textCopied: false,
      coordinates: '',
      dialog: false,
      locationInfo: {},
      mapRouteMarkers: [],
      carrierLocation: null,
      map: {
        center: {lat: 0, lng: 0},
        zoom: 5,
        markers: [],
        path: [],
        polylineOptions: {
          strokeColor: '#ffffff',
          strokeOpacity: 1.0,
          strokeWeight: 1,
          icons: [{
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 1,
              strokeColor: '#ff001d',
            },
            offset: '0%',
            repeat: '5px'
          }]
        },
      }
    }
  },
  watch: {
    currentLocation(location) {
      this.map.center = {lat: location.lat, lng: location.lng};
    },
    socket_connection(connection) {
      if (!connection) {
        this.destroySocket()
      } else {
        this.runSocket()
      }
    }
  },
  methods: {
    destroySocket() {
      this.$socketTracking.emit('leave_room',
          `carrier:location:${this.traking_id}`)
    },
    getOpenStreetUrl(street) {
      const url = 'https://api.openrouteservice.org'
      const key = '5b3ce3597851110001cf6248911f3d66c2df4a369a9367169876c5e0'
      return `${url}/geocode/search?api_key=${key}&text=${street}=address,locality`
    },
    convertTimeStamp(timestamp) {
      const dateObj = new Date(timestamp);
      const year = String(dateObj.getFullYear() % 100).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const datePart = `${month}/${day}/${year}`;

      let hours = dateObj.getUTCHours();
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      hours = String(hours).padStart(2, '0');

      const timePart = `${hours}:${minutes} ${ampm}`;

      return `${timePart} - ${datePart}`;
    },
    showMapModal(index) {
      this.locationInfo = this.map.markers[index]
      this.dialog = true
    },
    async fetchCarrierLocations() {
      const url = `${config.trackingApi}/get-user-locations/${this.traking_id}`
      await axios.get(url).then((response) => {
        const data = response.data
        for (let i = 0; i < data.length; i++) {
          /*INFO: Mock data, remove on prod*/
          if (!Object.keys(data[i].extra).length) {
            data[i].extra = {
              speed: 0,
              heading: 0
            }
          }
          const cords = { lat: data[i].lat, lng: data[i].lng }
          this.map.markers.push({ position: cords, id: i,
            timestamp: this.convertTimeStamp(data[i].timestamp),
            extra: data[i].extra,
            icon: {
              url: `${gmap_icon_path}green.png`,
              scaledSize: new google.maps.Size(20, 20)
            }});
          this.map.path.push(cords)
        }
        this.$emit('sendMarkers', {markers: this.map.markers});
      }).catch(() => {
        this.errorSend = true
        this.errorSendText = 'Carrier locations not found'
      })
    },
    async sendRequest(url) {
      const response = await fetch(url);
      return response.json();
    },
    async insertRouteMarkers(source, type = 'shipper') {
      for (let i = 0; i < source.length; i++) {
        let url = this.getOpenStreetUrl(source[i].location)
        try {
          const data = await this.sendRequest(url)
          if (data.features.length > 0) {
            const coords = data.features[0].geometry.coordinates;
            const position = {lat: coords[1], lng: coords[0]};
            let icon_url = `${gmap_icon_path}yellow-dot.png`
            if (type !== 'shipper') icon_url = `${gmap_icon_path}blue-dot.png`
            if (type === 'shipper' && i === 0) this.map.center = position
            this.mapRouteMarkers.push({ position: position, id: source[i].id,
              icon: {url: icon_url, scaledSize: new google.maps.Size(40, 40) }});
          }
        } catch (error) { console.error('Error:', error); }
      }
    },
    runSocket() {
      this.$socketTracking.emit('join_room',
          `carrier:location:${this.traking_id}`)
      this.$socketTracking.on('carrier:location', (data) => {
        const cords = {lat: data.lat, lng: data.lng}
        this.map.markers.unshift({
          extra: data.extra, position: cords,
          timestamp: this.convertTimeStamp(data.extra.time),
          icon: { url: `${gmap_icon_path}green.png`,
            scaledSize: new google.maps.Size(20, 20) }})
        this.map.path.unshift(cords)
        const updated_markers = { markers: this.map.markers };
        this.$emit('sendMarkers', updated_markers);
      })
    },
    copyCoordinates(lat, lng) {
      window.navigator.clipboard.writeText(`${lat}, ${lng}`)
          .then(() => {
            this.coordinates = `${lat}, ${lng}`
            this.textCopied = true
          }).catch(err => {console.error('Failed to copy text: ', err);});
    },
  },
  async mounted() {
    if (this.traking_id.length !== 0) this.runSocket()
    await this.insertRouteMarkers(this.carrier.load_carrier_shippers)
    await this.insertRouteMarkers(this.carrier.load_carrier_consignees, 'consignee')
    if (this.traking_id.length !== 0) await this.fetchCarrierLocations()
  },
}
</script>

<template>
  <div class="map_wrapper">
    <v-snackbar
        v-model="textCopied"
        :timeout="2000"
        top>
      Coordinates {{ coordinates }} copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="textCopied = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
        :close-on-content-click="true"
        v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Truck location details</v-card-title>
        <v-card-text>
          <div class="float-right">
            <v-btn x-small :rounded="true"
                   @click="copyCoordinates(locationInfo.position.lat, locationInfo.position.lng)"
                   color="info" variant="outlined">
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </div>
          <div v-if="Object.keys(locationInfo).length > 0">
            <p>Latitude: {{ locationInfo.position.lat }}</p>
            <p>Longitude: {{ locationInfo.position.lng }}</p>
            <p>Time: {{ locationInfo.timestamp }}</p>
            <p>Speed: {{ locationInfo.extra.speed.toFixed(2) ?? '' }}</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <GmapMap
        ref="map"
        :center="map.center"
        :zoom="map.zoom"
        style="width: 100%; height: 500px; color: green;"
    >
      <GmapMarker v-if="carrierLocation"
                  :position="{lat:carrierLocation.lat, lng:carrierLocation.lng}"
      ></GmapMarker>
      <GmapMarker
          :key="index + 'routeMaker'"
          v-for="(location, index) in mapRouteMarkers"
          :icon="location.icon"
          :position="{lat: location.position.lat, lng: location.position.lng}"
          v-if="mapRouteMarkers" />
      <GmapMarker v-if="map.markers"
                  v-for="(location, index) in map.markers"
                  :key="index"
                  :clickable="true"
                  @click="showMapModal(index)"
                  :draggable="false"
                  :icon="location.icon"
                  :position="{lat: location.position.lat, lng: location.position.lng}" />
      <GmapPolyline
          :path="map.path"
          :options="map.polylineOptions"
      />
    </GmapMap>
  </div>
</template>

<style scoped>

</style>