import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('h4',[_vm._v("Comments:")]),_c(VCard,{staticStyle:{"height":"300px","overflow-y":"scroll"},attrs:{"outlined":""}},[_c(VCardText,[(_vm.comments.length == 0)?_c('i',[_vm._v("No comments ...")]):_vm._e(),_vm._l((_vm.comments),function(item){return _c(VRow,[_c('div',{staticClass:"d-flex w-100 px-3 align-items-center justify-space-between"},[_c(VChip,{staticClass:"my-2"},[_c('strong',[_vm._v(_vm._s(item.created_by.name))]),_vm._v(": "+_vm._s(item.comment)+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])],1)])})],2)],1),_c('div',{staticClass:"mt-3 d-flex align-items-center"},[_c(VTextarea,{attrs:{"hide-details":"","rows":"1","placeholder":"Comment ...","outlined":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('blocking-layover',{staticStyle:{"width":"unset"},attrs:{"blocking-condition":_vm.restrictedEdit}},[_c(VBtn,{attrs:{"x-large":"","color":"success"},on:{"click":_vm.sendComment}},[_vm._v("Send")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }