<template>
  <div>
    <v-simple-table class="bol-table" border="1">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">
            Pieces
          </th>
          <th class="text-center">
            Description
          </th>
          <th  class="text-center">
            Lbs
          </th>
          <th class="text-center">
            Type
          </th>
          <th class="text-center">
            NMFC
          </th>
          <th style="width: 75px;" class="text-center">
            HM
          </th>
          <th>
            Class
          </th>
          <th>

          </th>
        </tr>
        </thead>
        <tbody>
        <blocking-layover style="display: table-row; font-size: 12px;" component="tr" v-for="item in items" :blocking-condition="restrictedEdit">
          <td>
            <v-text-field type="number" step="1" hide-details v-model.number="item.pieces" outlined dense></v-text-field>
          </td>
          <td style="width: 200px;">
            <v-text-field v-model="item.description" hide-details outlined dense></v-text-field>
          </td>
          <td>
            <v-text-field type="number" v-model.number="item.lbs" step="0.001" hide-details outlined dense></v-text-field>
          </td>
          <td>
            <v-text-field v-model="item.type" hide-details outlined dense></v-text-field>
          </td>
          <td>
            <v-text-field v-model="item.NMFC" hide-details outlined dense></v-text-field>
          </td>
          <td style="width: 75px;">
            <v-select v-model="item.HM" :items="['Yes', 'No']" hide-details outlined dense></v-select>
          </td>
          <td>
            <v-text-field v-model="item.class" hide-details outlined dense></v-text-field>
          </td>
          <td>
            <v-btn x-small @click="remove(item)" height="24">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </blocking-layover>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center mt-3">
      <blocking-layover style="position: unset" :blocking-condition="restrictedEdit" :bluring-condition="restrictedEdit">
        <v-btn @click="addLine" small>ADD</v-btn>
      </blocking-layover>
    </div>
  </div>
</template>

<script>
import BlockingLayover from '@/components/layout/BlockingLayover.vue'
import loadLayoverMixin from '@/mixins/loadLayoverMixin'
export default {
  name: "BillOfLandingLineItems",
  mixins: [loadLayoverMixin],
  components: {
    BlockingLayover
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    restrictedEdit() {
      return this.restrictLoadEdit(this.$store.state.loadInEdit)
    }
  },
  methods: {
    addLine () {
      this.items.push({
        pieces: null,
        description: null,
        lbs: null,
        type: null,
        NMFC: null,
        HM: null,
        class: null,
      })
    },
    remove(item) {
      this.items.splice(this.items.indexOf(item), 1)
    }
  }
}
</script>

<style scoped>

</style>
