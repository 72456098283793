import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":"","light":""}},[_c(VCardText,[_c(VTabs,{attrs:{"slider-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.carriers),function(item,idx){return _c(VTab,{key:idx,attrs:{"transition":"false","draggable":true},on:{"dragstart":function($event){return _vm.onDragStart(idx)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver(idx)},"dragleave":_vm.onDragLeave,"drop":function($event){_vm.onDrop(idx, _vm.carriers); _vm.tab = idx}}},[_c('span',{staticStyle:{"pointer-events":"all"}},[_vm._v("Carrier - "+_vm._s(idx + 1))]),_c(VBtn,{staticClass:"ml-5 px-1",staticStyle:{"min-width":"0"},attrs:{"x-small":"","text":"","color":"error"},on:{"click":function($event){return _vm.remove(idx)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)}),_c(VSpacer),(!_vm.allowEdit)?_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":_vm.add}},[_vm._v(" Add new carrier ")]):_vm._e()],2),_c(VTabsItems,{staticClass:"pt-2",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.carriers),function(item,index1){return _c(VTabItem,{key:index1,attrs:{"eager":"","transition":false}},[_c('Item',{attrs:{"load":_vm.load,"carrier":item},on:{"openRC":function($event){return _vm.$emit('openRC', index1)}}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }