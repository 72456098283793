<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-tabs v-model="tab" slider-color="transparent">
          <v-tab
              v-for="(item, idx) in shippers"
              :key="idx"
              :draggable="true"
              @dragstart="onDragStart(idx)"
              @dragover.prevent="onDragOver(idx)"
              @dragleave="onDragLeave"
              @drop="onDrop(idx, shippers); tab = idx"
          >
            <span style="pointer-events: all;">Shipper - {{ idx + 1 }}</span>
            <v-icon class="ml-3" color="error" small @click="remove(idx)">mdi-close</v-icon>
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn v-if="!allowEdit" small color="secondary" @click="add">
            Add new shipper
          </v-btn>
        </v-tabs>

        <v-tabs-items v-model="tab" class="pt-2 px-3">
          <v-tab-item v-for="(item, index) in shippers" :key="'shipper-tab-' + index" eager :transition="false">
            <Item :shipper="item" :idx="index+1" :key="'shipper-' + index"
              @duplicate-fields-changed="onDuplicateFieldsChanged(...arguments, index)" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Item from './Item.vue';
import dragSwapMixin from "@/mixins/dragSwapMixin";

export default
  {
    name: "Shippers",
    mixins: [dragSwapMixin],
    props: {
      shippers: {
        type: Array,
        required: true,
      },
    },
    components: { Item },
    data() {
      return {
        tab: null,
      }
    }, 
    computed: {
    allowEdit () {
      return !this.$store.getters.role.includes('admin') && this.loadInEdit.status === 'invoiced'
    },
      loadInEdit() {
        return this.$store.state.loadInEdit;
      },
    },
    methods: {
      add() {
        this.shippers.push({
          "load_carrier_id": null,
          "shipper_id": null,
          "customs_broker_id": null,
          "location": "",
          "location_coordinates": null,
          "location_data": null,
          "date": null,
          "description": null,
          "type": null,
          "quantity": null,
          "weight": null,
          "cost": null,
          "shipping_notes": null,
          "po_numbers": null,
          "customs_broker": null,
          "shipper": null
        })
      },
      remove(item) {
        this.shippers.splice(item, 1);
      },
      onDuplicateFieldsChanged(values, oldValues, index) {
        this.$emit('duplicate-fields-changed', values, oldValues, index)
      }
    }
  }
</script>
