<template>
  <v-dialog
      max-width="1700"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="blue-grey darken-3"
          class="white--text"
          v-bind="attrs"
          v-on="on"
          small
      >
      <v-icon small class="mr-3">
        mdi-receipt-text-outline
      </v-icon>
        BOL
      </v-btn>
    </template>
    <v-toolbar flat dense dark color="primary">
      <v-toolbar-title tag="span" class="subtitle-1">Bill of Lading</v-toolbar-title>
    </v-toolbar>
    <v-card flat light class="p-3">
      <v-simple-table class="bol-table" border="1">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center bol-select-column"></th>
            <th class="text-center">
              BOL #
            </th>
            <th class="text-center">
              3rd Party
            </th>
            <th style="max-width: 230px" class="text-center">
              Carrier
            </th>
            <th class="text-center">
              Freight Charges
            </th>
            <th style="max-width: 180px" class="text-center">
              Origin
            </th>
            <th style="max-width: 180px" class="text-center">
              Destination
            </th>
            <th class="text-center">
              Emergency #
            </th>
            <th class="text-center" colspan="2">
              C.O.D.
            </th>
            <th class="text-center">
              Value
            </th>
            <th class="text-center">
              Notes
            </th>
            <th class="text-center">
              Items
            </th>
            <th class="text-center">

            </th>
          </tr>
          </thead>
          <tbody>
            <BillOfLandingLine @selected="updateSelectedLines" v-for="(item, key) in load.bol" :item="item" :index="key" :load="load" :company-carrier="companyCarrier" :key="'bolline-' + key" @remove="onItemRemove"></BillOfLandingLine>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center mt-2">
        <blocking-layover class="d-flex justify-content-between" style="position: unset;" :blocking-condition="restrictedEdit" :bluringCondition="restrictedEdit">
          <v-btn :loading="printInProgress" @click="printMultiple" :disabled="selectedLines.length === 0" small color="primary">PRINT LINES</v-btn>
          <v-btn @click="addLine" small color="primary">ADD LINE</v-btn>
        </blocking-layover>
      </div>
    </v-card>

    <v-snackbar
        v-model="errorSnackbar"
        :timeout="3000"
        color="red accent-2"
        top
    >
      <b>{{ serverError }}</b>
    </v-snackbar>

    <v-snackbar v-model="printInProgress" color="info">
      <b>Generating PDF file for selected BOLs, please wait...</b>
    </v-snackbar>

  </v-dialog>
</template>

<script>
import BillOfLandingLine from "@/components/loads/bol/BillOfLandingLine";
import BlockingLayover from "@/components/layout/BlockingLayover.vue";
import config from "@/config";
import axiosInstance from "@/plugins/axios";
import loadLayoverMixin from "@/mixins/loadLayoverMixin";
import axios from "@/plugins/axios";
export default {
  name: "BillOfLanding",
  components: {BillOfLandingLine, BlockingLayover},
  mixins: [loadLayoverMixin],
  data() {
    return {
      errorSnackbar: false,
      serverError: null,
      printInProgress: false,
      selectedLines: [],
      companyCarrier: null
    }
  },
  props: {
    load: {
      type: Object,
      required: true
    }
  },
  computed: {
    restrictedEdit() {
      return this.restrictLoadEdit(this.load)
    }
  },
  created() {
    axiosInstance.get(config.apiUrl + '/api/counterparty', {
      params: {
        search: 'TRANSIMEX GLOBAL'
      }
    }).then(({data}) => {
      this.companyCarrier = data.data[0]
    })
    if (this.load.bol.length === 0) {
      this.addLine()
    }
  },
  methods: {
    updateSelectedLines(item, isItemSelected) {
      if (isItemSelected) {
        this.selectedLines.push(item)
      } else {
        this.selectedLines = this.selectedLines.filter(selectedItem => selectedItem.nr !== item.nr)
      }
    },
    getShipper(item) {
      return item.carrier.load_carrier_shippers
          .filter(shipper => shipper.location === item.origin)
    },
    getConsignee(item) {
      return item.carrier.load_carrier_consignees
          .filter(consignee => consignee.location === item.destination)
    },
    printMultiple() {
      this.printInProgress = true
      let bol_nr = [];
      this.selectedLines.map((line) => { bol_nr.push(line.nr) })

      const separator = '&'
      const lines = Object.values(bol_nr)
      const string_lines = lines.join(separator)
      const lines_clone =  this.selectedLines

      for (let i = 0; i < lines_clone.length; i++) {
        if (lines_clone[i].carrier !== null) {
          lines_clone[i].carrier.load_carrier_shippers = this.getShipper(lines_clone[i])
          lines_clone[i].carrier.load_carrier_consignees = this.getConsignee(lines_clone[i])
        } else {
          this.errorSnackbar = true
          this.serverError = "Carrier is required"
          return false;
        }
      }

      axios.post(config.apiUrl + '/api/load/' + this.load.id + '/bol',
          { item: lines_clone })
          .then(response => {
            const url = config.apiUrl + '/bol/' + this.load.id + '/' + string_lines
            window.location.assign(url)
          })
          .catch(error => {
            console.error('Error: ' + error)
          })
          .finally(() => {
            this.printInProgress = false
          })
    },
    addLine() {
      this.load.bol.push({
        nr: null,
        thirdParty: null,
        carrier: null,
        freightCharges: null,
        origin: null,
        destination: null,
        emergency: null,
        codType: null,
        codAmount: null,
        value: null,
        notes: null,
        items: []
      })
    },
    onItemRemove(item) {
      this.load.bol.splice(this.load.bol.indexOf(item), 1)
    }
  },
}
</script>

<style>
  .bol-table .v-text-field input, .bol-table .v-text-field select {
    padding: 2px !important;
  }
  .bol-table .v-select__slot {
    height: 24px;
  }
  .bol-table .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
    margin-top: 0;
    right: -7px;
    position: relative;
  }
  .bol-table .v-input__slot {
    min-height: 20px !important;
  }
  .bol-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: auto;
    padding: 3px;
  }
  .bol-table .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding: 0 5px;
  }
  .bol-table table th, .bol-table table td {
    border: 1px solid #aaa;
  }
  .bol-table .v-select__selections {
    height: 34px;
  }
</style>
