import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import gmaps from "./plugins/gmaps";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import config from "@/config";
import mixins from 'vuetify-multiple-draggable-dialogs';
import Sw from '@/components/layout/Sw.vue'
import { io } from "socket.io-client";
Vue.component('sw', Sw)

Vue.mixin(mixins);
Vue.config.productionTip = false;

Vue.prototype.$socketTracking = io(config.trackingApi, {
	forceNew: true,
	withCredentials: false
})

Vue.prototype.$socket = io(config.webSocketsUrl, {
  forceNew: true,
  withCredentials: false
})

Vue.prototype.$socketChat = io(config.chatWebSocketUrl, {
  auth: {
    serverOffset: 0,
    token: localStorage.getItem('tokenForChat'),
  },
  ackTimeout: 10000,
  retries: 3,
  autoConnect: true,
})

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: config.googleApiKey,
  language: 'en',
  installComponents: true
});


new Vue({
  router,
  store,
  vuetify,
  gmaps,
  created () {

    // enable using draggable dialogs
    this.activateMultipleDraggableDialogs();
  },
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
