<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-card outlined tile v-if="count">
          <v-card-title><strong>{{ data.count }} $</strong></v-card-title>
          <v-card-subtitle>{{ name }}</v-card-subtitle>
        </v-card>
        <v-card outlined tile v-else>
          <v-card-title><strong>{{ name }}</strong></v-card-title>
        </v-card>
        <div>
          <apexchart type="bar" :height="height" :options="chartOptions" :series="data.series"></apexchart>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: 'Title'
    },
    data: {
      type: Object,
      required: true
    },
    count: {
      type: Boolean,
      required: true
    },
    setColors: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      default: 350
    }
  },
  data() {
    return {
      chartOptions: {
        noData: {
          text: "Loading...",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: '14px',
            fontFamily: "Helvetica"
          }
        },
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        colors: this.setColors,
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals

            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          }
        },
        legend: {
          show: false,
        }
      },
    }
  }
}
</script>
