<template>
  <v-card>
    <v-toolbar dense flat dark color="primary">
      <v-toolbar-title>Quick search </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model.trim="identifier" placeholder="Search by MC, DOT ..." hide-details single-line clearable :autofocus="true"
        v-on:keyup.enter="getCarrier(identifier)" @click:clear="clearCarrier"></v-text-field>
      <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom height="5" color="white"></v-progress-linear>
    </v-toolbar>
    <v-card-text class="pt-5" v-if="showData">
      <div v-if="carrierData">
        <v-btn @click="saveTo">
          Save to Carrier profile
        </v-btn>
      </div>
      <v-row align="center">
        <v-col md="4">
          <strong>DOT{{ carrier.carrier_data.dot ?? "-" }} - MC{{ carrier.carrier_data.mc_ff_value ?? "-" }}</strong> <br>
          <strong>{{ carrier.name ?? "-" }}</strong> <br>
          <div v-for="address in carrier.counterparty_addresses">
            {{ address.address1 ?? "-" }} <br>
            {{ address.city ?? "-" }}, {{ address.state ?? "-" }}, {{ address.postcode ?? "-" }}, {{
              address.country_code ??
                "-"
            }}
          </div>
          <div class="my-2" v-for="contact in carrier.counterparty_contacts" v-if="contact.type === 'phone'">
            Main phone: {{ contact.contact ?? "-" }} <br>
            Contact: {{ contact.contact_person ?? "-" }}
          </div>
          <div class="my-2">
            <strong>Mailing Address:</strong><br>
            {{ carrier.counterparty_saferwatch?.full_data?.Identity.mailingStreet ?? "-" }}<br>
            {{ carrier.counterparty_saferwatch?.full_data?.Identity.mailingCity ?? "-" }},
            {{ carrier.counterparty_saferwatch?.full_data?.Identity.mailingState ?? "-" }},
            {{ carrier.counterparty_saferwatch?.full_data?.Identity.mailingZipCode ?? "-" }},
            {{ carrier.counterparty_saferwatch?.full_data?.Identity.mailingCountry ?? "-" }}<br>
            Cell phone: <a :href="'tel:' + carrier.counterparty_saferwatch?.full_data?.Identity.cellPhone">{{
              carrier.counterparty_saferwatch?.full_data?.Identity.cellPhone ?? "-"
            }}</a>
          </div>
          <div class="my-2" v-for="contact in carrier.counterparty_contacts" v-if="contact.type === 'email'">
            Email: <a :href="'mailto:' + contact.contact">{{ contact.contact ?? "-" }}</a>
          </div>
        </v-col>
        <v-col md="8">
          <v-row>

            <v-col md="3" v-if="carrierId">
              <v-btn @click="$store.dispatch('carrierHistory/carrierHistory', { carrier_id: carrierId, per_page: 1000 })" class="mb-3">Loads history</v-btn>
            </v-col>
            <v-col>
              <v-btn v-if="carrierExists" color="green" dark>
                Carrier is setup. <v-icon>mdi-checkbox-marked</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card flat outlined>
            <v-card-text v-if="carrier.counterparty_saferwatch?.full_data?.Authority.brokerAuthority === 'Active'">
              <div class="text-overline">
                Risk assessment performed on Interstate Carriers only.
              </div>
              <h4 class="mt-3 mb-5 text-green">Broker Assessment</h4>
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment?.Authority === 'Acceptable' ? 'green' : 'gray'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Autority</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Insurance === 'Acceptable' ? 'green' : 'gray'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Insurance</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment?.Safety === 'Acceptable' ? 'green' : 'gray'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Safety</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Operation === 'Acceptable' ? 'green' : 'gray'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Operation</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Other === 'Acceptable' ? 'green' : 'gray'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Other</strong>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text v-else-if="carrier.counterparty_saferwatch?.full_data?.Authority.commonAuthority === 'Active'">
              <div class="text-overline">
                Overall assessment using checklist
              </div>
              <h4 class="mt-3 mb-5">
                <v-icon large
                  :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Overall === 'Acceptable' ? 'green' : 'red'"
                  class="mr-3">mdi-check-decagram</v-icon>
                {{ carrier.counterparty_saferwatch?.full_data.RiskAssessment.Overall }}
              </h4>
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment?.Authority === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Autority</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Insurance === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Insurance</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment?.Safety === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Safety</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Operation === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Operation</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Other === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Other</strong>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

            </v-card-text>
            <v-card-text v-else>
              <div class="text-overline">
                Overall assessment using checklist
              </div>
              <h4 class="mt-3 mb-5">
                <v-icon large
                  :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Overall === 'Acceptable' ? 'green' : 'red'"
                  class="mr-3">mdi-check-decagram</v-icon>
                {{ carrier.counterparty_saferwatch?.full_data.RiskAssessment.Overall }}
              </h4>
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment?.Authority === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Autority</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Insurance === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Insurance</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment?.Safety === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Safety</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Operation === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Operation</strong>
                    </v-col>
                    <v-col class="text-center">
                      <v-icon
                        :color="carrier.counterparty_saferwatch?.full_data.RiskAssessment.Other === 'Acceptable' ? 'green' : 'red'"
                        class="mb-2">mdi-check-decagram</v-icon>
                      <strong class="d-block">Other</strong>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div>
        <h5>Authority</h5>
        <v-row>
          <v-col md="6">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>
                    Authority
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    App Pending
                  </th>
                  <th>
                    Revocation Pending
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">
                    Common
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Authority.commonAuthority ?? "-" }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      carrier.counterparty_saferwatch?.full_data?.Authority.commonAuthorityPending ?? "-"
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      carrier.counterparty_saferwatch?.full_data?.Authority.commonAuthorityRevocation ?? "-"
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="table-active">
                    Contract
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Authority.contractAuthority ?? "-" }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      carrier.counterparty_saferwatch?.full_data?.Authority.contractAuthorityPending ?? "-"
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      carrier.counterparty_saferwatch?.full_data?.Authority.contractAuthorityRevocation ?? "-"
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="table-active">
                    Broker
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Authority.brokerAuthority ?? "-" }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      carrier.counterparty_saferwatch?.full_data?.Authority.brokerAuthorityPending ?? "-"
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      carrier.counterparty_saferwatch?.full_data?.Authority.brokerAuthorityRevocation ?? "-"
                    }}</strong>
                  </td>
                </tr>
              </tbody>

            </v-simple-table>
          </v-col>
          <v-col md="3">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th colspan="2">
                    Cargo Authorization
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">
                    Freight
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Cargo?.CargoGenFreight ?? "-" }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="table-active">
                    Passenger
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Cargo?.CargoPassengers ?? "-" }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="table-active">
                    Household
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Cargo?.CargoHousehold ?? "-" }}</strong>
                  </td>
                </tr>
              </tbody>

            </v-simple-table>
          </v-col>
          <v-col md="3">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th colspan="2">
                    Authority Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">
                    Granted On
                  </td>
                  <td>
                    <strong>{{ carrier.counterparty_saferwatch?.full_data?.Authority.authGrantDate ?? "-" }}</strong>
                  </td>
                </tr>

              </tbody>

            </v-simple-table>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div v-if="carrier.counterparty_saferwatch?.certificate_data">
        <h5>Insurance <v-btn small class="ml-5 link-underline" text color="blue" :loading="certLoading"
            @click="getCert()">Certificate View</v-btn> </h5>

        <v-simple-table dense>
          <thead>
            <tr>
              <th>
                Underwriter
              </th>
              <th>
                Type
              </th>
              <th>
                Policy Number
              </th>
              <th>
                Expire Date
              </th>
              <th>
                Policy Limit
              </th>
              <th>
                Deductible / Reefer Ded.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in carrier.counterparty_saferwatch?.certificate_data.Coverage">
              <td>
                {{ item.insurerName ?? "-" }}
              </td>
              <td>
                {{ item.type ?? "-" ?? "-" }}
              </td>
              <td>
                {{ item.policyNumber ?? "-" ?? "-" }}
              </td>
              <td>
                {{ item.expirationDate ?? "-" ?? "-" }}
              </td>
              <td>
                {{ item.coverageLimit ?? "-" ?? "-" }}
              </td>
              <td>
                <span v-if="item.deductable.length >= 1">
                  {{ item.deductable ?? "-" ?? "-" }}
                </span>
                <span v-else>
                  0
                </span>
              </td>
            </tr>

          </tbody>

        </v-simple-table>
        <div>
          <v-row align="center">
            <v-col>
              <img src="@/assets/fmcsa.png" alt="">
            </v-col>
            <v-col>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th colspan="2">BIPD Insurance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-active">Required</td>
                    <td>{{ carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance.bipdRequired ?? "-" }}</td>
                  </tr>
                  <tr>
                    <td class="table-active">Filed</td>
                    <td>{{ carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance.bipdOnFile ?? "-" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th colspan="2">HHG Cargo Insurance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-active">Required</td>
                    <td>{{ carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance?.CargoRequired ?? "-" }}</td>
                  </tr>
                  <tr>
                    <td class="table-active">Filed</td>
                    <td>{{ carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance?.CargoOnFile ?? "-" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th colspan="2">Bond Insurance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-active">Required</td>
                    <td>{{ carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance.bondSuretyRequired ?? "-" }}</td>
                  </tr>
                  <tr>
                    <td class="table-active">Filed</td>
                    <td>{{ carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance.bondSuretyOnFile ?? "-" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <div>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>
                    Underwriter
                  </th>
                  <th>
                    A.M. Best
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Policy Number
                  </th>
                  <th>
                    Posted Date
                  </th>
                  <th>
                    Effective Date
                  </th>
                  <th>
                    Cancel Date
                  </th>
                  <th>
                    Coverage From
                  </th>
                  <th>
                    Coverage To
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in carrier.counterparty_saferwatch?.full_data?.FMCSAInsurance.PolicyList.PolicyItem"
                  v-if="item.insuranceType === 'BIPD'">
                  <td style="font-size:12px"><strong>{{ item.companyName ?? "-" }}</strong> <br>
                    {{ item.attnToName ?? "-" }} <br>
                    {{ item.address ?? "-" }}, {{ item.city ?? "-" }}, {{ item.stateCode ?? "-" }} {{
                      item.postalCode ?? "-"
                    }}<br>
                    P. {{ item.phone ?? "-" }}
                  </td>
                  <td>{{ item.amBestRating ?? "-" }}</td>
                  <td>{{ item.insuranceType ?? "-" }}</td>
                  <td>{{ item.policyNumber ?? "-" }}</td>
                  <td>{{ item.postedDate ?? "-" }}</td>
                  <td>{{ item.effectiveDate ?? "-" }}</td>
                  <td>{{ item.cancelationDate ?? "-" }}</td>
                  <td>{{ item.coverageFrom ?? "-" }}</td>
                  <td>{{ item.coverageTo ?? "-" }}</td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div>
        <h5>Safety & Review</h5>
        <v-row>
          <v-col md="4">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th colspan="2">Safety</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Safety Rating</td>
                  <td>{{ carrier.counterparty_saferwatch?.safety.rating ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Rating Date</td>
                  <td>{{ carrier.counterparty_saferwatch?.safety.ratingDate ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="3">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th colspan="2">MCS-150 - Most Recent Report</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Date</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Review.mcs150Date ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">MCS-150 (year/miles)</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Review.mcs150MileYear ?? "-" }} - {{
                    carrier.counterparty_saferwatch?.full_data?.Review.mcs150Miles ?? "-"
                  }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="5">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th colspan="2">Latest Review</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Review Type</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Review?.ReviewType ?? "-" }}</td>
                  <td class="table-active">Document</td>
                  <td>
                    <span v-for="item in carrier.counterparty_saferwatch?.full_data?.Review?.ReviewDocNum"
                      v-if="carrier.counterparty_saferwatch?.full_data?.Review?.ReviewDocNum">
                      {{ item ?? "-" }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="table-active">Review Date</td>
                  <td>
                    {{ carrier.counterparty_saferwatch?.full_data?.Review?.ReviewDate ?? "-" }}
                  </td>
                  <td class="table-active">Reported Miles</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Review?.ReviewMiles ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <strong class="d-block pt-5">FAST Act/CSA Score Information</strong>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>CSA-e Calculations</th>
              <th>Percentile</th>
              <th>Over Threshold</th>
              <th>Severe Violation</th>
              <th>Overall</th>
              <th>Trend</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-active">Unsafe Driving</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.unsafeDrvPCT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.unsafeDrvOT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.unsafeDrvSV ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.unsafeDrvAlert ?? "-" }}</td>
              <td>
                <v-icon v-if="carrier.counterparty_saferwatch?.safety.unsafeDrvTrend === 'DOWN'"
                  color="red">mdi-arrow-down-bold-box</v-icon>
                <span v-else></span>
              </td>
            </tr>
            <tr>
              <td class="table-active">HOS Compliance</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.hosPCT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.hosOT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.hosSV ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.hosAlert ?? "-" }}</td>
              <td>
                <v-icon v-if="carrier.counterparty_saferwatch?.safety.hosTrend === 'DOWN'"
                  color="red">mdi-arrow-down-bold-box</v-icon>
                <span v-else></span>
              </td>
            </tr>
            <tr>
              <td class="table-active">Driver Fitness</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.drvFitPCT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.drvFitOT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.drvFitSV ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.drvFitAlert ?? "-" }}</td>
              <td>
                <v-icon v-if="carrier.counterparty_saferwatch?.safety.drvFitTrend === 'DOWN'"
                  color="red">mdi-arrow-down-bold-box</v-icon>
                <span v-else></span>
              </td>
            </tr>
            <tr>
              <td class="table-active">Drugs/Alcohol</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.controlSubPCT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.controlSubOT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.controlSubSV ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.controlSubAlert ?? "-" }}</td>
              <td>
                <v-icon v-if="carrier.counterparty_saferwatch?.safety.controlSubTrend === 'DOWN'"
                  color="red">mdi-arrow-down-bold-box</v-icon>
                <span v-else></span>
              </td>
            </tr>
            <tr>
              <td class="table-active">Vehicle Maintenance</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.vehMaintPCT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.vehMaintOT ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.vehMaintSV ?? "-" }}</td>
              <td>{{ carrier.counterparty_saferwatch?.safety.vehMaintAlert ?? "-" }}</td>
              <td>
                <v-icon v-if="carrier.counterparty_saferwatch?.safety.vehMaintTrend === 'DOWN'"
                  color="red">mdi-arrow-down-bold-box</v-icon>
                <span v-else></span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <v-divider></v-divider>
      <div>
        <h5>Inspections & Crash</h5>
        <v-row align="center" class="px-1">
          <v-col md="3">
            <strong>Total US Inspections: {{ carrier.counterparty_saferwatch?.inspection.inspectTotalUS ?? "-" }}
            </strong><br>
            <img src="@/assets/us.gif" alt="">
          </v-col>
          <v-col md="6">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th></th>
                  <th>Vehicle</th>
                  <th>Driver</th>
                  <th>Hazmat</th>
                  <th>IEP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Inspections</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectVehUS ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectDrvUS ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectHazUS ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectIEPUS ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Out of Service</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectVehOOSUS ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectDrvOOSUS ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectHazOOSUS ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectIEPOOSUS ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Out of Service %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectVehOOSPctUS ?? "-" }} %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectDrvOOSPctUS ?? "-" }} %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectHazOOSPctUS ?? "-" }} %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectIEPOOSPctUS ?? "-" }} %</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="3">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>Fatal</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashFatalUS ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Injury</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashInjuryUS ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Tow</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashTowUS ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashTotalUS ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row align="center" class="px-1">
          <v-col md="3">
            <strong>Total Canadian Inspections: {{ carrier.counterparty_saferwatch?.inspection.inspectTotalCAN ?? "-" }}
            </strong><br>
            <img src="@/assets/ca.gif" alt="">
          </v-col>
          <v-col md="6">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th></th>
                  <th>Vehicle</th>
                  <th>Driver</th>
                  <th>Hazmat</th>
                  <th>IEP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Inspections</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectVehCAN ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectDrvCAN ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectHazCAN ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectIEPCAN ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Out of Service</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectVehOOSCAN ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectDrvOOSCAN ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectHazOOSCAN ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectIEPOOSCAN ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Out of Service %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectVehOOSPctCAN ?? "-" }} %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectDrvOOSPctCAN ?? "-" }} %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectHazOOSPctCAN ?? "-" }} %</td>
                  <td>{{ carrier.counterparty_saferwatch?.inspection.inspectIEPOOSPctCAN ?? "-" }} %</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="3">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>Fatal</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashFatalCAN ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Injury</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashInjuryCAN ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Tow</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashTowCAN ?? "-" }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data?.Crash?.CrashTotalCAN ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div v-if="carrier.counterparty_saferwatch?.full_data?.Drivers">
        <h5>Drivers & Equipment</h5>
        <strong class="d-block pt-5">Drivers</strong>
        <v-row>
          <v-col md="4">
            <v-simple-table dense>
              <thead>
                <tr>
                  <td>Total Drivers</td>
                  <td>Count</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Interstate & Intrastate Drivers</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversTotal ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">CDL Employed Drivers</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversCDL ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Monthly Average Leased Drivers</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversAvgLeased ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="4">
            <v-simple-table dense>
              <thead>
                <tr>
                  <td>Interstate Drivers</td>
                  <td>Count</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Drivers</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversInter ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Drivers &lt;100 Miles </td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversInterLT100 ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Drivers 100+ Miles</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversInterGT100 ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="4">
            <v-simple-table dense>
              <thead>
                <tr>
                  <td>Intrastate Drivers</td>
                  <td>Count</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Drivers</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversIntra ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Drivers &lt;100 Miles </td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversIntraLT100 ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Drivers 100+ Miles</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Drivers.driversIntraGT100 ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <strong class="d-block pt-5">Equipment</strong>
        <v-row>
          <v-col md="4">
            <v-simple-table dense>
              <thead>
                <tr>
                  <td colspan="2">Fleet</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Fleet Size</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.fleetsize ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Total Power Units</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.totalPower ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Total Trucks</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trucksTotal ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col md="8">
            <v-simple-table dense>
              <thead>
                <tr>
                  <td>Equipment</td>
                  <td>Owned</td>
                  <td>Term Leased</td>
                  <td>Trip Leased</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-active">Tractors</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.tractorsOwned ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.tractorsTerm ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.tractorsTrip ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Trucks</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trucksOwned ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trucksTerm ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trucksTrip ?? "-" }}</td>
                </tr>
                <tr>
                  <td class="table-active">Trailers</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trailersOwned ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trailersTerm ?? "-" }}</td>
                  <td>{{ carrier.counterparty_saferwatch?.full_data.Equipment.trailersTrip ?? "-" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>

    </v-card-text>
  </v-card>
</template>
<style>
.theme--dark.v-card>.v-card__text,
.theme--dark.v-card>.v-card__subtitle {
  color: white;
}

.theme--light.v-card>.v-card__text,
.theme--light.v-card>.v-card__subtitle {
  color: black;
}
</style>
<script>
import { EventBus } from "@/helpers/eventBus";
import config from "@/config";
import axios from "@/plugins/axios";
export default {
  data() {
    return {
      carrierExists: false,
      identifier: null,
      carrier: null,
      showData: false,
      loading: false,
      carrierId: null,
      certLoading: false
    }
  },
  props: {
    carrierData: {
      type: Object,
      default: null
    },
    close: {
      type: Boolean,
      default: null
    }
  },
  mounted() {
    if (this.carrierData?.id) {
      this.loading = true
      axios.get(config.apiUrl + '/api/counterparty/' + this.carrierData.id)
        .then((response) => {
          this.carrier = response.data
          this.showData = true
          this.loading = false
          if (this.carrier.counterparty_saferwatch === null) {
            this.getCarrier(this.carrier.carrier_data.dot)
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  watch: {
    carrierData: function (newVal, oldVal) {
      if (this.carrierData && this.carrierData.id) {
        this.loading = true
        axios.get(config.apiUrl + '/api/counterparty/' + this.carrierData.id)
          .then((response) => {
            this.carrier = response.data
            this.showData = true
            this.loading = false
            if (this.carrier.counterparty_saferwatch === null) {
              this.getCarrier(this.carrier.carrier_data.dot)
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  },
  methods: {
    clearCarrier() {
      this.showData = false
      this.carrier = null
    },
    getCert() {
      this.certLoading = true
      axios.get(config.apiUrl + '/api/counterparty/get-certificate/' + this.carrier.counterparty_saferwatch?.full_data.CertData.Certificate.certificateID).then(
        (response) => {
          window.open(response.data.displayMsg, '_blank', 'noreferrer');
          this.certLoading = false
        }
      ).finally(() => {
        this.certLoading = false
      })
    },
    getCarrier(value) {
      this.loading = true
      axios.post(config.apiUrl + '/api/counterparty/carrier/saferwatch?identity=' + value)
        .then((response) => {
          this.carrier = response.data
          this.findBySW(value)
          this.showData = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    findBySW(value) {
      axios.get(config.apiUrl + '/api/counterparty/' + value + '/sw').then(
        (response) => {
          this.carrierExists = true
          this.carrierId = response.data.counterparty_id
        }
      ).catch(
        () => {
          this.carrierExists = false
        }
      )
    },
    saveTo() {
      EventBus.$emit("update:carrier-data", this.carrier)
      this.$emit('update:close', false)
    },
  }
}
</script>
