<template>
  <v-row justify="center">
    <v-dialog
        :value="true"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ team['id'] !== null ? 'Edit team' : 'Add team' }} </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
                v-model="formData.name"
                :rules="validation.required"
                label="Name"
                required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="$store.state.teams.inEdit = null"
          >
            Close
          </v-btn>
          <v-btn
              :disabled="saveInProgress"
              color="blue darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "TeamEdit",
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  created () {
    this.formData = JSON.parse(JSON.stringify(this.team));
  },
  data: () => ({
    formData: null,
    saveInProgress: false,
    validation: {
      required: [
        v => !!v || 'Name is required',
      ]
    }
  }),
  methods: {
    save () {
      if (this.$refs.form.validate()) {
        this.saveInProgress = true;
        this.$store.dispatch('teams/save', this.formData)
            .then((response) => {
              this.$store.state.teams.inEdit = null;
              this.saveInProgress = false;
              this.$emit('saved', response.data)
            })
            .catch(() => {})
            .finally(() => {
              this.saveInProgress = false;
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
