<template>
  <div>
    <v-text-field
        :value="carrier.total_amount"
        class="mini icon-green"
        hide-details="auto"
        label="Carrier fee"
        @click="open"
        append-icon="mdi-cash-plus"
        outlined dense readonly
    >
    </v-text-field>
    <v-dialog v-model="isOpen"
              @keydown.esc="close"
              @click:outside="close"
              max-width="900px" overlay-opacity="0.5">
      <v-card>
        <v-toolbar flat dense dark color="secondary" style="width:100%">
          <div class="w-100 align-items-center d-flex justify-content-between">
            <v-toolbar-title>Carrier fee</v-toolbar-title>
            <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="tab" slider-color="transparent">
            <v-tab transition="false">Agreed Rate</v-tab>
            <v-tab transition="false">Charges</v-tab>
            <v-tab transition="false">Advances</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pt-2">
            <v-tab-item eager :transition="false">
              <v-row>
                <v-col :md="isTaxable ? 3 : 6">
                  <v-text-field
                      autofocus
                      v-model.number="carrier.rate"
                      class="mini"
                      hide-details="auto"
                      label="Rate"
                      outlined dense
                      v-on:keyup.enter="isOpen = false"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="3" v-if="isTaxable">
                  <TaxIncludedSelect v-model="carrier.taxes" outlined dense />
                </v-col>
                <v-col md="6">
                  <v-text-field
                      readonly
                      v-model.number="carrier.total_amount"
                      class="mini"
                      hide-details="auto"
                      label="Total Amount / Taxes"
                      :suffix="taxTypeLabel"
                      outlined dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-text-field
                      v-model.number="carrier.fcs_value"
                      class="mini icon-green"
                      :append-icon="carrier.fcs_type === 'percent' ? 'mdi-percent' : 'mdi-numeric'"
                      type="number"
                      label="F.S.C. / Rate"
                      hide-details="auto"
                      outlined dense
                      @click:append="carrier.fcs_type = carrier.fcs_type === 'percent' ? 'amount' : 'percent'"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                      v-model.number="carrier['picks_drops']"
                      class="mini"
                      hide-details="auto"
                      label="Picks/Drops"
                      type="number"
                      outlined dense
                  >
                  </v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                      :value="carrier.total_rate"
                      class="mini"
                      hide-details="auto"
                      label="Total rate"
                      type="number"
                      outlined dense readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                      :value="carrier.balance_owing"
                      class="mini"
                      hide-details="auto"
                      label="Balance owing"
                      type="number"
                      outlined dense readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="justify-content: flex-end; align-items: center;">
                <v-col style="max-width: 120px">
                    Use factoring
                </v-col>
                <v-col style="max-width: 50px">
                  <v-checkbox
                    v-model="useFactoringCompany"
                    style="position: relative; top: -3px;"
                    hide-details
                    class="shrink mr-2 mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col style="max-width: 441px">
                    <v-text-field readonly class="mini"
                                  hide-details="auto"
                                  label="Factoring company"
                                  outlined dense :value="carrier.factoring_company?.name ?? 'Not set'"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item eager :transition="false">
              <OtherCharges v-model="carrier['other_charges']" type="default"></OtherCharges>
            </v-tab-item>
            <v-tab-item eager :transition="false">
              <OtherCharges v-model="carrier['other_charges']" type="advances"></OtherCharges>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OtherCharges from "@/components/loads/OtherCharges";
import TaxIncludedSelect from "@/components/loads/TaxIncludedSelect.vue";
import CounterpartySelect from "@/components/counterparty/CounterpartySelect.vue";
export default {
  name: "CarrierFee",
  components: {CounterpartySelect, TaxIncludedSelect, OtherCharges},
  props: {
    carrier: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false,
      tab: 0,
      include_tax: 'Zero rate',
    }
  },
  computed: {
    useFactoringCompany: {
        get () {
            return this.carrier.factoring_company_id !== null
        },
        set (value) {
            if (value && this.carrier.carrier.carrier_data.factoring_company) {
                this.carrier.factoring_company_id = this.carrier.carrier.carrier_data.factoring_company['id']
                this.carrier.factoring_company = this.carrier.carrier.carrier_data.factoring_company
            } else {
                this.carrier.factoring_company_id = null
                this.carrier.factoring_company = null
            }
        }
    },
    taxType () {
      return this.$store.state.taxes.taxes.find((t) => t.state === this.carrier.load_carrier_consignees.at(-1).state)
    },
    taxTypeLabel () {
      if (!this.taxType) {
        return ''
      }
      return this.taxType.state + ' / ' + this.taxType.rate + '% = ' + (this.taxAmountLabel).toFixed(2)
    },
    taxAmountLabel() {
      if (this.carrier.taxes === 'After taxes') {
        return this.carrier.total_amount  - (this.carrier.total_amount / (1 + (this.taxType?.rate ?? 0) / 100))
      }
      if (this.carrier.taxes === 'Zero rate') {
        return 0
      }
      return this.carrier.total_amount - this.carrier.total_amount_net
    },
    taxAmount() {
      return this.carrier.taxes === 'Before taxes' ? (this.carrier.total_amount * ((this.taxType?.rate ?? 0) / 100)) : 0
    },
    isTaxable() {
      return this.carrier.load_carrier_consignees.at(-1).country === 'CA'
    },
    totalRateDependencies () {
      return [this.carrier.rate, this.carrier.picks_drops, this.carrier.fcs_type, this.carrier.fcs_value, this.carrier.other_charges];
    },
    totalAmountDependencies () {
      return [this.carrier.rate, this.carrier.picks_drops, this.carrier.fcs_type, this.carrier.fcs_value, this.carrier.other_charges, this.carrier.taxes];
    },
    rateDependecies () {
      return [this.carrier.net_rate, this.carrier.taxes]
    }
  },
  methods: {
    open () {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    calculateCharges() {
      const other_charges = this.carrier.other_charges;
      let total = 0;
      for (const charge of other_charges) {
        total = parseInt(charge.value, 10) + total
      }
      this.carrier.total_amount = total + this.carrier.total_rate
    }
  },
  watch: {
    totalRateDependencies () {
      const rate = !isNaN(parseFloat(this.carrier.rate)) ? parseFloat(this.carrier.rate) : 0;
      const picks_drops = !isNaN(parseFloat(this.carrier.picks_drops)) ? parseFloat(this.carrier.picks_drops) : 0;
      const fcs_value = !isNaN(parseFloat(this.carrier.fcs_value)) ? parseFloat(this.carrier.fcs_value) : 0;
      this.carrier.total_rate = rate + picks_drops + (this.carrier.fcs_type === 'amount' ? fcs_value : (rate * fcs_value / 100));
    },
    totalAmountDependencies: {
      handler (value) {
        if (!Array.isArray(this.carrier.other_charges)) {
          return;
        }
        this.calculateCharges();
      },
      deep: true
    },
  },
  mounted() {
    this.calculateCharges()
  }
}
</script>

<style scoped>

</style>
