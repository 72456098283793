import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"1000px","max-height":"800px","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('close')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('close')}},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("File manager - #"+_vm._s(_vm.load.id))]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,{staticClass:"pt-3"},[_c('blocking-layover',{staticClass:"d-block",staticStyle:{"height":"auto"},attrs:{"blocking-condition":_vm.restrictedEdit}},[(_vm.load?.load_carriers?.length >= 1)?[_c('div',{staticClass:"my-2"},[_vm._v("Documents related to this load: ")]),_c('FilesList',{staticClass:"files_list",attrs:{"additionalData":_vm.additionalData,"entity-type":"load","entity-id":_vm.load.id}})]:_vm._e(),_vm._l((_vm.load.load_carriers.filter(load => load.carrier)),function(carrier,index){return _c('div',[_c('div',{staticClass:"my-2"},[_vm._v("Documents related for "),(_vm.load.load_carriers.length > 1)?[_vm._v(_vm._s(index + 1))]:_vm._e(),_vm._v(" carrier ("+_vm._s(carrier?.carrier?.name)+"): ")],2),(carrier?.carrier?.carrier_data?.factoring_company)?_c('div',{staticClass:"d-flex my-2 align-items-center"},[_c('input',{staticClass:"shrink mr-2 mt-0",staticStyle:{"position":"relative"},attrs:{"type":"checkbox","id":'checkbox_label' + index,"hide-details":""},domProps:{"checked":carrier.factoring_company_id,"value":carrier.factoring_company_id},on:{"input":function($event){return _vm.$emit('setCarrierFactoringCompany', {
                  index, value: $event.target.value
                })}}}),_c('label',{staticClass:"mr-1 mb-0",staticStyle:{"font-size":"1rem"},attrs:{"role":"button","for":'checkbox_label' + index}},[_vm._v("Use factoring company ")]),_c(VTextField,{staticClass:"mini",attrs:{"readonly":"","hide-details":"auto","label":"Factoring company","outlined":"","dense":"","value":carrier.factoring_company?.name ?? 'Not set'}})],1):_vm._e(),_c('FilesList',{staticClass:"files_list",attrs:{"roles":_vm.load?.load_carriers?.length === 1 ? ['load_carrier_single'] : ['load_carrier_multiple'],"colSize":2,"additionalParams":{ 'load_id': _vm.load.id, 'carrier_id': carrier.carrier_id },"entity-id":carrier.carrier_id,"entity-type":"counterparty"}})],1)})],2),_c('blocking-layover',{attrs:{"blocking-condition":_vm.restrictedEdit}},[_c('Files',{staticStyle:{"width":"100%"},attrs:{"type":'load',"id":_vm.load.id,"entity":_vm.load}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }