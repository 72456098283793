<template>
  <div class="pt-3">
    <v-row v-for="(item) in items" class="mb-0">
      <v-col :md="item.type === 'advances' ? 4 : 7">
        <v-text-field v-model="item.name" hide-details="auto" dense outlined label="Charge"></v-text-field>
      </v-col>
      <v-col v-if="item.type === 'advances'" md="3">
        <v-menu v-model="datePickerOpened" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="item.date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              hide-details="auto" dense outlined clearable label="Date"></v-text-field>
          </template>
          <v-date-picker v-model="item.date" @input="datePickerOpened = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="2">
        <v-text-field v-model.number="item.value" hide-details="auto" dense outlined label="Amount"
          type="number"></v-text-field>
      </v-col>
      <v-col md="2">

        <v-select v-model="item.currency" dense outlined label="Currency" :items="currencies" :item-value="'CAD'" />
      </v-col>
      <v-col md="1" class="text-right">
        <v-btn tile icon @click="remove(item)">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-center mt-2">
      <v-btn @click="add(type)" color="success">ADD</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherCharges",
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    type: {
      type: String,
      default() {
        return 'default';
      }
    }
  },
  data() {
    return {
      datePickerOpened: false,
      currencies: ['CAD', 'USD']
    }
  },
  computed: {
    items () {
      return this.value.filter(item => item.type === this.type) 
    } 
  },
  methods: {
    add(type = 'default') {
      this.value.push({
        name: '',
        value: null,
        currency: 'CAD',
        type: type,
        date: null
      })
    },
    remove(item) {
      this.value.splice(this.value.indexOf(item), 1)
    }
  }
}
</script>

<style scoped>
.row+.row {
  margin-top: 0;
}
</style>
