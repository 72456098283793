<template>
  <div class="gray-bg reports pl-15">
    <v-container fluid>
      <div class="d-flex align-items-center justify-content-between pb-2">

        <h5 class="m-0">{{ items[selectedItem].text }}</h5>

        <div class="d-flex align-items-center">
          <v-select v-model="filters.accounting_company_id" item-text="name" item-value="id" :items="accountingCompanies"
            label="Select bank account" hide-details dense solo flat class="mr-3" />
          <v-select v-model="filters.year" :items="[2024]" label="Accounting year" hide-details dense solo flat />
        </div>
      </div>

      <v-navigation-drawer dense absolute expand-on-hover @transitionend="menuOpenned = !menuOpenned">
        <v-list dense>
          <v-subheader>Reports</v-subheader>
          <v-list-item-group v-model="selectedItem" color="secondary">
            <v-list-item v-for="(item, i) in items" :key="i" @click="getPage(item.action, item.text)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item link to="/accounting">
            <v-list-item-icon>
              <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Accounting Manager
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/reports/salary">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Salaries
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item link @click="openBankModal">
            <v-list-item-icon>
              <v-icon>mdi-bank</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Bank accounts
              </v-list-item-title>
              <div>
                <div class="my-3" v-for="bank in banks">
                  {{ bank.name }}:
                  <strong>{{ formattedAmount(bank.balance, bank.currency) }} </strong>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-row>
        <v-col md="12">
          <v-row>
            <v-col md="4">
              <v-skeleton-loader v-if="loading" type="card, table-row"></v-skeleton-loader>
            </v-col>
            <v-col md="4">
              <v-skeleton-loader v-if="loading" type="card, table-row"></v-skeleton-loader>
            </v-col>
            <v-col md="4">
              <v-skeleton-loader v-if="loading" type="card, table-row"></v-skeleton-loader>
            </v-col>
          </v-row>
          <Sales v-if="selectedItem === 0" :data="reports.sales" :filters="filters" @loading="loadingStatus" />
          <CFO v-if="selectedItem === 1" :data="reports.cfo" :filters="filters" @loading="loadingStatus" />
          <CEO v-if="selectedItem === 2" :data="reports.ceo" :filters="filters"  @loading="loadingStatus" />
          <Receivable v-if="selectedItem === 3" :data="reports.receivable" :filters="filters" @loading="loadingStatus" />
          <DashboardPayable v-if="selectedItem === 4" :data="reports.receivable" :filters="filters" @loading="loadingStatus" />
          <Budget v-if="selectedItem === 5" :data="reports.receivable" :filters="filters" @loading="loadingStatus" />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="bankModal" max-width="1200">
      <BankAccount />
    </v-dialog>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Sales from '@/views/dashboard/Sales'
import CFO from '@/views/dashboard/CFO'
import CEO from '@/views/dashboard/CEO'
import Receivable from '@/views/dashboard/Receivable'
import BankAccount from './dashboard/widgets/BankAccount.vue'
import axios from "@/plugins/axios";
import config from "@/config";
import Budget from './dashboard/Budget.vue'
import DashboardPayable from "@/views/dashboard/Payable.vue";
export default {
  name: "Dashboard",
  components: {
    DashboardPayable,
    apexchart: VueApexCharts,
    Sales,
    CFO,
    CEO,
    Receivable,
    BankAccount,
    Budget
},
  computed: {
    breads() {
      return [
        {
          text: "TRANSIMEX Global",
          disabled: false,
          href: "/",
        },
        {
          text: "Dashboard",
          disabled: true,
        },
      ];
    },
  },
  data() {
    return {
      loading: false,
      bankModal: false,
      menuOpenned: false,
      year: 2023,
      banks: [],
      selectedItem: 0,
      filters: {
        accounting_company_id: null,
        year: 2024,
      },
      items: [
        //{ text: 'General statistics', icon: 'mdi-chart-box-outline', action: null },
        { text: 'Sales and Profit', icon: 'mdi-percent-outline', action: 'sales' },
        { text: 'CFO Dashboard', icon: 'mdi-finance', action: 'cfo' },
        { text: 'CEO Dashboard', icon: 'mdi-office-building', action: 'ceo' },
        { text: 'Account Receivable', icon: 'mdi-arrow-left', action: 'income' },
        { text: 'Account Payable', icon: 'mdi-arrow-right', action: 'outcome' },
        { text: 'Budget', icon: 'mdi-cash-multiple', action: 'budget' },
      ],
      reports: {
        sales: {
          revenue: {
            count: 245554,
            series: [
              {
                data: [
                  {
                    x: 'Jan',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Feb',
                    y: 950000,
                    goals: [
                      {
                        name: 'Target',
                        value: 980000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Mar',
                    y: 1050000,
                    goals: [
                      {
                        name: 'Target',
                        value: 900000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Apr',
                    y: 1250000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1500000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'May',
                    y: 1150000,
                  },
                  {
                    x: 'Jun',
                    y: 1450000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1300000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Jul',
                    y: 1050000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Aug',
                    y: 1650000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1300000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Sep',
                    y: 1050000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1200000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Oct',
                    y: 1250000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1100000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Nov',
                    y: 1255486,
                    goals: [
                      {
                        name: 'Target',
                        value: 1300000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Dec',
                    y: 1250000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1200000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                ]
              }
            ],
          },
          target: {
            count: 101,
            series: [
              {
                data: [12, 0, 15, 18, 13, 0, 0, 0, 0, 11.5, 4.3, 3.2
                ]
              },
              {
                data: [0, -12, 0, 0, 0, -13, -2.0, -11.2, -0.5, 0, 0, 0
                ]
              }
            ],
          },
          profit: {
            count: 45231,
            series: [
              {
                data: []
              },
            ],
          },
          profit_target: {
            count: 80,
            series: [
              {
                data: [12, 20, 0, 28, 23, 0, 0, 0, 0, 23.5, 14.3, 3.2
                ]
              },
              {
                data: [0, 0, -8, 0, 0, -23, -2.0, -31.2, -7.5, 0, 0, 0
                ]
              }
            ],
          },
          teams_volume: {
            series: [
              {
                data: [

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                ]
              }
            ]
          },
          teams_revenue: {
            series: [
              {
                data: [

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                ]
              }
            ]
          },
          teams_profit: {
            series: [
              {
                data: [

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                ]
              }
            ]
          }
        },
        cfo: {
          gross_profit_margin: {
            count: 80,
            target: 29,
            last: 27,
            series: [25],
          },
          operating_profit_margin: {
            count: 80,
            target: 29,
            last: 27,
            series: [17],
          },
          net_profit_margin: {
            count: 80,
            target: 29,
            last: 27,
            series: [12],
          },
          revenue: {
            count: 80,
            target: -10,
            last: -20
          },
          gross: {
            count: 80,
            target: 110,
            last: 20
          },
          operating: {
            count: 80,
            target: 110,
            last: -20
          },
          net: {
            count: 80,
            target: 100,
            last: -20
          },
          income_statement: {
            categories: [
              'Total Revenue',
              'Cost of Services',
              'Total Operating Expenses',
              'Other Non-Operating Income',
              'Finance Expense',
              'Taxes',
              'Net Profit Before Tax'
            ],
            series: [
              {
                data: [0, 0, 0, 0, 0, 0]
              }
            ],
          },
          net_operating_cash_flow: {
            count: 1089845
          },
          net_financing_cash_flow: {
            count: 1079845
          },
          net_investing_cash_flow: {
            count: 1079845
          },
          cash_end_month: {
            count: 1079845
          },
          days_receivable_outstanding: 75,
          receivable: {
            total: 1350,
            overdue: 605,
            percent: (605 / 1350) * 100
          },
          receivable_chart: {
            series: [
              {
                data: [

                  {
                    x: '< 30 days',
                    y: 540,
                  },
                  {
                    x: '< 60 days',
                    y: 405,
                  },
                  {
                    x: '< 90 days',
                    y: 270,
                  },
                  {
                    x: '> 90 days',
                    y: 135,
                  }
                ]
              }
            ],
          },
          days_payable_outstanding: 89,
          payable: {
            total: 1350,
            overdue: 605,
            percent: (605 / 1350) * 100
          },
          payable_chart: {
            series: [
              {
                data: [

                  {
                    x: '< 30 days',
                    y: 540,
                  },
                  {
                    x: '< 60 days',
                    y: 405,
                  },
                  {
                    x: '< 90 days',
                    y: 270,
                  },
                  {
                    x: '> 90 days',
                    y: 135,
                  }
                ]
              }
            ],
          },

          current_ratio: {
            count: 1.5,
            series: [
              {
                data: [
                  {
                    x: 'Jan',
                    y: 1.5,
                  },
                  {
                    x: 'Feb',
                    y: 1.8,
                  },
                  {
                    x: 'Mar',
                    y: 1.6,
                  },
                  {
                    x: 'Apr',
                    y: 1.4,
                  },
                  {
                    x: 'May',
                    y: 1.3,
                  },
                  {
                    x: 'Jun',
                    y: 1.5,
                  },
                  {
                    x: 'Jul',
                    y: 1.7,
                  },
                  {
                    x: 'Aug',
                    y: 1.8,
                  },
                  {
                    x: 'Sep',
                    y: 1.9,
                  },
                  {
                    x: 'Oct',
                    y: 2.0,
                  },
                  {
                    x: 'Nov',
                    y: 1.8,
                  },
                  {
                    x: 'Dec',
                    y: 1.9,
                  },
                ]
              }
            ]
          },
          quick_ratio: {
            count: 1.1,
            series: [
              {
                data: [
                  {
                    x: 'Jan',
                    y: 1.8,
                  },
                  {
                    x: 'Feb',
                    y: 1.5,
                  },
                  {
                    x: 'Mar',
                    y: 1.4,
                  },
                  {
                    x: 'Apr',
                    y: 1.5,
                  },
                  {
                    x: 'May',
                    y: 1.6,
                  },
                  {
                    x: 'Jun',
                    y: 1.5,
                  },
                  {
                    x: 'Jul',
                    y: 1.7,
                  },
                  {
                    x: 'Aug',
                    y: 1.8,
                  },
                  {
                    x: 'Sep',
                    y: 1.9,
                  },
                  {
                    x: 'Oct',
                    y: 2.0,
                  },
                  {
                    x: 'Nov',
                    y: 1.8,
                  },
                  {
                    x: 'Dec',
                    y: 1.9,
                  },
                ]
              }
            ]
          },
          debt_ratio: {
            count: 44 + '%',
            series: [
              {
                data: [
                  {
                    x: 'Jan',
                    y: 45,
                  },
                  {
                    x: 'Feb',
                    y: 40,
                  },
                  {
                    x: 'Mar',
                    y: 35,
                  },
                  {
                    x: 'Apr',
                    y: 33,
                  },
                  {
                    x: 'May',
                    y: 38,
                  },
                  {
                    x: 'Jun',
                    y: 42,
                  },
                  {
                    x: 'Jul',
                    y: 44,
                  },
                  {
                    x: 'Aug',
                    y: 49,
                  },
                  {
                    x: 'Sep',
                    y: 50,
                  },
                  {
                    x: 'Oct',
                    y: 45,
                  },
                  {
                    x: 'Nov',
                    y: 46,
                  },
                  {
                    x: 'Dec',
                    y: 48,
                  },
                ]
              }
            ]
          },
        },
        ceo: {
          sales: {
            count: 1000080,
            target: -10,
            last: -20
          },
          gross: {
            count: 1000080,
            target: -10,
            last: -20
          },
          operating: {
            count: 1000080,
            target: -10,
            last: -20
          },
          net: {
            count: 1000080,
            target: -10,
            last: -20
          },
          teams_volume: {
            series: [
              {
                data: [

                  {
                    x: 'Team 1',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 2',
                    y: 520000,
                    goals: [
                      {
                        name: 'Target',
                        value: 600000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 3',
                    y: 1100000,
                    goals: [
                      {
                        name: 'Target',
                        value: 700000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },

                  {
                    x: 'Team 4',
                    y: 850000,
                    goals: [
                      {
                        name: 'Target',
                        value: 900000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Team 5',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1500000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Team 6',
                    y: 700000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                ]
              }
            ]
          },

          actual_target: {
            count: 245554,
            series: [
              {
                data: [
                  {
                    x: 'Jan',
                    y: 1200000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Feb',
                    y: 950000,
                    goals: [
                      {
                        name: 'Target',
                        value: 980000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Mar',
                    y: 1050000,
                    goals: [
                      {
                        name: 'Target',
                        value: 900000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Apr',
                    y: 1250000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1500000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'May',
                    y: 1150000,
                  },
                  {
                    x: 'Jun',
                    y: 1450000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1300000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Jul',
                    y: 1050000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1000000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Aug',
                    y: 1650000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1300000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Sep',
                    y: 1050000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1200000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Oct',
                    y: 1250000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1100000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Nov',
                    y: 1255486,
                    goals: [
                      {
                        name: 'Target',
                        value: 1300000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                  {
                    x: 'Dec',
                    y: 1250000,
                    goals: [
                      {
                        name: 'Target',
                        value: 1200000,

                        strokeDashArray: 0,
                        strokeColor: '#000'
                      }
                    ]
                  },
                ]
              }
            ]
          },
          income_statement: {
            categories: [
              'Total Revenue',
              'Cost of Services',
              'Total Operating Expenses',
              'Other Non-Operating Income',
              'Finance Expense',
              'Net Profit Before Tax'
            ],
            series: [
              {
                data: [108942, 76620, 13096, 855, 4389, 15692]
              }
            ],
          },
          net_operating: {
            series: [
              {
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              },
              {
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              }
            ],
          },
          sales_growth: {
            series: [
              {
                data: [2, 10, 0, 18, 13, 0, 0, 0, 0, 13.5, 4.3, 3.2
                ]
              },
              {
                data: [0, 0, -8, 0, 0, -13, -2.0, -21.2, -0.5, 0, 0, 0
                ]
              }
            ],
          },
          cost_sales: {
            count: 245554,
            series: [
              {
                name: 'Sales',
                data: [
                  {
                    x: 'Jan',
                    y: 1200000,
                  },
                  {
                    x: 'Feb',
                    y: 950000,
                  },
                  {
                    x: 'Mar',
                    y: 1050000,
                  },
                  {
                    x: 'Apr',
                    y: 1250000,
                  },
                  {
                    x: 'May',
                    y: 1150000,
                  },
                  {
                    x: 'Jun',
                    y: 1450000,
                  },
                  {
                    x: 'Jul',
                    y: 1050000,
                  },
                  {
                    x: 'Aug',
                    y: 1650000,
                  },
                  {
                    x: 'Sep',
                    y: 1050000,
                  },
                  {
                    x: 'Oct',
                    y: 1250000,
                  },
                  {
                    x: 'Nov',
                    y: 1255486,
                  },
                  {
                    x: 'Dec',
                    y: 1250000,
                  },
                ]
              },
              {
                name: 'Costs',
                data: [
                  {
                    x: 'Jan',
                    y: 120000,
                  },
                  {
                    x: 'Feb',
                    y: 95000,
                  },
                  {
                    x: 'Mar',
                    y: 105000,
                  },
                  {
                    x: 'Apr',
                    y: 125000,
                  },
                  {
                    x: 'May',
                    y: 115000,
                  },
                  {
                    x: 'Jun',
                    y: 145000,
                  },
                  {
                    x: 'Jul',
                    y: 105000,
                  },
                  {
                    x: 'Aug',
                    y: 165000,
                  },
                  {
                    x: 'Sep',
                    y: 105000,
                  },
                  {
                    x: 'Oct',
                    y: 125000,
                  },
                  {
                    x: 'Nov',
                    y: 125586,
                  },
                  {
                    x: 'Dec',
                    y: 125000,
                  },
                ]
              }
            ]
          },
          days_receivable_outstanding: 75,
          receivable: {
            total: 1350,
            overdue: 605,
            percent: (605 / 1350) * 100
          },
          receivable_chart: {
            series: [
              {
                data: [

                  {
                    x: '< 30 days',
                    y: 540,
                  },
                  {
                    x: '< 60 days',
                    y: 405,
                  },
                  {
                    x: '< 90 days',
                    y: 270,
                  },
                  {
                    x: '> 90 days',
                    y: 135,
                  }
                ]
              }
            ],
          },
          days_payable_outstanding: 89,
          payable: {
            total: 1350,
            overdue: 605,
            percent: (605 / 1350) * 100
          },
          payable_chart: {
            series: [
              {
                data: [

                  {
                    x: '< 30 days',
                    y: 540,
                  },
                  {
                    x: '< 60 days',
                    y: 405,
                  },
                  {
                    x: '< 90 days',
                    y: 270,
                  },
                  {
                    x: '> 90 days',
                    y: 135,
                  }
                ]
              }
            ],
          },
        },
        receivable: {
          status: {
            sales: {
              count: 1000080,
            },
            receivable: {
              count: 75080,
            },
            overdue: {
              count: 75080,
            },
            percent: {
              count: 45,
            },
            net: {
              count: 548710,
            },
          },
          sales: {
            series: [
              {
                name: 'Sales',
                type: 'column',
                data: [
                  {
                    x: 'Jan',
                    y: 220000,
                  },
                  {
                    x: 'Feb',
                    y: 105000,
                  },
                  {
                    x: 'Mar',
                    y: 205000,
                  },
                  {
                    x: 'Apr',
                    y: 225000,
                  },
                  {
                    x: 'May',
                    y: 205000,
                  },
                  {
                    x: 'Jun',
                    y: 245000,
                  },
                  {
                    x: 'Jul',
                    y: 205000,
                  },
                  {
                    x: 'Aug',
                    y: 265000,
                  },
                  {
                    x: 'Sep',
                    y: 205000,
                  },
                  {
                    x: 'Oct',
                    y: 225000,
                  },
                  {
                    x: 'Nov',
                    y: 225546,
                  },
                  {
                    x: 'Dec',
                    y: 225000,
                  },
                ]
              },
              {
                name: 'Costs',
                type: 'column',
                data: [
                  {
                    x: 'Jan',
                    y: 120000,
                  },
                  {
                    x: 'Feb',
                    y: 95000,
                  },
                  {
                    x: 'Mar',
                    y: 105000,
                  },
                  {
                    x: 'Apr',
                    y: 125000,
                  },
                  {
                    x: 'May',
                    y: 115000,
                  },
                  {
                    x: 'Jun',
                    y: 145000,
                  },
                  {
                    x: 'Jul',
                    y: 105000,
                  },
                  {
                    x: 'Aug',
                    y: 165000,
                  },
                  {
                    x: 'Sep',
                    y: 105000,
                  },
                  {
                    x: 'Oct',
                    y: 125000,
                  },
                  {
                    x: 'Nov',
                    y: 125586,
                  },
                  {
                    x: 'Dec',
                    y: 125000,
                  },
                ]
              },
              {
                name: 'Overdue',
                type: 'line',
                data: [
                  {
                    x: 'Jan',
                    y: 200000,
                  },
                  {
                    x: 'Feb',
                    y: 100000,
                  },
                  {
                    x: 'Mar',
                    y: 95000,
                  },
                  {
                    x: 'Apr',
                    y: 95000,
                  },
                  {
                    x: 'May',
                    y: 100000,
                  },
                  {
                    x: 'Jun',
                    y: 115000,
                  },
                  {
                    x: 'Jul',
                    y: 95000,
                  },
                  {
                    x: 'Aug',
                    y: 105000,
                  },
                  {
                    x: 'Sep',
                    y: 95000,
                  },
                  {
                    x: 'Oct',
                    y: 105000,
                  },
                  {
                    x: 'Nov',
                    y: 105586,
                  },
                  {
                    x: 'Dec',
                    y: 115000,
                  },
                ]
              }
            ]
          },
        },
        payable: {
          status: {
            sales: {
              count: 1000080,
            },
            receivable: {
              count: 75080,
            },
            overdue: {
              count: 75080,
            },
            percent: {
              count: 45,
            },
            net: {
              count: 548710,
            },
          },
          sales: {
            series: [
              {
                name: 'Sales',
                type: 'column',
                data: [
                  {
                    x: 'Jan',
                    y: 220000,
                  },
                  {
                    x: 'Feb',
                    y: 105000,
                  },
                  {
                    x: 'Mar',
                    y: 205000,
                  },
                  {
                    x: 'Apr',
                    y: 225000,
                  },
                  {
                    x: 'May',
                    y: 205000,
                  },
                  {
                    x: 'Jun',
                    y: 245000,
                  },
                  {
                    x: 'Jul',
                    y: 205000,
                  },
                  {
                    x: 'Aug',
                    y: 265000,
                  },
                  {
                    x: 'Sep',
                    y: 205000,
                  },
                  {
                    x: 'Oct',
                    y: 225000,
                  },
                  {
                    x: 'Nov',
                    y: 225546,
                  },
                  {
                    x: 'Dec',
                    y: 225000,
                  },
                ]
              },
              {
                name: 'Costs',
                type: 'column',
                data: [
                  {
                    x: 'Jan',
                    y: 120000,
                  },
                  {
                    x: 'Feb',
                    y: 95000,
                  },
                  {
                    x: 'Mar',
                    y: 105000,
                  },
                  {
                    x: 'Apr',
                    y: 125000,
                  },
                  {
                    x: 'May',
                    y: 115000,
                  },
                  {
                    x: 'Jun',
                    y: 145000,
                  },
                  {
                    x: 'Jul',
                    y: 105000,
                  },
                  {
                    x: 'Aug',
                    y: 165000,
                  },
                  {
                    x: 'Sep',
                    y: 105000,
                  },
                  {
                    x: 'Oct',
                    y: 125000,
                  },
                  {
                    x: 'Nov',
                    y: 125586,
                  },
                  {
                    x: 'Dec',
                    y: 125000,
                  },
                ]
              },
              {
                name: 'Overdue',
                type: 'line',
                data: [
                  {
                    x: 'Jan',
                    y: 200000,
                  },
                  {
                    x: 'Feb',
                    y: 100000,
                  },
                  {
                    x: 'Mar',
                    y: 95000,
                  },
                  {
                    x: 'Apr',
                    y: 95000,
                  },
                  {
                    x: 'May',
                    y: 100000,
                  },
                  {
                    x: 'Jun',
                    y: 115000,
                  },
                  {
                    x: 'Jul',
                    y: 95000,
                  },
                  {
                    x: 'Aug',
                    y: 105000,
                  },
                  {
                    x: 'Sep',
                    y: 95000,
                  },
                  {
                    x: 'Oct',
                    y: 105000,
                  },
                  {
                    x: 'Nov',
                    y: 105586,
                  },
                  {
                    x: 'Dec',
                    y: 115000,
                  },
                ]
              }
            ]
          },
        }
      },
      accountingCompanies: [
        {
          id: null,
          name: 'ALL',
        },
        {
          id: 2,
          name: 'TD Bank (CA)',
        },
        {
          id: 1,
          name: 'Ameris Bank (US)',
        }
      ]
    }
  },
  beforeCreate() {
    if (this.$route.params.role === 'users' && !this.$store.getters.role.includes('admin')) {
      this.$router.push({ name: '404' })
    }
  },
  mounted() {
    axios.get(config.apiUrl + '/api/bank-account').then((response) => {
      this.banks = response.data
    })
  },
  methods: {
    formattedAmount(amount, currency) {
      return amount.toFixed(2) + ' ' + currency
    },
    getPage(value, v2) {
      console.log(value, v2)
    },
    openBankModal() {
      this.bankModal = true

    },
    loadingStatus(status) {
      this.loading = status
    }
  }
}
</script>
