<template>
  <div>
    <v-dialog :value="true" max-width="1200" @click:outside="$emit('closed')">
        <v-snackbar
            v-model="errorSnackbar"
            :timeout="3000"
            color="red accent-2"
        >
            <b>{{ serverError }}</b>
        </v-snackbar>
        <v-snackbar v-model="successSnackbar" :timeout="3000" color="success">
            <b>Successfully saved!</b>
        </v-snackbar>
      <v-card>

        <v-toolbar dark color="secondary" dense>
          <v-toolbar-title>Payment: {{ params.type }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="payment.id === null" dark text @click="savePayment">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-8">
          <v-row>
            <v-col md="6">
              <CounterpartySelect class="mb-3" v-model="payment.counterparty" :roles="['carrier', 'customers']" qb-type="carrier" item-text="name" :quickbooks="true" :account-company-id="1" :currency="payment.currency" />
            </v-col>
            <v-col md="2">

                <v-select class="mb-3" dense outlined :items="banks" v-model="payment.bank_account_id" item-text="name" item-value="id" label="Bank" hide-details />
            </v-col>
            <v-col md="2">

                <v-select class="mb-3" dense outlined :items="categories" v-model="payment.transaction_category_id" item-text="name" item-value="id" label="Category" hide-details />
            </v-col>
            <v-col md="2">

              <v-text-field v-model="payment.amount" dense outlined label="Amount" hide-details />
            </v-col>
            <v-col md="2">
              <v-select class="mb-3" dense outlined :items="currency" v-model="payment.currency" label="Currency" hide-details />
            </v-col>
            <v-col md="12">
                <v-file-input
                    chips
                    multiple
                    label="Attachments"
                    @change="filesSelected"
                ></v-file-input>
            </v-col>
              <v-col v-if="payment.media" md="12">
                  <a v-for="file in payment.media" :href="config.apiUrl + '/api/media/' + file.uuid" class="mr-3">
                      <v-chip>{{ file.name }}</v-chip>
                  </a>
              </v-col>
              <v-col v-if="payment.id" md="12">
                  <div v-if="params.type === 'bill'">
                      bill
                  </div>
                  <div v-else>
                      <v-simple-table class="table-bordered custom-table">
                          <template v-slot:default>
                          <thead>
                            <tr>
                                <th>Invoice</th>
                                <th>Invoice date</th>
                                <th>Due date</th>
                                <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="invoice in payment.invoices">
                                <td>#{{ invoice.loadData.id }}</td>
                                <td>{{ invoice.date }}</td>
                                <td>{{ invoice.due }}</td>
                                <td>{{ invoice.totalDue }} {{ invoice.currency }}</td>
                            </tr>
                          </tbody>
                          </template>
                      </v-simple-table>
                  </div>
              </v-col>
            <v-col v-if="params.counterpartyId" md="12">
                <v-row>
                    <v-col md="1">

                    </v-col>
                    <v-col md="2">
                        {{ params.type === 'bill' ? 'Bill' : 'Invoice' }}
                    </v-col>
                    <v-col md="2">
                        Amount
                    </v-col>
                    <v-col md="2">
                        Currency
                    </v-col>
                    <v-col md="2">
                        Created at
                    </v-col>
                    <v-col md="2">
                        Due date
                    </v-col>
                </v-row>
                <hr />
                <div v-if="params.counterpartyId && params.type === 'invoice'" style="height: 330px; overflow-y: scroll; overflow-x: hidden;">
                    <v-row v-if="invoices" v-for="item in invoices.data" class="row-item" style="height: 36px;">
                        <v-col md="1">
                            <v-checkbox :disabled="selectedInvoices.length >= 1 && item.currency !== selectedInvoices[0].currency" v-model="selectedIds" :value="item.id" hide-details></v-checkbox>
                        </v-col>
                        <v-col md="2">
                            #{{ item.loadData.id }}
                        </v-col>
                        <v-col md="2">
                            {{ item.totalDue }}
                        </v-col>
                        <v-col md="2">
                            {{ item.currency }}
                        </v-col>
                        <v-col md="2">
                            {{ item.date }}
                        </v-col>
                        <v-col md="2">
                            {{ item.due }}
                        </v-col>
                    </v-row>
                    <div v-intersect="loadInvoices" />
                </div>
                <div v-if="params.counterpartyId && params.type === 'bill'" style="height: 330px; overflow-y: scroll; overflow-x: hidden;">
                    <v-row v-if="bills" v-for="item in bills.data" class="row-item" style="height: 36px;">
                        <v-col md="1">
                            <v-checkbox :disabled="selectedBills.length >= 1 && item.currency !== selectedBills[0].currency" v-model="selectedIds" :value="item.id" hide-details></v-checkbox>
                        </v-col>
                        <v-col md="2">
                            #{{ item.id }}
                        </v-col>
                        <v-col md="2">
                            {{ item.amount }}
                        </v-col>
                        <v-col md="2">
                            {{ item.currency }}
                        </v-col>
                        <v-col md="2">
                            {{ item.bill_date }}
                        </v-col>
                        <v-col md="2">
                            {{ item.due_date }}
                        </v-col>
                    </v-row>
                    <div v-intersect="loadBills" />
                </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import config from '@/config'
import axiosInstance from '@/plugins/axios'
import CounterpartySelect from '../counterparty/CounterpartySelect.vue'
import LoadLocationSelect from '../loads/LoadLocationSelect.vue';
import QuickInvoices from './QuickInvoices.vue';
import QuickBills from './QuickBills.vue';
import Files from "@/components/files/List.vue";
import dayjs from 'dayjs';
import {EventBus} from "@/helpers/eventBus";

export default {
  name: "PayQuick",
  props: {
      params: {
          type: Object,
          required: true
      }
  },
  data() {
    return {
        banks: [],
        categories: [],
      payment: {
          id: null,
          type: null,
          counterparty_id: null,
          amount: 0,
          currency: null,
          entity_ids: [],
          media: [],
          counterparty: null,
          payment_relations: [],
      },
      invoices: {
          data: []
      },
      bills: {
          data: []
      },
      selectedIds: [],
      files: [],
      dialog: false,
      currency: ['USD', 'CAD'],
      itemsPage: 1,
      serverError: null,
      errorSnackbar: false
    };
  },
  created() {
    this.payment.type = this.params.type;
    if (this.params.counterpartyId) {
        axiosInstance.get(config.apiUrl + '/api/counterparty/' + this.params.counterpartyId)
            .then((response) => {
                this.payment.counterparty = response.data;
                this.payment.counterparty_id = response.data.id;
            })
        if (this.params.type === 'bill') {
            axiosInstance.get(config.apiUrl + '/api/bill', {
                params: {
                    carrier_id: this.params.counterpartyId,
                    states: ['partially_paid', 'unpaid'],
                    itemsPerPage: 10
                }
            })
                .then((response) => {
                    this.bills.data = response.data.data;
                })
        } else {
            axiosInstance.get(config.apiUrl + '/api/invoice', {
                params: {
                    counterparty_id: this.params.counterpartyId,
                    status: ['partially_paid', 'unpaid'],
                    itemsPerPage: 10
                }
            })
                .then((response) => {
                    this.invoices = response.data;
                })
        }
    } else {
        if (this.params.type === 'bill') {
            if (this.params.itemIds.length === 1) {
                axiosInstance.get(config.apiUrl + '/api/bill/' + this.params.itemIds[0])
                    .then((response) => {
                        this.payment.amount = response.data.data.amount;
                        this.payment.counterparty = response.data.data.carrier;
                        this.payment.counterparty_id = response.data.data.carrier_id;
                        this.payment.type = 'bill';
                        this.payment.currency = response.data.data.currency;
                        this.payment.entity_ids = [response.data.data.id];

                        this.bills.data.push(response.data.data);
                    })
            } else {
                axiosInstance.get(config.apiUrl + '/api/bill', {
                    params: {
                        ids: this.params.itemIds,
                        itemsPerPage: 50,
                    }
                })
                    .then((response) => {
                        this.bills.data = response.data.data;
                    });
            }
        } else {
            if (this.params.itemIds.length === 1) {
                axiosInstance.get(config.apiUrl + '/api/payments/invoice/' + this.params.itemIds[0]).then((response) => {
                    if (response.data.data[0]) {
                      this.payment = response.data.data[0]
                    } else {
                        axiosInstance.get(config.apiUrl + '/api/invoice/' + this.params.itemIds[0])
                            .then((response) => {
                                this.payment.amount = response.data.total;
                                this.payment.counterparty = response.data.customer;
                                this.payment.counterparty_id = response.data.counterparty_id;
                                this.payment.type = 'invoice';
                                this.payment.currency = response.data.currency;
                                this.payment.entity_ids = [response.data.id];

                                this.invoices.push(response.data);
                            })
                    }
                })
            } else {
                axiosInstance.get(config.apiUrl + '/api/invoice', {
                    params: {
                        ids: this.params.itemIds,
                        itemsPerPage: 50,
                    }
                })
                    .then((response) => {
                        this.invoices = response.data.data;
                    });
            }
        }
    }
  },
  mounted() {

    axiosInstance.get(config.apiUrl + '/api/transaction-category?with[]=children.children').then((response) => {
        this.categories = response.data.data
      })
    axiosInstance.get(config.apiUrl + '/api/bank-account').then((response) => {
        this.banks = response.data
      })
  },
    computed: {
        selectedBills () {
            if (!this.bills || !this.bills.data) {
                return []
            }
            return this.bills.data.filter(i => this.selectedIds.includes(i.id))
        },
        selectedInvoices () {
            return this.invoices.data.filter(i => this.selectedIds.includes(i.id))
        },
        paymentAmountBasedOnItems () {
            let amount = 0;
            if (this.params.type === 'bill') {
                this.selectedBills.forEach((b) => {
                    amount += b.amount
                });
            } else {
                this.selectedInvoices.forEach((b) => {
                    amount += b.totalDue
                });
            }
            return amount;
        },
        config () {
            return config
        }
    },
  methods: {
      loadInvoices () {
          this.itemsPage++;
          axiosInstance.get(config.apiUrl + '/api/invoice', {
              params: {
                  counterparty_id: this.params.counterpartyId,
                  status: ['partially_paid', 'unpaid'],
                  itemsPerPage: 10,
                  page: this.itemsPage
              }
          })
              .then((response) => {
                  response.data.data.forEach(i => this.invoices.data.push(i));
              })
      },
      loadBills () {
          this.itemsPage++;
          axiosInstance.get(config.apiUrl + '/api/bill', {
              params: {
                  carrier_id: this.params.counterpartyId,
                  states: ['partially_paid', 'unpaid'],
                  itemsPerPage: 10,
                  page: this.itemsPage
              }
          })
              .then((response) => {
                  response.data.data.forEach(i => this.bills.data.push(i));
              })
      },
      filesSelected (files) {
          this.files = files;
      },
    openDialog() {
      this.dialog = true;
    },
    onLocationSelect(location) {
      this.mailingAddress = location
      this.item.mailing_address = [location.address1, location.address2, location.city, location.state, location.country_code].filter(i => i).join(', ')
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
    getAttach(files) {
      this.item.media = files
    },
    savePayment () {
      const formData = new FormData();
      for (const name in this.payment) {
          if (name === 'entity_ids') {
              for (const eid of this.payment[name]) {
                  formData.append('entity_ids[]', eid);
              }
          } else {
              formData.append(name, this.payment[name]);
          }
      }
      this.files.forEach((file) => {
          formData.append('files[]', file);
      })
      axiosInstance.post(config.apiUrl + '/api/payments', formData, {
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        EventBus.$emit('paymentCreated', response.data);
        this.$emit('closed');
      })
      .catch((error) => {
        this.serverError = error.response.data.message
        this.errorSnackbar = true
      })
    },
    downloadFile (file) {

    }
  },
  components: { CounterpartySelect, LoadLocationSelect, QuickInvoices, QuickBills, Files },
  watch: {
      selectedIds (values) {
          this.payment.entity_ids = values;
      },
      selectedInvoices (invoices) {
          this.payment.currency = invoices[0]?.currency;
      },
      paymentAmountBasedOnItems (value) {
          this.payment.amount = value
      }
  }
}
</script>

<style scoped>

.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

.row-item:hover {
    background-color: #dedede;
    align-items: center;
}

</style>
