export default {
    data() {
        return {
            draggedItemIndex: null,
            targetItemIndex: null
        }
    },
    methods: {
        onDragStart(index) {
            this.draggedItemIndex = index
        },
        onDragOver(index) {
            this.targetItemIndex = index
        },
        onDragLeave() {
            this.targetItemIndex = null
        },
        onDrop(index, itemsToSwap) {
            if (this.draggedItemIndex !== null && this.draggedItemIndex !== index) {
                const draggedItem = itemsToSwap.splice(this.draggedItemIndex, 1, itemsToSwap[index])[0]
                itemsToSwap.splice(index, 1, draggedItem)
                this.draggedItemIndex = null
                this.targetItemIndex = null
            }
        }
    }
}