<template>
  <div class="chat container border-left" style="height: 100%">
    <div class="row h-100">

      <div class="col-12" style="overflow-y: scroll; height: 700px">

        <div
            v-for="(message, index) in chatMessages"
            :key="index"
            class="d-flex mb-4"
            :class="isMyMessage(message.participant_id) ? 'justify-content-end': 'justify-content-start'"
        >
          <div class="chat-message w-75">
            <div
                class="chat-message-card p-3"
                style="font-size: 0.9rem"
                :style="getMessageStyle(message.participant_id)"
            >
              {{ message.message }}
            </div>
            <div
                class="message-time text-muted"
                style="font-size: 0.85rem;"
                :class="isMyMessage(message.participant_id) ? 'text-end': 'text-start'"
            >
              {{ dayjs(message.created_at).format('hh:mm A') }}
            </div>
          </div>

        </div>

        <div ref="divAfterLastMessage"></div>
      </div>

      <div class="col-12 d-flex align-items-center justify-content-between border-top">
        <v-textarea
            auto-grow
            row-height="15"
            rows="2"
            class="chat-messages-input"
            v-model="newMessage"
            placeholder="Type your message..."
            @keyup.enter="sendMessage"
            outlined
            hide-details
        >
        </v-textarea>
        <div class="d-flex align-items-end h-100">
          <v-btn small color="primary" class="ms-1" @click="sendMessage" elevation="0" style="height: 56px;">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import generator from "@/helpers/generator";

export default {
  name: "CarrierChat",
  computed: {
    dayjs() {
      return dayjs
    }
  },
  props: {
    dispatcherId: {
      type: Number,
      required: true
    },
    displayNameDispatcher: {
      type: String,
      required: true
    },
    carrierId: {
      type: Number,
      required: true
    },
    displayNameCarrier: {
      type: String,
      required: true
    },
    fcmTokenCarrier: {
      type: String,
      required: true
    },
    loadId: {
      type: Number,
      required: true
    },
    load_carrier_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      participantId: null,
      clientOffsetPrefix: '',
      clientOffsetSuffix: 0,
      chatMessages: [],
      currentMessages: [],
      newMessage: "",
      socket: null
    }
  },
  beforeDestroy() {
    this.$socketChat.off();
  },
  mounted() {

    this.clientOffsetPrefix = this.$socketChat.id

    this.startChat()

    this.$socketChat.on(
        "chat:message",
        ({room, participants, participant, message, serverOffset}) => {
          this.$socketChat.auth.serverOffset = serverOffset

          const parts = room.external_id.split(':');
          const load_id = parts[0]

          if (load_id == this.loadId) {
            if (this.carrierId == participant.user_id
                || this.participantId == participant.id) {
              this.chatMessages.push(message)
              this.updateReadStatus([message])
            }
          }
        },
    )

    this.$socketChat.on(
        "chat:recovered",
        ({room, participants, participant, messages, serverOffset}) => {
          this.$socketChat.auth.serverOffset = serverOffset

          this.chatMessages.concat(messages)

        },
    )

  },
  methods: {
    getMessageStyle(participantId) {
      if (this.isMyMessage(participantId)) {
        return {backgroundColor: '#1565c0', color: '#ffffff', borderRadius: '6px 0 6px 6px'}
      } else {
        return {backgroundColor: '#f5f5f5', color: '#000000', borderRadius: '0 6px 6px 6px'}
      }
    },
    isMyMessage(participantId) {
      return participantId === this.participantId
    },
    scrollToChatBottom() {
      const el = this.$refs.divAfterLastMessage
      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },
    startChat() {
      this.$socketChat.emit(
          "chat:start",
          {
            participants: [
              {
                userId: this.dispatcherId,
                fcmToken: null,
                displayName: this.displayNameDispatcher,
              },
              {
                userId: this.carrierId,
                fcmToken: this.fcmTokenCarrier,
                displayName: this.displayNameCarrier,
              },
            ],
            externalId: String(this.loadId + ":" + this.carrierId), // Load ID for TSX
          },
          (error, {room, participants, participant, messages, serverOffset}) => {
            if (error) {
              console.error(error)
            } else {

              this.$socketChat.auth.serverOffset = serverOffset

              this.participantId = participant.id

              this.chatMessages = messages

              this.updateReadStatus(this.chatMessages)

            }
          },
      )
    },
    sendMessage() {
      if (this.newMessage.trim().length === 0) {
        return
      }
      this.$socketChat.emit(
          "chat:message",
          {
            participantId: this.participantId,
            message: this.newMessage.trim(),
            clientOffset: `${this.clientOffsetPrefix}:${generator.uuid()}`,
          },
          (error, response) => {
            if (error) {
              console.error(error)
            }
          },
      )
      this.newMessage = ''
    },
    updateReadStatus(messagesArray) {
      const unreadMessages = messagesArray.filter(
          message => !message.reads?.some(read => read.participant_id === this.participantId)
      )

      if (unreadMessages.length === 0) {
        return
      }

      const messagesToUpdate = unreadMessages.map(message => ({
        id: message.id,
        isRead: true
      }))

      this.$socketChat.emit(
          "chat:message:status",
          {
            participantId: this.participantId,
            messages: messagesToUpdate,
          },
          (error, response) => {
            if (error) {
              console.error(error)
            }
          },
      )

    },
  },
  watch: {
    chatMessages() {
      setTimeout(() => {
        this.scrollToChatBottom()
      }, "100")
    }
  }
}
</script>