<template>
  <v-card class="mb-3" outlined>
    <v-card-text>
      <v-row v-if="needUpdate">
        <v-col md="auto">
          <v-chip class="pa-5" label>
            <strong>{{ idx+ 1 }}</strong>
          </v-chip>
        </v-col>
        <v-col md="4">
          <CounterpartySelect
              class="mb-3"
              v-model="item.carrier"
              :roles="['carrier']"
              qb-type="carrier"
              item-text="name"
              :quickbooks="true"
              :account-company-id="invoice.billing.vendor.id"
              :currency="item.currency"
          />
          <LoadLocationSelect v-model="item.mailing_address" @change="onLocationSelect" :label="'Mailing address'"
                              :counterpartyId="item.carrier ? item.carrier.id : null" />
        </v-col>
        <v-col md="3">
          <v-select label="Terms" :items="paymentTerms" :value="item.payment_term_id" v-model="item.payment_term_id"  hide-details="auto"
                    item-text="name" item-value="id" outlined class="mb-3 mini" dense />
          <v-menu v-model="showDue" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="item.due_date" hide-details="auto" label="Due date" readonly outlined
                            dense clearable class="my-3 mini" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="item.due_date" @input="showDue = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-select class="mb-3" dense outlined @change="onCurrencyChange" :items="currency" v-model="item.currency"
                     label="Currency" hide-details="auto" />
          <v-text-field v-model="item.amount" dense outlined label="Amount" />
        </v-col>
        <v-col md="2">
          <v-select v-model="item.type" :items="['carrier', 'cross_dock', 'other_expenses']" dense outlined label="Type" hide-details="auto">
            <template v-slot:selection="{ item }">
              {{ item.toUpperCase().replace('_', ' ') }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.toUpperCase().replace('_', ' ') }}
            </template>
          </v-select>
          <v-btn v-if="!item.id" @click="$emit('remove', idx)" color="error" class="mt-3">REMOVE</v-btn>
        </v-col>
        <v-col md="12">
          <v-textarea v-model="item.memo" rows="3" dense outlined label="Description"></v-textarea>
        </v-col>
        <v-col>
          <v-btn v-if="item.id === null" text color="secondary">+ Create Bill</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CounterpartySelect from "@/components/counterparty/CounterpartySelect.vue";
import LoadLocationSelect from "@/components/loads/LoadLocationSelect.vue";
import axios from "@/plugins/axios";
import config from "@/config";

export default {
  name: "Bill",
  components: {LoadLocationSelect, CounterpartySelect},
  props: {
    item: {
      type: Object,
      required: true
    },
    invoice: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    paymentTerms: {
      type: Array,
      required: true
    },
    currency: {},
    load: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mailingAddress: null,
      needUpdate: true,
      showDue: false
    }
  },
  mounted() {
    if (this.item.carrier && this.item.carrier.id) {
      this.refreshBill()
    }
  },
  methods: {
    refreshBill () {
      axios.get(config.apiUrl + '/api/counterparty/' + this.item.carrier.id).then((response) => {
        this.item.carrier = response.data
        this.item.accounting_company_id = this.invoice.accounting_company_id
        this.item.payment_term_id = response.data?.carrier_data?.payment_terms_id ?? null
        if (typeof this.load.load_carriers[this.idx] !== "undefined") {
          const cd = this.load.load_carriers[this.idx]
          this.item.amount = cd.total_rate
          this.item.currency = cd.currency
        }
        this.needUpdate = false
        this.$nextTick(() => {
          this.needUpdate = true
          this.$emit('QBchanged')
        })
      })
    },
    onLocationSelect (location) {
      this.mailingAddress = location
      this.item.mailing_address = [location.address1, location.address2, location.city, location.state, location.country_code].filter(i => i).join(', ')
    },
    onCurrencyChange () {
      this.needUpdate = false
      this.$nextTick(() => {
        this.needUpdate = true
        this.$emit('QBchanged')
      })
    }
  }
}
</script>

<style scoped>

</style>
