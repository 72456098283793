<script>
import axios from "@/plugins/axios";
import config from "@/config";
import vueDropzone from "vue2-dropzone";

export default {
  name: "FilesList",
  components: {vueDropzone},
  props: {
    additionalData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    additionalParams: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    colSize: {
      type: Number,
      required: false
    },
    roles: {
      type: Array,
      default() {
        return []
      }
    },
    entityType: {
      type: String,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    }
  },
  created() {
    this.setFields()
    this.getFiles().then(() => {
      this.fillFields()
    })
  },
  data() {
    return {
      multiLine: true,
      snackbar: false,
      dropZoneRef: null,
      files: null,
      fields: {
        data: [],
        options: {
          col: 3
        }
      },
      loading: false,
      allChecked: false,
      dropzoneOptions: {
        url: config.apiUrl + '/api/file/upload',
        thumbnailWidth: 150,
        maxFilesize: 99999999999,
        addRemoveLinks: true,
        paramName: 'files[]',
        uploadMultiple: false,
        clickable: true,
      },
    }
  },
  methods: {
    handleError(file, message, xhr) {
      alert(message.message)
    },
    async getFiles() {
      try {
        const response = await axios.get(
          config.apiUrl + '/api/file/' + this.entityType + '/' + this.entityId,
       { params: Object.keys(this.additionalParams).length > 0 ? this.additionalParams : {} }
        );
        if (typeof response.data === 'object') {
          this.files = Object.values(response.data);
        } else {
          this.files = response.data;
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    },
    setFields() {
      if (this.entityType === 'counterparty') {
        if (this.roles.includes('customer')) {
          this.fields = {
            data: [
              {
                type: 'agreement',
                name: 'Agreement',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'w89',
                name: 'W8-9',
                required: true,
                uploaded: false,
                files: []
              }
            ],
            options: {
              col: this.colSize ?? 6
            }
          }
        }
        if (this.roles.includes('load_carrier_single')) {
          this.fields = {
            data: [
              {
                type: 'insurance_certificate',
                name: 'Insurance certificate',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'bank_details',
                name: 'Bank details',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'w8_9',
                name: 'W8-9',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'carrier_rate_confirmation',
                name: 'RC',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'invoice',
                name: 'Invoice',
                required: true,
                uploaded: false,
                files: []
              },
            ],
            options: {
              col: this.colSize ?? 4
            }
          }
        }
        if (this.roles.includes('load_carrier_multiple')) {
          this.fields = {
            data: [
              {
                type: 'insurance_certificate',
                name: 'Insurance certificate',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'bank_details',
                name: 'Bank details',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'w8_9',
                name: 'W8-9',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'invoice',
                name: 'Invoice',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'carrier_rate_confirmation',
                name: 'RC',
                required: true,
                uploaded: false,
                files: []
              },
            ],
            options: {
              col: this.colSize ?? 4
            }
          }
        }
        if (this.roles.includes('carrier')) {
          this.fields = {
            data: [
              {
                type: 'insurance_certificate',
                name: 'Insurance certificate',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'bank_details',
                name: 'Bank details',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'mc_nir_cvor',
                name: 'MC/NIR/CVOR',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'w8_9',
                name: 'W8-9',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'agreement',
                name: 'Agreement',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'noa',
                name: 'NOA',
                required: false,
                uploaded: false,
                files: []
              },
              {
                type: 'invoice',
                name: 'Invoice',
                required: true,
                uploaded: false,
                files: []
              },
            ],
            options: {
              col: this.colSize ?? 4
            }
          }
        }
      } else if (this.entityType === 'bill') {
        this.fields = {
          data: [
            {
              type: 'pod',
              name: 'POD',
              required: true,
              uploaded: false,
              files: []
            },
            {
              type: 'carrier_rate_confirmation',
              name: 'RC',
              required: true,
              uploaded: false,
              files: []
            },
            {
              type: 'mc_nir_cvor',
              name: 'MC/DOT',
              required: true,
              uploaded: false,
              files: []
            },
            {
              type: 'carrier_invoice',
              name: 'Invoice',
              required: true,
              uploaded: false,
              files: []
            },
            {
              type: 'insurance_certificate',
              name: 'Insurance',
              required: true,
              uploaded: false,
              files: []
            }
          ],
          options: {
            col: this.colSize ?? 2
          }
        }
      } else if (this.entityType === 'load') {
        if (this.additionalData.hasOwnProperty("load_file_type")) {
          if (this.additionalData.load_file_type === 'single') {
            this.fields = {
              data: [
                {
                  type: 'pod',
                  name: 'POD',
                  required: true,
                  uploaded: false,
                  files: []
                },
                {
                  type: 'rate_confirmation',
                  name: 'RC',
                  required: true,
                  uploaded: false,
                  files: []
                },
                {
                  type: 'invoice',
                  name: 'Invoice',
                  required: true,
                  uploaded: false,
                  files: []
                }
              ],
              options: {
                col: this.colSize ?? 2
              }
            }
          } else if (this.additionalData.load_file_type === 'multiple') {
            this.fields = {
              data: [
                {
                  type: 'pod',
                  name: 'POD',
                  required: true,
                  uploaded: false,
                  files: []
                },
                {
                  type: 'rate_confirmation',
                  name: 'RC',
                  required: true,
                  uploaded: false,
                  files: []
                }
              ],
              options: {
                col: this.colSize ?? 2
              }
            }
          }
        } else {
          this.fields = {
            data: [
              {
                type: 'pod',
                name: 'POD',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'rate_confirmation',
                name: 'RC',
                required: true,
                uploaded: false,
                files: []
              },
              {
                type: 'invoice',
                name: 'Invoice',
                required: true,
                uploaded: false,
                files: []
              }
            ],
            options: {
              col: this.colSize ?? 2
            }
          }
        }
      }
    },
    fillFields() {
      this.fields.data.forEach((field) => {
        field.files = []
        field.uploaded = false
        this.files.forEach((file) => {
          if (field.type === file.custom_properties.file_type) {
            field.files.push(file)
            field.uploaded = true
          }
        })
      })
    },
    setStatus(idx) {
      this.fields.data[idx].uploaded = true;
    },
    remove(idx) {
      if (confirm("Do you really want to delete?")) {
        this.fields.data[idx].uploaded = false
      }
    },
    sendingEvent(file, xhr, formData, fileType) {
      formData.append('entity_id', this.entityId);
      formData.append('entity_type', this.entityType);
      formData.append('file_type', fileType);
      if (this.additionalParams.hasOwnProperty('load_id')) {
        formData.append('load_id', this.additionalParams.load_id)
      }
      if (this.additionalParams.hasOwnProperty('carrier_id')) {
        formData.append('carrier_id', this.additionalParams.carrier_id)
      }
    },
    uploadCompleted(event, fileType) {
      this.$emit('upload', event, fileType)
      this.loading = false
      this.getFiles().then(() => {
        this.fillFields()
      });
      this.destroyZone();
    },
    downloadFile(file) {
      window.open(config.apiUrl + '/api/media/' + file.uuid)
    },
    removeFile(file) {
      if (confirm('Do you want to remove file?')) {
        axios.delete(config.apiUrl + '/api/file/'
            + this.entityType + '/' + this.entityId
            + '/' + file.uuid, {
            data: this.additionalParams
        }).then((response) => {
              this.getFiles().then(() => {
                this.fillFields()
              })
            })
        this.initZone()
      }
    },
    showUserMessage(length, idx) {
      this.dropZoneRef = 'dropZone-' + idx;
      const dropzoneComponent = this.$refs[this.dropZoneRef][0];
      if (length > 0) {
        this.destroyZone()
        this.snackbar = true;
      } else if (length === 0) {
        this.initZone()
        dropzoneComponent.dropzone.element.click();
      }
    },
    onMount(ref_name, length) {
      this.dropZoneRef = ref_name
      if (length === 0) {
        this.initZone()
      } else {
        this.destroyZone()
      }
    },
    destroyZone() {
      const dropzoneComponent = this.$refs[this.dropZoneRef][0];
      dropzoneComponent.dropzone.clickable = false;
      dropzoneComponent.dropzone.disable();
    },
    initZone() {
      const dropzoneComponent = this.$refs[this.dropZoneRef][0];
      dropzoneComponent.dropzone.clickable = true;
      dropzoneComponent.dropzone.enable();
    }
  },
}
</script>

<template>
    <div class="drop-zone">
      <v-snackbar
          v-model="snackbar"
          :multi-line="multiLine"
          top
      >
        <span>Please remove existing file, after upload another one</span>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

        <v-row v-if="files !== null">
            <v-col
                @click.prevent="showUserMessage(item.files.length, (idx + entityId))"
                :md="fields.options.col" v-for="(item, idx) in fields.data"
                :key="'vdz-' + (idx + entityId)">
                <vue-dropzone
                        :id="'dropzone-' + (idx + entityId)"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        :ref="'dropZone-' + (idx + entityId)"
                        @vdropzone-mounted="onMount('dropZone-' + (idx + entityId))"
                        @vdropzone-file-added="setStatus(idx)"
                        @vdropzone-upload-progress="loading = true"
                        @vdropzone-complete="uploadCompleted($event, item.type, (idx + entityId))"
                        @vdropzone-sending="(file, xhr, formData) => sendingEvent(file, xhr, formData, item.type)"
                        @vdropzone-error="(file, message, xhr) => handleError(file, message, xhr)"
                >
                    <div
                        :class="item.required ? 'drop-item text-center mandatory' : 'drop-item text-center'" :style="item.uploaded ? 'border-color: green; border-style: solid;' : ''">
                        <h5>{{ item.name }}</h5>
                        <v-icon color="green lighten-1" v-if="item.uploaded">mdi-check-circle</v-icon>
                        <v-icon color="grey lighten-1" v-else>mdi-cloud-upload</v-icon>
                        <template v-if="item.uploaded && typeof item.files[0] !== 'undefined'">
                            <v-btn text x-small class="mt-1" @click.stop="downloadFile(item.files[0])" >Download file</v-btn>
                            <v-btn text x-small class="mt-1" color="red" @click.stop="removeFile(item.files[0])">
                                <v-icon x-small>mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </div>
                </vue-dropzone>
            </v-col>
        </v-row>
        <v-skeleton-loader
            v-else
            class="mx-auto"
            max-width="100%"
            type="card"
        ></v-skeleton-loader>
    </div>
</template>

<style scoped>

</style>
