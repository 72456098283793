<script>
import Paginator from "@/components/core/Paginator.vue";

export default {
  name: "RouteHistory",
  components: {Paginator},
  props: {
    history: {
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {
      textCopied: false,
      coordinates: '',
      historyList: [],
      currentPage: 1,
      itemsPerPage: 10,
      list: []
    }
  },
  watch: {
    history(newVal) {
      this.list = newVal.markers
    }
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.list.slice(start, end);
    },
  },
  methods: {
    copyCoordinates(lat, lng) {
      navigator.clipboard.writeText(`${lat}, ${lng}`)
          .then(() => {
            this.coordinates = `${lat}, ${lng}`
            this.textCopied = true
      }).catch(err => {console.error('Failed to copy text: ', err);});
    },
    getShortCord(cord) {
      let cut = 6;
      const response = JSON.stringify(cord)
      switch (response.length) {
        case 10: { cut = 7; break }
        case 11: { cut = 8; break }
      }
      return response.substring(0, cut)
    },
    getDirection(degree) {
      if ((degree >= 337.5 && degree <= 360) || (degree >= 0 && degree < 22.5)) {
        return 'mdi-arrow-up-thin-circle-outline' //'North';
      } else if (degree >= 22.5 && degree < 67.5) {
        return 'mdi-arrow-top-right-thin-circle-outline' //'North-East';
      } else if (degree >= 67.5 && degree < 112.5) {
        return 'mdi-arrow-right-thin-circle-outline' //'East';
      } else if (degree >= 112.5 && degree < 157.5) {
        return 'mdi-arrow-bottom-right-thin-circle-outline' //'South-East';
      } else if (degree >= 157.5 && degree < 202.5) {
        return 'mdi-arrow-down-thin-circle-outline' //'South';
      } else if (degree >= 202.5 && degree < 247.5) {
        return 'mdi-arrow-bottom-left-thin-circle-outline' //'South-West';
      } else if (degree >= 247.5 && degree < 292.5) {
        return 'mdi-arrow-left-thin-circle-outline' //'West';
      } else if (degree >= 292.5 && degree < 337.5) {
        return  'mdi-arrow-top-left-thin-circle-outline' //'North-West';
      } else {
        return 'mdi-minus';
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
  },
}
</script>

<template>
  <div class="route_history">
    <v-snackbar
        v-model="textCopied"
        :timeout="2000"
        top>
      Coordinates {{ coordinates }} copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="textCopied = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="history_header">
      <v-icon small class="float-left mt-1">
        mdi-map-marker-path
      </v-icon>
      <p class="text-center font-weight-bold mb-0">
        Route History:</p>
    </div>
    <!--v-if="list.length > 0" -->
    <div class="history_body">
      <div class="paginator_wrapper mt-2 mb-5" style="text-align:center;">
        <Paginator :total-items="list.length"
                   :items-per-page="10"
                   @page-changed="handlePageChange" />
      </div>
      <ul>
        <li v-for="(item, index) in paginatedItems" :key="index">
          <span @click="copyCoordinates(item.position.lat, item.position.lng)">
          <v-icon
              class="location_icon mr-1"
              title="Copy cords" style="cursor: pointer">
            mdi-map-marker-check-outline
          </v-icon>
          </span>

          <div class="history_item">
            <span class="cords mr-1">
              {{ getShortCord(item.position.lat) }} {{ getShortCord(item.position.lng) }}
            </span>
            <span class="direction">
              <v-icon small>
                {{ getDirection(item.extra.heading) ?? '-' }}
              </v-icon>
            </span>
            <span class="speed ml-1">{{ item.extra.speed.toFixed(2) ?? '-' }} MPH</span>
            <p>{{ item.timestamp ?? '-' }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>


<style lang="scss">
.route_history {
  .history_body {
    ul {
      list-style: none;
      font-size: 13px;
      line-height: 16px;
      padding-left: 0;
      text-align: center;

      li {
        display: flex;
        justify-content: center;

        .location_icon {
          color: #187526;
          font-size: 21px;
          display: inline;
          vertical-align: top;
        }
        .history_item {
          p {
            font-size: 12px;
            text-align: center;
            color: gray;
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}

</style>