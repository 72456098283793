<template>
  <div>
    <v-data-table :headers="headers" :items="$store.state.quickBills.list.data" :items-per-page="5" class="elevation-1">
      
      <template v-slot:item.desc="{ item }">
        Bill #{{ item.id }}
      </template>
      <template v-slot:item.load="{ item }">
        Load #{{ item.load_id }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.bill_date) }}
      </template>
      <template v-slot:item.due="{ item }">
        {{ formatDate(item.due_date) }}
      </template>
      <template v-slot:item.amount="{ item }">
        $ {{ item.amount }} {{ item.currency === 'CAD' ? 'CAD' : '' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'QuickBills',
  data() {
    return {
      headers: [
        { text: 'Description', value: 'desc' },
        { text: 'Load #', value: 'load' },
        { text: 'Date', value: 'date' },
        { text: 'Due date', value: 'due' },
        { text: 'Amount', value: 'amount' },
      ],
      items: []
    }
  },
  created() {
    this.$store.dispatch('quickBills/getList')
  },
  methods: {
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
  }
}
</script>