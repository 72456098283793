<template>
  <vuetify-google-autocomplete :country="countries" ref="locationAutocomplete" :id="id" :placeholder="undefined" @placechanged="onPlaceChanged"
    :value="entity['location']" :label="label" :persistent-placeholder="false" :persistent-hint="false" outlined dense
    :hide-details="hideInputErrors" class="mini location-autocomplete" :rules="validationRules">
  </vuetify-google-autocomplete>
</template>

<script>
export default {
  name: "LocationAutocomplete",
  props: {
    countries: {
      type: Array,
      default() {
        return []
      }
    },
    id: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: 'Location',
    },
    validationRules: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    hideInputErrors: {
      type: Boolean,
      default: true
    },
    mustHavePostCode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onPlaceChanged(addressData, addressComponents) {

      // Clear the input if address does not have a postal code
      if (this.mustHavePostCode) {
        const postalCode = Object.keys(addressData).includes('postal_code')
        if (!postalCode) {
          this.$refs.locationAutocomplete.clear();
          return null
        }
      }

      this.entity.location = addressComponents['formatted_address']
      this.entity.location_coordinates = {
        type: 'Point',
        coordinates: [addressData.longitude, addressData.latitude]
      }
      this.entity.location_data = addressData
      this.$emit('selected', addressData, addressComponents)
    }
  }
}
</script>
