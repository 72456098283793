<template>
  <v-dialog :value="isNotesOpened" max-width="600px" overlay-opacity="0.5" :transition="false"
            @click:outside="$emit('close', notes)">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title>Notes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close', notes)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-5">
        <Note v-model="notes" value-key="driverPayNotes" value-type="text" label="Driver Pay Notes"></Note>
        <Note v-model="notes" value-key="invoiceNotes" value-type="text" label="Invoice Notes"></Note>
        <Note v-model="notes" value-key="invoiceDescription" value-type="text" label="Invoice Description">
        </Note>
        <Note v-model="notes" value-key="deleteNotes" value-type="text" label="Deleted/Refusal Notes"></Note>
      </v-card-text>
      <v-footer class="d-flex justify-content-end">
        <v-btn
            v-if="canSaveNote"
            @click="updateNotes"
            color="success"
        >
          <v-icon small class="mr-3">
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
      </v-footer>
      <v-snackbar v-if="canSaveNote" v-model="savedSnackbar" :timeout="2000">
        Load saved!
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="savedSnackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

    </v-card>
  </v-dialog>
</template>
<script>
import Note from "@/components/notes/Note.vue"
import axios from "@/plugins/axios";
import config from "@/config";

export default {
  name: 'LoadNotes',
  components: {Note},
  props: {
    loadId: {
      type: Number
    },
    isNotesOpened: {
      type: Boolean
    },
    canSaveNote: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      savedSnackbar: false,
      notes: [],
    }
  },
  created() {
    axios.get(config.apiUrl + '/api/load/' + this.loadId).then((response) => {
      if (response.data.notes.length === 0) {
        return
      }
      this.notes = response.data.notes
    })
  },
  methods: {
    updateNotes() {
      axios.patch(config.apiUrl + '/api/load/' + this.loadId + '/attribute', {
        attribute: 'notes',
        value: this.notes
      }).then((response) => {
        this.notes = response.data.notes
        this.savedSnackbar = true
      }).catch(() => {
        alert('Update notes error')
      })
      this.$emit('notesUpdated');
    }
  }
}
</script>