<template>
  <div>
    <strong>{{ name }}</strong>
    <v-row class="align-items-end">
      <v-col md="3" v-if="data.last">
        Last Year <br> <span :class="data.last < 0 ? 'text-danger' : 'text-success'">{{ data.last }} %</span>
      </v-col>
      <v-col :md="data.last ? 6 : 12">
        <apexchart type="radialBar" :height="height" :options="chartOptions" :series="data.series"></apexchart>
      </v-col>
      <v-col md="3" v-if="data.last">
        Target <span :class="data.target < 0 ? 'text-danger' : 'text-success'">{{ data.target }} %</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: 'Title'
    },
    data: {
      type: Object,
      required: true
    },
    count: {
      type: Boolean,
      required: true
    },
    setColors: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      default: 350
    },
    showPercent: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      percent: this.showPercent ?? false,
      chartOptions: {
        chart: {
          type: 'radialBar',
          offsetY: 0,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '100%',
              margin: 1,
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
                
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          colors: this.setColors
        },
      },
    }
  }
}
</script>