import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v("My profile")]),_c(VCardText,[_c('form',[_c(VTextField,{attrs:{"label":"Name"},model:{value:(_vm.user.counterparties[0].name),callback:function ($$v) {_vm.$set(_vm.user.counterparties[0], "name", $$v)},expression:"user.counterparties[0].name"}}),_c(VTextField,{attrs:{"label":"E-mail"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.saveProfile()}}},[_vm._v(" Save ")]),_c(VBtn,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])],1)])],1),_c(VSnackbar,{attrs:{"timeout":2000,"color":"success"},model:{value:(_vm.savedProfile),callback:function ($$v) {_vm.savedProfile=$$v},expression:"savedProfile"}},[_c(VIcon,[_vm._v("mdi-check")]),_vm._v(" Profile saved! ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }