<template>
  <div>
    <div v-if="loaded">
      <div>
        <v-row align="stretch" dense justify="center">
          <v-col md="2">
            <v-card outlined>
              <v-card-text>
                <GridCard :count="true" :name="'Sales'" :data="data.sales" />
                <GridCard class="mt-5" :count="true" :name="'Gross Profit'" :data="data.gross" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3">

            <ColumnChart :set-datalabels="true" :set-legend="true" :height="297" :count="true" :data="data.teams_volume"
                         :name="'Sales (amount) by Teams'" />
          </v-col>
          <v-col md="7">
            <ColumnChart :set-datalabels="true" :set-legend="true" :height="297" :count="true" :data="data.actual_target"
                         :name="'Actual Sales vs Target'" />
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row align="stretch" dense justify="center">
          <v-col md="2">
            <v-card outlined>
              <v-card-text>
                <GridCard :count="true" :name="'Operating Profit'" :data="data.operating" />
                <GridCard class="mt-5" :count="true" :name="'Net Profit'" :data="data.net" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4">

            <ColumnChart :set-legend="false" :height="297" :count="true" :name="'Income Statement'"
                         :data="data.income_statement" />
          </v-col>
          <v-col v-if="false" md="3">
            <BarNegative :count="false" :name="'Net Operating Cash Flow'" :height="287" :data="data.net_operating" />
          </v-col>
          <v-col md="3">
            <BarNegative :count="false" :name="'Monthly Sales Growth'" :height="287" :data="data.sales_growth" />
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row >
          <v-col md="6" class="px-1">
            <BarBasic :count="false" :name="'Employee Cost vs Sales'" :height="550" :data="data.cost_sales" :set-colors="['#3CB371', '#000']"  />
          </v-col>
          <v-col>
            <v-row dense>
              <v-col md="6">
                <v-card outlined>
                  <v-card-text>
                    <SimpleCounter :name="'Days Receivable Outstanding'" :count="data.days_receivable_outstanding"
                                   :subject-count="invoicesCount" :type="'invoices'" />
                    <hr>
                    <strong>Receivable Aging</strong>
                    <v-simple-table>
                      <tr>
                        <td>Total Receivable</td>
                        <td>{{ data.receivable.total }}</td>
                      </tr>
                      <tr>
                        <td>Overdue Receivable</td>
                        <td>{{ data.receivable.overdue }}</td>
                      </tr>
                      <tr>
                        <td>% of Overdue Receivable</td>
                        <td :class="data.receivable.percent > 0 ? 'text-danger' : 'text-success'">{{
                            data.receivable.percent.toFixed(2) }}%</td>
                      </tr>
                    </v-simple-table>
                    <hr>
                    <ColumnChart :set-datalabels="true" :set-legend="true" :height="295" :count="true"
                                 :data="data.receivable_chart"
                                 :set-colors="['rgb(60,179,113)', 'rgba(60,179,113,0.7)', 'rgba(220,20,60,0.5)', 'rgba(220,20,60,1)']" />

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <v-card outlined>
                  <v-card-text>
                    <SimpleCounter :name="'Days Payable Outstanding'" :count="data.days_payable_outstanding"
                                   :subject-count="billsCount" :type="'bills'" />
                    <hr>
                    <strong>Payable Aging</strong>
                    <v-simple-table>
                      <tr>
                        <td>Total Payable</td>
                        <td>{{ data.payable.total }}</td>
                      </tr>
                      <tr>
                        <td>Overdue Receivable</td>
                        <td>{{ data.payable.overdue }}</td>
                      </tr>
                      <tr>
                        <td>% of Overdue Receivable</td>
                        <td :class="data.payable.percent > 0 ? 'text-danger' : 'text-success'">{{
                            data.payable.percent.toFixed(2) }}%</td>
                      </tr>
                    </v-simple-table>
                    <hr>
                    <ColumnChart :set-datalabels="true" :set-legend="true" :height="295" :count="true"
                                 :data="data.payable_chart"
                                 :set-colors="['rgb(60,179,113)', 'rgba(60,179,113,0.7)', 'rgba(220,20,60,0.5)', 'rgba(220,20,60,1)']" />

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import GridCard from './widgets/GridCard.vue';
import ColumnChart from './widgets/ColumnChart.vue';
import BarNegative from './widgets/BarNegative.vue'
import BarBasic from './widgets/BarBasic.vue';
import SimpleCounter from './widgets/SimpleCounter.vue';
import axios from "@/plugins/axios";
import config from "@/config";
export default {
  name: "DashboardCEO",
  components: {
    apexchart: VueApexCharts,
    GridCard,
    ColumnChart,
    BarNegative,
    BarBasic,
    SimpleCounter
},
  props: {
    data: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      invoicesCount: 0,
      billsCount: 0
    }
  },
  mounted() {
    this.data.sales.count = 0
    this.data.sales.last = 0
    this.data.sales.target = 0

    this.data.gross.count = 0
    this.data.gross.last = 0
    this.data.gross.target = 0

    this.data.net.count = 0
    this.data.net.last = 0
    this.data.net.target = 0

    this.data.operating.count = 0
    this.data.operating.last = 0
    this.data.operating.target = 0

    this.data.teams_volume.series[0]['data'] = []

    this.loaded = false;
    this.fetchApiData();
  },
  methods: {
    fetchApiData() {
      this.$emit('loading', true)
      axios.get(config.apiUrl + '/api/reports/ceo-totals', {
        params: this.filters
      }).then((response) => {
        this.data.sales.count = Math.round(response.data.sales['curr'])
        this.data.gross.count = Math.round(response.data.grossProfit['curr'])
        this.data.net.count = Math.round(response.data.grossProfit['curr'])
        this.data.operating.count = Math.round(response.data.grossProfit['curr'])

        this.data.gross = {
          count: response.data.grossProfit['curr'].toFixed(),
          last: response.data.grossProfit['prev'] ?? '-',
          target: 0,
        }
        this.data.operating = {
          count: response.data.operatingProfit['curr'].toFixed(),
          last: response.data.operatingProfit['prev'] ?? '-',
          target: 0,
        }
        this.data.net = {
          count: response.data.netProfit['curr'].toFixed(),
          last: response.data.netProfit['prev'] ?? '-',
          target: 0,
        }
        this.data.gross_profit_margin = {
          series: [((response.data.grossProfit['curr'] / response.data.revenue['curr']) * 100).toFixed(2)],
          target: 0,
          last: (((response.data.grossProfit['prev'] ?? 0) / (response.data.revenue['prev'] ?? 1)) * 100).toFixed(2)
        }
        this.data.operating_profit_margin = {
          series: [((response.data.operatingProfit['curr'] / response.data.revenue['curr']) * 100).toFixed(2)],
          target: 0,
          last: (((response.data.operatingProfit['prev'] ?? 0) / (response.data.revenue['prev'] ?? 1)) * 100).toFixed(2)
        }
        this.data.net_profit_margin = {
          series: [((response.data.netProfit['curr'] / response.data.revenue['curr']) * 100).toFixed(2)],
          target: 0,
          last: (((response.data.netProfit['prev'] ?? 0) / (response.data.revenue['prev'] ?? 1)) * 100).toFixed(2)
        }

        this.data.income_statement.series[0].data[0] = response.data.revenue['curr']
        this.data.income_statement.series[0].data[1] = response.data.costs['curr']
        this.data.income_statement.series[0].data[2] = response.data.otherOperatingExpenses['curr']
        this.data.income_statement.series[0].data[3] = response.data.nonOperatingIncome['curr']
        this.data.income_statement.series[0].data[4] = response.data.financialExpenses['curr']
        this.data.income_statement.series[0].data[5] = response.data.taxes['curr']

        this.data.receivable_chart.series[0].data = response.data.daysReceivable.map(item => {
          return {
            x: item['DIFF'],
            y: item['COUNT'],
          }
        })

        // add invoices count
        this.invoicesCount = 0
        response.data.daysReceivable.forEach((item) => {
          this.invoicesCount += item['COUNT']
        })

        this.data.payable_chart.series[0].data = response.data.daysPayable.map(item => {
          return {
            x: item['DIFF'],
            y: item['COUNT'],
          }
        })

        // add bills count
        this.billsCount = 0
        response.data.daysPayable.forEach((item) => {
          this.billsCount += item['COUNT']
        })

        this.data.days_receivable_outstanding = parseFloat(response.data?.daysReceivableAvg.toFixed(2))
        this.data.days_payable_outstanding = parseFloat(response.data?.daysPayableAvg.toFixed(2))
        this.data.receivable = {
          total: response.data.totalReceivable,
          overdue: response.data.totalOverdueReceivable,
          percent: (response.data.totalReceivable / response.data.totalOverdueReceivable) * 100
        }
        this.data.payable = {
          total: response.data.totalPayable,
          overdue: response.data.totalOverduePayable,
          percent: ( response.data.totalOverduePayable / response.data.totalPayable) * 100
        }

        this.data.teams_volume.series[0]['data'] = response.data.salesByTeam.filter(i => i['TEAM'] !== null).map(i => {
          return {
            x: i['TEAM'],
            y: i['SALES'],
            goals: [
              {
                name: 'Target',
                value: 400000,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })

        this.data.actual_target.series[0]['data'] = response.data.salesByMonth.map(i => {
          return {
            x: i['MONTH'],
            y: i['SALES'],
            goals: [
              {
                name: 'Target',
                value: 400000,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })

        this.data.cost_sales.series[0] = {
          data: response.data.salaryVsSales.map(i => {
            return {
              x: i['MONTH'],
              y: i['SALES'],
            }
          }),
          name: 'Sales'
        }

        this.data.cost_sales.series[1] = {
          data: response.data.salaryVsSales.map(i => {
            return {
              x: i['MONTH'],
              y: i['SALARY'],
            }
          }),
          name: 'Salary'
        }

        this.data.sales_growth.series = response.data.monthlySalesGrowth

        setTimeout(() => {
          this.loaded = true
          this.$emit('loading', false)
        }, 500)
        this.$forceUpdate();
      })
    }
  }
}
</script>
