<template>
  <v-select
      label="Taxes"
      :value="value"
      @change="$emit('input', $event)"
      :items="['Zero rate', 'Before taxes', 'After taxes']"
      v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "TaxIncludedSelect",
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
