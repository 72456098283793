<template>
    <component :is="component" :class="['load_layover', {
         'load_layover--bg_blurred': bluringCondition,
         'load_layover--bg': blockingCondition
    }]">
      <slot name="default" />
    </component>
</template>
<script>
export default {
    props: {
        component: {
          type: String,
          default: 'span'
        },
        blockingCondition: {
            type: Boolean | Function,
            default: false
        },
        bluringCondition: {
            type: Boolean | Function,
            default: false
        }
    }
}
</script>
<style scoped>
.load_layover {
  /*position: absolute;*/
  font-size: 1.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.load_layover--bg {
  position: relative;
  z-index: 9999;
  pointer-events: none;
}

.load_layover--bg_blurred {
  opacity: .2;
}
</style>