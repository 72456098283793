<template>
  <div class="mt-2">
    <v-row align="center" dense>
      <v-col md="3">
        <blocking-layover :blocking-condition="restrictedEdit">
          <CounterpartySelect :clearable="!restrictedEdit" @input="onCounterpartyChanged" v-model="shipper.shipper" :roles="['shipper']" />
        </blocking-layover>
      </v-col>
      <v-col md="4">
        <LoadLocationSelect :counterpartyId="shipper.shipper ? shipper.shipper.id : null" v-model="shipper.location"
          @change="onLocationSelected" @input="onLocationInput"></LoadLocationSelect>
      </v-col>
      <v-col md="3">
        <v-menu v-model="showDatePickerMenu" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field hide-details="auto" v-model="shipper.date" label="Date" readonly outlined dense clearable
              class="mini" v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="shipper.date" @input="showDatePickerMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="2">
        <v-select v-model="shipper.time" :readonly="timeDisabled ? true : false" class="mini" label="Time"
          hide-details="auto" outlined dense :items="times" item-text="label" item-value="time"
          @click:append="onUseTimeChange" :append-icon="appendIcon" menu-props="auto"></v-select>
      </v-col>
      <v-col md="5">
        <v-text-field class="mini" hide-details="auto" v-model="shipper.description" label="Description" outlined dense
          clearable>
        </v-text-field>
      </v-col>
      <v-col md="2">
        <!--        v-model="type"-->
        <v-text-field class="mini" v-model="shipper.type" hide-details="auto" label="Type" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="1">
        <v-text-field class="mini" v-model.number="shipper.quantity" hide-details="auto" label="Qty" outlined dense
          clearable>
        </v-text-field>
      </v-col>
      <v-col md="2">

        <v-text-field class="mini" v-model.number="shipper.weight" hide-details="auto" label="Weight" suffix="lbs"
          outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field v-model.number="shipper.cost" class="mini" hide-details="auto" label="Value" suffix="$" outlined
          dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="5">
        <v-text-field class="mini" v-model="shipper.shipping_notes" hide-details="auto" label="Shipping notes" outlined
          dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field class="mini" v-model="shipper.po_numbers" hide-details="auto" label="P.O. Numbers" outlined dense
          clearable>
        </v-text-field>
      </v-col>
      <v-col md="4">
        <CounterpartySelect v-model="shipper.customs_broker" :roles="['customs_broker']" label="" />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import LocationAutocomplete from "@/components/location/LocationAutocomplete";
import BlockingLayover from '@/components/layout/BlockingLayover.vue';
import LoadLocationSelect from "@/components/loads/LoadLocationSelect";
import loadLayoverMixin from '@/mixins/loadLayoverMixin';
import time from "@/helpers/time";
import dayjs from "dayjs";
export default {
  mixins: [loadLayoverMixin],
  props: {
    shipper: {
      type: Object,
      required: true,
    }
  },
  components: {
    LoadLocationSelect,
    CounterpartySelect,
    LocationAutocomplete,
    BlockingLayover,
    datePicker,
  },
  mounted() {
      if (this.shipper.shipper && this.shipper.shipping_notes === null && this.shipper.shipper.internal_notes) {
        this.shipper.shipping_notes = this.shipper.shipper.internal_notes
      } 
    if (!this.shipper.date) {
      this.$nextTick(() => {
        this.shipper.date = dayjs().format('YYYY-MM-DD')
      })
    }
    if (!this.shipper.time) {
      this.shipper.time = null
    } else {
      this.timeDisabled = false
    }
    if (this.timeDisabled) {
      this.shipper.time = null
    }
  },
  data() {
    return {
      showDatePickerMenu: false,
      times: time,
      timeDisabled: true,
      billDialog: true,
    }
  },
  computed: {
    restrictedEdit() {
      if(this.load.status === 'completed' && this.$store.getters.role.includes('customer_support')) {
        return false
      }
      return this.restrictLoadEdit(this.load)
    },
    load() {
      return this.$store.state.loadInEdit;
    },
    appendIcon() {
      return this.timeDisabled ? 'mdi-alarm-off' : 'mdi-alarm'
    },
    duplicatedFields() {
      return {
        description: this.shipper.description,
        type: this.shipper.type,
        quantity: this.shipper.quantity,
        weight: this.shipper.weight,
        cost: this.shipper.cost
      }
    }
  },
  methods: {
    onCounterpartyChanged(value) {
      if (!value) {
        this.shipper.location = null
        this.shipper.location_coordinates = null
        this.shipper.location_data = null
      }

      if (this.shipper.shipper && this.shipper.shipper.internal_notes) {
        this.shipper.shipping_notes = this.shipper.shipper.internal_notes
      } else {
        this.shipper.shipping_notes = null
      }
    },
    onLocationSelected(address) {
      if (typeof address === 'string') {
        this.shipper.location = address
      } else if (address === null) {
        this.shipper.location = null
        this.shipper.location_coordinates = null
        this.shipper.location_data = null
      } else {
        this.shipper.location = [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
          return !!addressPart
        }).join(', ')
        this.shipper.location_parts = address
        if (address && address.location_data && address.location_coordinates) {
          this.shipper.location_coordinates = address.location_coordinates
          this.shipper.location_data = address.location_data
        }
      }
    },
    onLocationInput(address) {
      if (address === null) {
        this.shipper.location = null
        this.shipper.location_coordinates = null
        this.shipper.location_data = null
      } else {
        this.shipper.location = [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
          return !!addressPart
        }).join(', ')
      }
    },
    onUseTimeChange(value) {
      this.timeDisabled = !this.timeDisabled
      if (this.timeDisabled) {
        this.shipper.time = null
      } else {
        this.shipper.time = value ? '12:00:00' : null
      }
    }
  },
  watch: {
    duplicatedFields(values, oldValues) {
      this.$emit('duplicate-fields-changed', values, oldValues)
    }
  }
}

</script>
