<template>
  <v-select
      :value="value"
      :items="items"
      @input="$emit('input', $event)"
      class="mini"
      hide-details="auto"
      label="Status"
      outlined dense clearable
      :multiple="multiple"
  >
    <template v-slot:selection="{ item }">
      {{ item.toUpperCase().replace('_', ' ') }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.toUpperCase().replace('_', ' ') }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: "LoadStatusSelect",
  props: {
    value: {
      type: [String, Array]
    },
    multiple: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      items: ['open', 'covered', 'dispatched', 'loading', 'on_route', 'unloading', 'in_yard', 'delivered', 'completed', 'invoiced', 'refused', 'claim', 'drop_trailer']
    }
  }
}
</script>

<style scoped>

</style>
