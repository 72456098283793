export default [
    {
        time: '00:00:00',
        label: '12:00 AM'
    },
    {
        time: '00:15:00',
        label: '12:15 AM'
    },
    {
        time: '00:30:00',
        label: '12:30 AM'
    },
    {
        time: '00:45:00',
        label: '12:45 AM'
    },
    {
        time: '01:00:00',
        label: '01:00 AM'
    },
    {
        time: '01:15:00',
        label: '01:15 AM'
    },
    {
        time: '01:30:00',
        label: '01:30 AM'
    },
    {
        time: '01:45:00',
        label: '01:45 AM'
    },
    {
        time: '02:00:00',
        label: '02:00 AM'
    },
    {
        time: '02:15:00',
        label: '02:15 AM'
    },
    {
        time: '02:30:00',
        label: '02:30 AM'
    },
    {
        time: '02:45:00',
        label: '02:45 AM'
    },
    {
        time: '03:00:00',
        label: '03:00 AM'
    },
    {
        time: '03:15:00',
        label: '03:15 AM'
    },
    {
        time: '03:30:00',
        label: '03:30 AM'
    },
    {
        time: '03:45:00',
        label: '03:45 AM'
    },
    {
        time: '04:00:00',
        label: '04:00 AM'
    },
    {
        time: '04:15:00',
        label: '04:15 AM'
    },
    {
        time: '04:30:00',
        label: '04:30 AM'
    },
    {
        time: '04:45:00',
        label: '04:45 AM'
    },
    {
        time: '05:00:00',
        label: '05:00 AM'
    },
    {
        time: '05:15:00',
        label: '05:15 AM'
    },
    {
        time: '05:30:00',
        label: '05:30 AM'
    },
    {
        time: '05:45:00',
        label: '05:45 AM'
    },
    {
        time: '06:00:00',
        label: '06:00 AM'
    },
    {
        time: '06:15:00',
        label: '06:15 AM'
    },
    {
        time: '06:30:00',
        label: '06:30 AM'
    },
    {
        time: '06:45:00',
        label: '06:45 AM'
    },
    {
        time: '07:00:00',
        label: '07:00 AM'
    },
    {
        time: '07:15:00',
        label: '07:15 AM'
    },
    {
        time: '07:30:00',
        label: '07:30 AM'
    },
    {
        time: '07:45:00',
        label: '07:45 AM'
    },
    {
        time: '08:00:00',
        label: '08:00 AM'
    },
    {
        time: '08:15:00',
        label: '08:15 AM'
    },
    {
        time: '08:30:00',
        label: '08:30 AM'
    },
    {
        time: '08:45:00',
        label: '08:45 AM'
    },
    {
        time: '09:00:00',
        label: '09:00 AM'
    },
    {
        time: '09:15:00',
        label: '09:15 AM'
    },
    {
        time: '09:30:00',
        label: '09:30 AM'
    },
    {
        time: '09:45:00',
        label: '09:45 AM'
    },
    {
        time: '10:00:00',
        label: '10:00 AM'
    },
    {
        time: '10:15:00',
        label: '10:15 AM'
    },
    {
        time: '10:30:00',
        label: '10:30 AM'
    },
    {
        time: '10:45:00',
        label: '10:45 AM'
    },
    {
        time: '11:00:00',
        label: '11:00 AM'
    },
    {
        time: '11:15:00',
        label: '11:15 AM'
    },
    {
        time: '11:30:00',
        label: '11:30 AM'
    },
    {
        time: '11:45:00',
        label: '11:45 AM'
    },
    {
        time: '12:00:00',
        label: '12:00 PM'
    },
    {
        time: '12:15:00',
        label: '12:15 PM'
    },
    {
        time: '12:30:00',
        label: '12:30 PM'
    },
    {
        time: '12:45:00',
        label: '12:45 PM'
    },
    {
        time: '13:00:00',
        label: '01:00 PM'
    },
    {
        time: '13:15:00',
        label: '01:15 PM'
    },
    {
        time: '13:30:00',
        label: '01:30 PM'
    },
    {
        time: '13:45:00',
        label: '01:45 PM'
    },
    {
        time: '14:00:00',
        label: '02:00 PM'
    },
    {
        time: '14:15:00',
        label: '02:15 PM'
    },
    {
        time: '14:30:00',
        label: '02:30 PM'
    },
    {
        time: '14:45:00',
        label: '02:45 PM'
    },
    {
        time: '15:00:00',
        label: '03:00 PM'
    },
    {
        time: '15:15:00',
        label: '03:15 PM'
    },
    {
        time: '15:30:00',
        label: '03:30 PM'
    },
    {
        time: '15:45:00',
        label: '03:45 PM'
    },
    {
        time: '16:00:00',
        label: '04:00 PM'
    },
    {
        time: '16:15:00',
        label: '04:15 PM'
    },
    {
        time: '16:30:00',
        label: '04:30 PM'
    },
    {
        time: '16:45:00',
        label: '04:45 PM'
    },
    {
        time: '17:00:00',
        label: '05:00 PM'
    },
    {
        time: '17:15:00',
        label: '05:15 PM'
    },
    {
        time: '17:30:00',
        label: '05:30 PM'
    },
    {
        time: '17:45:00',
        label: '05:45 PM'
    },
    {
        time: '18:00:00',
        label: '06:00 PM'
    },
    {
        time: '18:15:00',
        label: '06:15 PM'
    },
    {
        time: '18:30:00',
        label: '06:30 PM'
    },
    {
        time: '18:45:00',
        label: '06:45 PM'
    },
    {
        time: '19:00:00',
        label: '07:00 PM'
    },
    {
        time: '19:15:00',
        label: '07:15 PM'
    },
    {
        time: '19:30:00',
        label: '07:30 PM'
    },
    {
        time: '19:45:00',
        label: '07:45 PM'
    },
    {
        time: '20:00:00',
        label: '08:00 PM'
    },
    {
        time: '20:15:00',
        label: '08:15 PM'
    },
    {
        time: '20:30:00',
        label: '08:30 PM'
    },
    {
        time: '20:45:00',
        label: '08:45 PM'
    },
    {
        time: '21:00:00',
        label: '09:00 PM'
    },
    {
        time: '21:15:00',
        label: '09:15 PM'
    },
    {
        time: '21:30:00',
        label: '09:30 PM'
    },
    {
        time: '21:45:00',
        label: '09:45 PM'
    },
    {
        time: '22:00:00',
        label: '10:00 PM'
    },
    {
        time: '22:15:00',
        label: '10:15 PM'
    },
    {
        time: '22:30:00',
        label: '10:30 PM'
    },
    {
        time: '22:45:00',
        label: '10:45 PM'
    },
    {
        time: '23:00:00',
        label: '11:00 PM'
    },
    {
        time: '23:15:00',
        label: '11:15 PM'
    },
    {
        time: '23:30:00',
        label: '11:30 PM'
    },
    {
        time: '23:45:00',
        label: '11:45 PM'
    },
]
