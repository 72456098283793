<template>
  <div>
    <v-data-table :options="defaultOptions" :headers="headers" :items="invoice.data" :loading="loading" item-key="id"
      @update:options="onOptionUpdate" :server-items-length="invoice.meta.total" :custom-sort="items => items"
      :footer-props="{
        'items-per-page-options': [30, 50, 100, 300]
      }" dense :sort-desc.sync="apiParams.sortDesc" class="table-hover table-bordered accounting-table custom-table">
      <template v-slot:item.load="{ item }">
        <div class="d-flex justify-content-between">
          <a class="link-underline" @click.stop="handleClick(item.loadData)">
            #{{ item.loadData?.id }}
          </a>
          <a v-if="item" @click="openNotesModal(item.loadData)">
            <v-icon small :color="item.loadNotes.some(obj => obj.value !== null && obj.value) ? 'green' : 'gray'">
              mdi-comment-text-multiple-outline
            </v-icon>
          </a>
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <a  @click.stop="viewInvoice(item)" class="link-underline">
          {{ item.loadData?.id }}
        </a>
      </template>
      <template v-slot:item.date="{ item }">
        <span class="link mini">
          {{ formatDate(item.date) }}
        </span>
      </template>
      <template v-slot:item.customer="{ item }">
          <a class="link" text @click="editItem(item.billing.customer.id)">
            {{ item.billing.customer.name }}
          </a>

      </template>
      <template v-slot:item.status="{ item }">
        <v-menu bottom :offset-x="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="full-box" small v-bind="attrs" v-on="!$store.getters.role.some(role => {
              return role === 'sales_representant' || role === 'customer_support'
            }) ? on : ''"
              :loading="loadingStatus === item.id" dark :color="statusColors[item.status]">
              <span v-if="item.status === 'refused'"><strike>{{ humanize(item.status).toUpperCase() }}</strike></span>
              <span v-else>{{ humanize(item.status).toUpperCase() }}</span>

            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(status, index) in invoiceStatuses" :key="index" @click="updateStatus(item, status)">
              <v-list-item-title>{{ humanize(status) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.totalDue="{ item }">
        <template v-if="item.status === 'unpaid'">
          <span>{{ item.totalDue }} {{ item.currency }}</span>
        </template>
        <template v-else>
          <span>{{ item.totalDue }} {{ item.currency }}</span>
          <strong v-if="item.balance > 0">&nbsp;&nbsp;&nbsp;({{ item.balance }} {{ item.currency }})</strong>
        </template>
      </template>
      <template v-slot:item.options="{ item }">

        <v-btn text color="secondary" @click.stop="invoiceRow(item)" small dark>
          <v-icon x-small class="mr-1">mdi-eye</v-icon> View
        </v-btn>
        <v-btn class="ml-3" v-if="item.status !== 'paid'" color="black" @click.stop="viewInvoice(item)" text small dark>
          <v-icon x-small class="mr-1">mdi-pencil</v-icon> Edit Invoice
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="viewInvoiceDialog" max-width="1500" scrollable transition="none" class="invoice">
      <v-card>
        <v-toolbar dark color="black" dense>
          <v-toolbar-title>Invoice # {{ inv? inv.loadData.id : '' }}
              <v-chip v-if="inv && inv.status === 'unpaid'" color="error" label>Unpaid</v-chip>
              <v-chip v-else-if="inv && inv.status === 'partially_paid'" color="secondary" label>Partially paid</v-chip>
              <v-chip v-else-if="inv && inv.status === 'paid'" color="success" label>Paid</v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

            <v-btn icon dark @click="closeViewInvoice()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <iframe :src="invoiceFrame" width="100%" style="height:900px" frameborder="0"></iframe>

      </v-card>
    </v-dialog>
    <v-dialog v-model="invoiceDialog" fullscreen hide-overlay transition="none">
      <v-card flat tile>
        <v-toolbar dark color="black" dense>
          <v-toolbar-title>Invoice for Load# {{ load_id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

            <v-btn icon dark @click="closeInvoice()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <blocking-layover :blocking-condition="$store.getters.role.some(role => {
              return role === 'sales_representant' || role === 'customer_support'
            })">
          <InvoiceEdit @saved="closeInvoice" v-if="load" :load="load" :invoice-obj="invoiceInEdit" />
          <InvoiceEdit @saved="closeInvoice" v-else-if="invoiceInEdit" :load="invoiceInEdit.loadData" :invoice-obj="invoiceInEdit" />
        </blocking-layover>
      </v-card>
    </v-dialog>
    <Load  />
    <CounterpartyEdit @saved="getApiData()" v-if="$store.state.users.inEdit" :counterparty="$store.state.users.inEdit">
    </CounterpartyEdit>
    <LoadNotes
        v-if="notesOpened"
        :load-id="loadForModal.id"
        @notesUpdated="getInvoices"
        @close="notesOpened = false"
        :is-notes-opened="notesOpened"
        :can-save-note="true"
    />
  </div>
</template>

<script>
import config from "@/config";
import axios from "@/plugins/axios";
import * as dayjs from 'dayjs'
import Load from "@/components/layout/Load";
import InvoiceEdit from "../InvoiceEdit.vue";
import invoiceStatuses from "@/helpers/invoiceStatuses";
import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import humanize from "@/helpers/humanize"
import { EventBus } from "@/helpers/eventBus";
import queryString from 'query-string';
import BlockingLayover from "@/components/layout/BlockingLayover.vue";
import LoadNotes from "@/components/loads/LoadNotes.vue";
import axiosInstance from "@/plugins/axios";
export default {
  props: {
    params: {
      type: Object,
      default: {}
    }
  },
  components: {
    LoadNotes,
    Load,
    InvoiceEdit,
    CounterpartyEdit,
    BlockingLayover
  },
  data() {
    return {
      invoiceInEdit: null,
      invoiceDialog: false,
      invoiceFrame: null,
      viewInvoiceDialog: false,
      loading: false,
      loadingStatus: null,
      loadInView: null,
      inv: null,
      invoice: {
        data: [],
        meta: {
          total: null
        }
      },
      apiParams: null,
      headers: [
        {
          text: 'Load #',
          align: 'start',
          sortable: true,
          value: 'load', width: 120
        },
        {
          text: 'Invoice #',
          align: 'start',
          sortable: true,
          value: 'id', width: 120
        },
        { text: 'Status', value: 'status', sortable: false, },
        { text: 'Invoice date', value: 'date', sortable: true, },
        { text: 'Customer', value: 'customer', sortable: true, },
        { text: 'Amount', value: 'totalDue', sortable: true, },
        { text: 'Options', value: 'options', sortable: false, },
      ],
      load: null,
      statusColors: {
        'partially_paid': 'light-blue darken-3',
        'unpaid': 'blue-grey darken-1',
        'paid': 'green accent-4',
      },
      notesOpened: false,
      loadForModal: null,
    }
  },
  created() {
    EventBus.$on('exportAccountingInvoices', this.exportInvoices)
    this.apiParams = this.params
  },
  computed: {
    invoiceStatuses() {
      return invoiceStatuses
    },
    load_id() {
      if (this.invoice && this.invoice.loadData) {
        return this.invoice ? this.invoice.loadData.id : this.load.id
      } else {
        return null
      }
    },
    defaultOptions() {
      return localStorage.getItem('accountingInvoiceTableOptions') ? JSON.parse(localStorage.getItem('accountingInvoiceTableOptions')) : {}
    }
  },
  methods: {
    openNotesModal(item) {
      this.loadForModal = item
      this.notesOpened = true
    },
    editItem(id) {
      axios.get(config.apiUrl + '/api/counterparty/' + id).then(
        (response) => {
          this.$store.state.users.inEdit = response.data
        }
      )
    },
    humanize(str) {
      return humanize.humanize(str)
    },
    updateStatus(invoice, status) {
      this.loadingStatus = invoice.id
      axios.patch(config.apiUrl + '/api/invoice/' + invoice.id + '/attribute', {
        attribute: 'status',
        value: status
      }).then(() => {
        invoice.status = status
        this.loadingStatus = false
        this.getInvoices()
      }).catch(() => {
        alert('Update status error')
      })
    },
    handleClick(item) {
      this.$store.dispatch('loadLoad', item.id)
    },
    invoiceRow(item) {
      this.viewInvoiceDialog = true
      this.inv = item
      this.invoiceFrame = config.apiUrl + '/invoice/' + this.inv.id + '?preview=1'
    },
    closeInvoice() {
      this.invoiceDialog = null
      this.load = null
      this.invoiceInEdit = null

      this.getInvoices()
    },
    onOptionUpdate(options) {
      localStorage.setItem('accountingInvoiceTableOptions', JSON.stringify(options))
      this.apiParams = options
      this.getInvoices()
    },
    exportInvoices(options, tabsModel) {
      let q = Object.assign(
          options,
          this.params,
          this.apiParams
      )
      let query = JSON.parse(JSON.stringify(q))

      let status

      switch(tabsModel) {
        case 2: {
          status = ['unpaid']
          break;
        }
        case 3: {
          status = ['paid', 'partially_paid']
          break;
        }
        default: {
          throw new Exception("tabsModel recieved unexpected, unsupported value: " + tabsModel)
        }
      }

      if(JSON.stringify(status) == JSON.stringify(q.status)) {
        q.status = status
        window.open(config.apiUrl + '/api/reports/invoices?' + queryString.stringify(query, {arrayFormat: 'bracket'}), '_blank')
      }
    },
    getInvoices() {
      this.loading = true
      axios.get(config.apiUrl + '/api/invoice', {
        params: Object.assign(
          {},
          this.params,
          this.apiParams
        )
      })
        .then((response) => {
          this.invoice = response.data
        }).finally(() => {
          this.loading = false
        })
    },
    viewInvoice(item) {
      this.invoiceDialog = true
      this.load = item.load_data
      this.invoiceInEdit = item
    },
    closeViewInvoice() {
      this.viewInvoiceDialog = false
      this.inv = null
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
  },
  watch: {
    params: {
      handler () {
        this.getInvoices()
      },
      deep: true
    }
  }
}
</script>

