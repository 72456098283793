<script>
import axiosInstance from "@/plugins/axios";
import config from "@/config";
import RouteMap from "@/components/map/RouteMap.vue";
import RouteHistory from "@/components/map/RouteHistory.vue";
import axios from "@/plugins/axios";

export default {
  name: "ClientRouteHistory",
  components: {RouteHistory, RouteMap},
  async mounted() {
    const route_params = this.$route.params.token_id.split('&')
    if (route_params.length === 2) {
      this.traking_id = route_params.at(0)
      this.load_id = route_params.at(1)
    }
    await this.getLoad()
    // if (this.carrier.status !== 'in_progress')
    //   this.$router.push({ name: '404' });
  },
  data() {
    return {
      load: {},
      load_id: null,
      traking_id: '',
      carrier: {},
      locationMarkers: [],
      socket_connection: false
    }
  },
  methods: {
    updateMarkers(markers) {
      this.locationMarkers = markers
    },
    async getLoad() {
      const url = `${config.apiUrl }/api/load/${this.load_id}`
      await axiosInstance.get(url)
          .then(response => {
            this.load = response.data
            this.carrier = this.load.load_carriers.at(0)
          })
    },
  }
}
</script>

<template>
  <div class="client_history_wrapper">
    <div class="container-fluid mt-10">
      <v-row v-if="Object.keys(carrier).length > 0" class="row">
        <v-col class="col-3">
          <RouteHistory :history="locationMarkers" />
        </v-col>
        <v-col v-if="traking_id.length > 0" class="col-9">
          <RouteMap
              :socket_connection="socket_connection"
              :traking_id="traking_id" :carrier="carrier" :load="load" @sendMarkers="updateMarkers" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .client_history_wrapper {
    max-width: 80%;
    margin: 0 auto;
  }
</style>