import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from 'vuetify/lib/util/colors'
import '@/assets/main.scss'

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#112D4E',
        secondary: '#1565C0',
        accent: colors.shades.black,
        error: colors.red.accent3,
        light: '#FFF',
      },
      dark: {
        primary: '#B0BEC5',
        secondary: '#1565C0',
        light: '#000',
      },
    },
  },
})
export default vuetify
