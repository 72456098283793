import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c(VTabs,{attrs:{"slider-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.consignees),function(item,idx){return _c(VTab,{key:idx,attrs:{"draggable":true},on:{"dragstart":function($event){return _vm.onDragStart(idx)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver(idx)},"dragleave":_vm.onDragLeave,"drop":function($event){_vm.onDrop(idx, _vm.consignees); _vm.tab = idx}}},[_c('span',{staticStyle:{"pointer-events":"all"}},[_vm._v("Consignee - "+_vm._s(idx + 1))]),_c(VIcon,{staticClass:"ml-3",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(idx)}}},[_vm._v("mdi-close")])],1)}),_c(VSpacer),(!_vm.allowEdit)?_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":_vm.add}},[_vm._v(" Add new consignee ")]):_vm._e()],2),_c(VTabsItems,{staticClass:"pt-2 px-3",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.consignees),function(item,index){return _c(VTabItem,{key:index,attrs:{"eager":"","transition":false}},[_c('Item',{attrs:{"consignee":item,"index":index}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }