<template>
  <div>

    <v-card>
      <v-card-title class="text-h5">
        Bank accounts
      </v-card-title>
      <v-card-subtitle>
        Overview of you bank accounts
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col md="6" v-for="company in companies.list">
            <v-card outlined>
              <v-card-text>

                <div class="text-overline">
                  {{ company.name }} :
                </div>
                <div class=" mb-4">
                  <small>{{ company.meta.check }}</small>
                </div>
                <v-btn block color="success" @click="add(company.id)">Add new account</v-btn>
                <v-card class="my-3" v-for="(account, idx) in showAccounts(company.id)">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        Account name:
                        <v-text-field v-model="account.name" hide-details />
                        <v-btn-toggle class="my-3">
                          <v-btn small text color="secondary" @click="update(account)">Update</v-btn>
                          <v-btn small text color="red"
                            @click="account.id ? remove(account.id) : removeNoId(idx)">Remove</v-btn>
                        </v-btn-toggle>
                        <div>
                          <small>Last updated: {{ formatDate(account.updated_at) }}</small>
                        </div>
                      </v-col>
                      <v-col>
                        Balance:
                        <v-text-field type="number" v-model="account.balance" hide-details />
                        <v-select v-model="account.currency" :items="currencies" hide-details />

                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import config from "@/config";
import * as dayjs from 'dayjs'

export default {
  data() {
    return {
      newAccount: {},
      currencies: ['USD', 'CAD'],
      companies:
      {
        list: [],
        accounts: []
      }
      ,
    }
  },
  mounted() {
    axios.get(config.apiUrl + '/api/accounting-company').then((response) => {
      this.companies.list = response.data
    })
    axios.get(config.apiUrl + '/api/bank-account').then((response) => {
      this.companies.accounts = response.data
    })
  },
  computed: {
  },
  methods: {
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : 'Not set'
    },
    showAccounts(value) {
      let result = null
      result = this.companies.accounts.filter((item) => item.accounting_company_id === value)
      return result
    },
    add(company_id) {
      const account = {
        accounting_company_id: company_id,
        name: null,
        balance: null,
        currency: null
      }
      this.companies.accounts.unshift(account)
    },
    update(account) {
      const method = account.id ? 'patch' : 'post';
      const url = `${config.apiUrl}/api/bank-account/${account.id || ''}`;

      axios[method](url, account)
        .then(response => {
          return axios.get(`${config.apiUrl}/api/bank-account`);
        })
        .then(response => {
          this.companies.accounts = response.data;
        })
        .catch(error => {
          console.error('Error updating bank account:', error);
        });
    },
    remove(id) {
      axios.delete(config.apiUrl + '/api/bank-account/' + id).then((response) => {
        axios.get(config.apiUrl + '/api/bank-account').then((response) => {
          this.companies.accounts = response.data
        })
      })
    },
    removeNoId(idx) {
      this.companies.accounts.splice(idx, 1)
    }
  }
}
</script>