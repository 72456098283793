<template>
  <div class="my-5 bg-light py-2 px-1 rounded">
    <div class="d-flex justify-content-between align-items-center my-1">
      <h4 class="text-muted">Signature</h4>
      <div>Please sign in the field below</div>
      <div>
        <v-btn
            @click="clearPad"
            :disabled="!signatureData"
            color="warning"
            outlined
        >
          Clear
        </v-btn>
      </div>
    </div>
    <div class="signature-pad bg-light py-2 d-flex justify-content-center">
      <VueSignaturePad
          ref="signaturePad"
          :options="{ onEnd, ...options }"
          :width="width"
          :height="height"
          style="background: white; border-radius: 3px;"
          :style="validateSignature && !signatureData ? 'border: 2px solid red' : '  border: 2px solid gray;'"
      />
    </div>
  </div>
</template>

<script>
import {VueSignaturePad} from "vue-signature-pad";

export default {
  name: 'CounterpartySignature',
  components: {VueSignaturePad},
  data() {
    return {
      options: {
        penColor: '#0000FF',
      },
      signatureData: null
    }
  },
  props: {
    validateSignature: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100px'
    }
  },
  methods: {
    onEnd() {
      const {data} = this.$refs.signaturePad.saveSignature()
      this.signatureData = data
      this.$emit('signed', this.signatureData)
    },
    clearPad() {
      this.$refs.signaturePad.clearSignature();
      this.signatureData = null
      this.$emit('clear')
    },
  }
}
</script>