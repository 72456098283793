<template>
  <v-select
      :value="value"
      :items="items"
      @input="$emit('input', $event)"
      class="mini"
      hide-details="auto"
      label="Equipment type"
      outlined dense clearable
      return-object
      item-text="name"
      item-value="id"
  ></v-select>
</template>

<script>
import axios from "@/plugins/axios";
import config from "@/config";

export default {
  name: "EquipmentTypeSelect",
  props: {
    value: {
      type: [Object, null]
    }
  },
  data () {
    return {
      items: [this.value]
    }
  },
  mounted() {
    axios.get(config.apiUrl + '/api/equipment-type')
        .then(response => {
          this.items = response.data
        })
  }
}
</script>

<style scoped>

</style>
