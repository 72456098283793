export default {
	email (email) {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	},
	isValidDate(dateString) {
		// Check if the format is correct using a regular expression (YYYY-MM-DD)
		const regex = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateString.match(regex)) {
			return false;
		}

		// Parse the date components
		const [year, month, day] = dateString.split('-').map(Number);

		// Create a date object
		const date = new Date(year, month - 1, day);

		// Check if the date is valid
		if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
			return false;
		}

		return true;
	}
}