<template>
  <v-app>
    <div>
      <v-app-bar v-if="$store.state.auth.user" app color="light"  elevation="3" dense>
        <Header class="header" />
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
      <Footer />
      <safer-watch v-if="false" />
      <v-dialog max-width="1000" v-model="$store.state.carrierHistory.showCarrierHistory">
        <CounterpartyHistory :items="$store.state.carrierHistory.carrierHistory.data" />
      </v-dialog>
      <v-dialog max-width="1000" v-model="$store.state.customerHistory.showCustomerHistory">
        <CounterpartyHistory :items="$store.state.customerHistory.customerHistory.data" />
      </v-dialog>
      <v-dialog max-width="1000" :value="$store.state.quickInvoices.showQuickInvoices" @click:outside="$store.commit('quickInvoices/openModal', false)">
        <QuickInvocies />
      </v-dialog>
      <v-dialog max-width="1000" :value="$store.state.quickBills.showQuickBills" @click:outside="$store.commit('quickBills/openModal', false)">
        <QuickBills />
      </v-dialog>
      <PayQuick v-if="$store.state.payment.payInEdit" :params="$store.state.payment.payInEdit" @closed="$store.commit('payment/closePayment')" />
    </div>
  </v-app>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Load from './components/layout/Load.vue';
import QuickList from './components/loads/QuickList.vue';
import QuickInvocies from '@/components/tools/QuickInvoices.vue'
import QuickBills from '@/components/tools/QuickBills.vue'
import PayQuick from "@/components/tools/PayQuick.vue";
import CounterpartyHistory from "@/components/tools/CounterpartyHistory.vue"
import ClientRouteHistory from "@/views/map/ClientRouteHistory.vue";
export default {
  name: "App",
  components: {
    ClientRouteHistory,
    PayQuick,
    Header,
    Footer,
    Load,
    QuickList,
    QuickInvocies,
    CounterpartyHistory,
    QuickBills
  },
  beforeCreate() {
    this.$store.dispatch('taxes/loadTaxes')
    if (localStorage.getItem('user') && localStorage.getItem('token')) {
      this.$store.state.auth.user = JSON.parse(localStorage.getItem('user'))
      this.$store.state.auth.token = localStorage.getItem('token')
    }
  },
};

/*INFO: Yandex Metrix*/
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  m[i].l=1*new Date();
  for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
  k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(98241567, "init", {
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:true
});
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/98241567" style="position:absolute; left:-9999px;" alt="" /></div></noscript>