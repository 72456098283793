<template>
    <v-dialog v-model="files" width="1000px" max-height="800px" scrollable @click:outside="$emit('close')" @keydown.esc="$emit('close')">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>File manager - #{{ load.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-3">
          <blocking-layover style="height:auto;" class="d-block" :blocking-condition="restrictedEdit">
            <template v-if="load?.load_carriers?.length >= 1">
              <div class="my-2">Documents related to this load: </div>
              <FilesList :additionalData="additionalData" class="files_list" entity-type="load" :entity-id="load.id" />
            </template>
            <div v-for="(carrier, index) in load.load_carriers.filter(load => load.carrier)">
              <div class="my-2">Documents related for <template v-if="load.load_carriers.length > 1">{{ index + 1 }}</template> carrier ({{ carrier?.carrier?.name }}): </div>
                <div class="d-flex my-2 align-items-center" v-if="carrier?.carrier?.carrier_data?.factoring_company">
                  <input type="checkbox"
                    :id="'checkbox_label' + index"
                    :checked="carrier.factoring_company_id"
                    :value="carrier.factoring_company_id"
                    @input="$emit('setCarrierFactoringCompany', {
                      index, value: $event.target.value
                    })"
                    style="position: relative;"
                    hide-details
                    class="shrink mr-2 mt-0"
                  >
                  <label role="button" :for="'checkbox_label' + index" style="font-size:1rem;" class="mr-1 mb-0">Use factoring company </label>
                    <v-text-field readonly class="mini"
                      hide-details="auto"
                      label="Factoring company"
                      outlined dense :value="carrier.factoring_company?.name ?? 'Not set'"
                    />
                </div>
              <FilesList 
                class="files_list" 
                :roles="load?.load_carriers?.length === 1 ? ['load_carrier_single'] : ['load_carrier_multiple']" 
                :colSize="2"
                :additionalParams="{ 'load_id': load.id, 'carrier_id': carrier.carrier_id }"
                :entity-id="carrier.carrier_id"
                entity-type="counterparty" 
              />
            </div>
          </blocking-layover>
          <blocking-layover :blocking-condition="restrictedEdit">
            <Files style="width:100%" :type="'load'" :id="load.id" :entity="load" />
          </blocking-layover>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import FilesList from "@/components/tools/FilesList.vue"
import Files from "@/components/files/List.vue";
import BlockingLayover from '../layout/BlockingLayover.vue';
export default {
  mounted() {
    console.log('load', this.load);
  },
  components: {
        Files,
        FilesList,
        BlockingLayover
    },
    props: {
        files: {
            type: Boolean,
            required: true
        },
        additionalData: {
            type: Object,
            default: {}
        },
        load: {
            type: Object,
            required: true
        },
        restrictedEdit: {
            type: Boolean,
            required: true
        }
    }
}
</script>