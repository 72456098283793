<template>
  <div>
    <v-data-table :headers="headers" :items="$store.state.quickInvoices.list.data" :items-per-page="10" >

      <template v-slot:item.desc="{ item }">
        Invoice for Load #{{ item.loadData.id }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:item.due="{ item }">
        {{ formatDate(item.due) }}
      </template>
      <template v-slot:item.status="{ item }">
        <div v-if="item.status === 'unpaid'" class="text-danger">
          {{ humanize(item.status).toUpperCase() }}
        </div>
        <div v-else-if="item.status === 'partially_paid'" class="text-warning">
          {{ humanize(item.status).toUpperCase() }}
        </div>
        <div v-else class="text-success">
            <span @click="showPayment(item)">{{ humanize(item.status).toUpperCase() }}</span>
        </div>
      </template>
      <template v-slot:item.amount="{ item }">
        $ {{ item.totalDue }} {{ item.currency === 'CAD' ? 'CAD' : '' }}
      </template>
      <template v-slot:item.view="{ item }">
          <v-btn text color="secondary" @click.stop="invoiceRow(item)" small dark>
              <v-icon x-small class="mr-1">mdi-eye</v-icon> View
          </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="viewInvoiceDialog" max-width="1500" scrollable transition="none" class="invoice">
        <v-card>
            <v-toolbar dark color="black" dense>
                <!--v-toolbar-title>Invoice # {{ inv? inv.loadData.id : '' }}
                    <v-chip v-if="inv && inv.status === 'unpaid'" color="error" label>Unpaid</v-chip>
                    <v-chip v-else-if="inv && inv.status === 'partially_paid'" color="secondary" label>Partially paid</v-chip>
                    <v-chip v-else-if="inv && inv.status === 'paid'" color="success" label>Paid</v-chip>
                </v-toolbar-title-->
                <v-spacer></v-spacer>
                <v-toolbar-items>

                    <v-btn icon dark @click="closeViewInvoice()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <iframe :src="invoiceFrame" width="100%" style="height:900px" frameborder="0"></iframe>

        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import humanize from "@/helpers/humanize"
import config from "@/config";

export default {
  name: 'QuickInvoices',
  data() {
    return {
      invoiceDialog: false,
      invoiceFrame: null,
      viewInvoiceDialog: false,
      headers: [
        { text: 'Description', value: 'desc' },
        { text: 'Date', value: 'date' },
        { text: 'Due date', value: 'due' },
        { text: 'Status', value: 'status' },
        { text: 'Amount', value: 'amount' },
        { text: 'View', value: 'view' },
      ],
      items: []
    }
  },
  created() {
    this.$store.dispatch('quickInvoices/getList')
  },
  methods: {
    humanize(str) {
      return humanize.humanize(str)
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
    invoiceRow(item) {
        this.viewInvoiceDialog = true
        this.inv = item
        this.invoiceFrame = config.apiUrl + '/invoice/' + this.inv.id + '?preview=1'
    },
    closeViewInvoice() {
        this.viewInvoiceDialog = false
        this.inv = null
    },
    showPayment (item) {
        this.$store.commit('payment/openPayment', {
            type: 'invoice',
            itemIds: [item.id]
        })
    }
  }
}
</script>
