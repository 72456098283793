<template>
  <div>
    <v-tabs v-model="tab" show-arrows slider-color="transparent">
    <v-btn small text color="secondary" @click="add" class="mt-1">
      Add new address
    </v-btn>
      <v-spacer></v-spacer>
      <v-tab v-for="(address, index) in createdAddresses" :key="'cah-' + index">
        Address - {{ index + 1 }}
        <v-btn x-small text color="error" @click="remove(index)">
          <v-icon small>mdi-close-box</v-icon>
        </v-btn>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
          class="mt-5"
          eager
          v-for="(address, index) in counterparty['counterparty_addresses']"
          :key="'cac-' + index"
          :transition="false"
      >
        <CounterpartyAddress
          :address="address"
          :index="index"
        ></CounterpartyAddress>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CounterpartyAddress from "@/components/counterparty/CounterpartyAddress";
export default {
  name: "CounterpartyAddresses",
  components: {CounterpartyAddress},
  props: {
    counterparty: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: null,
      validation: {
        required: [
          v => !!v || 'Required'
        ]
      },
    }
  },
  computed: {
    createdAddresses() {
      return this.counterparty['counterparty_addresses'];
    },
  },
  methods: {
    add() {
      this.counterparty.counterparty_addresses.push({
        "id": null,
        "is_primary": false,
        "address1": null,
        "address2": null,
        "country_code": null,
        "state": null,
        "city": null,
        "postcode": null,
        "type": "default"
      });
    },

    remove(item) {
      this.counterparty.counterparty_addresses.splice(item, 1);
    },
  }
}
</script>

<style scoped>

</style>
