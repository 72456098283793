<template>
  <div>
    <v-card class="mb-5" v-for="category in items" flat>
      <v-data-table class="table-bordered custom-table" :headers="headers" :items="category.children ?? []" dense
        hide-default-footer>
        <template v-slot:header.name="{ item }">
          <strong style="color: #000;">{{ category.name }}</strong>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr>
              <td>
                {{ category.name }}
              </td>
              <td v-for="val in category.values">
                {{ val.toFixed(2) }}
              </td>
              <td class="font-weight-black">
                {{ arraySum(category.values) }}
              </td>
            </tr>
            <tr v-for="(item, idx) in items">
              <td>{{ item.name }}</td>
              <td v-for="(value, index) in item.values">
                {{ value.toFixed(2) }}
              </td>
              <td class="font-weight-black">
                {{ arraySum(items[idx].values) }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="category.children.length > 0">
            <tr class="font-weight-black grey lighten-3">
              <td>Totals</td>
              <td v-for="(header, idx) in headers.slice(1, -1)"> <!-- Skip the "Category" column -->
                {{ sumOfChildrenValues(category, idx) }}
              </td>
              <td>
                {{ totalOfChildrenValues(category) }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import config from '@/config';
import axiosInstance from '@/plugins/axios';

export default {
  name: "DashboardBudget",
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      table: [],
      headers: [
        { "text": "Category", "value": "name", align: "start", width: 400 },
        { "text": "Jan", "value": 1 },
        { "text": "Feb", "value": 2 },
        { "text": "Mar", "value": 3 },
        { "text": "Apr", "value": 4 },
        { "text": "May", "value": 5 },
        { "text": "Jun", "value": 6 },
        { "text": "Jul", "value": 7 },
        { "text": "Aug", "value": 8 },
        { "text": "Sep", "value": 9 },
        { "text": "Oct", "value": 10 },
        { "text": "Nov", "value": 11 },
        { "text": "Dec", "value": 12 },
        { "text": "Total", "value": 'total' }
      ],
      //items: [],
      items: [
        {
          id: 1,
          name: "Cost of Services",
          children: [
            {
              id: 10,
              name: "Carriers",
              values: [
                1, 2323, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
              ]
            },
            {
              id: 11,
              name: "Taxes",
              values: [
                121, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
              ]
            },
            {
              id: 12,
              name: "Other",
              values: [
                121, 2, 3, 4, 5, 0, 7, 8, 9, 10, 11, 12
              ]
            }
          ]
        },
        {
          id: 2,
          name: "Cost of Servicssses",
          children: [
            {
              id: 10,
              name: "Carriers",
              values: [
                1, 4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
              ]
            },
            {
              id: 11,
              name: "Taxes",
              values: [
                121, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
              ]
            },
            {
              id: 12,
              name: "Other",
              values: [
                121, 2, 3, 4, 5, 0, 7, 8, 9, 10, 11, 12
              ]
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.$emit('loading', false)
    axiosInstance.get(config.apiUrl + '/api/reports/budget-totals').then((response) => {
      this.items = response.data
    })
  },
  methods: {
    sumOfChildrenValues(category, idx) {
      let sum = 0;
      category.children.forEach(ch => {
        sum += ch.values[idx]
      })
      return sum.toFixed(2)
    },
    totalOfChildrenValues(category) {
      let sum = 0;
      category.children.forEach(ch => {
        ch.values.forEach(v => {
          sum += v;
        })
      })
      return sum.toFixed(2)
    },
    arraySum(array) {
      let sum = 0
      for (let arr of array) {
        const number = parseFloat(arr)
        sum += number
      }
      return sum.toFixed(2)
    }
  }
}
</script>
