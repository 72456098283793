<template>
  <div>
    <v-combobox 
      outlined dense  
      hide-details="auto"
      :items="addresses" 
      :item-text="addressLabel" 
      item-id="id"
      :value="value"
      return-object
      class="mini"
      :label="label ? label : ''"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'PrimaryAddress',
  props: {
    addresses: {
      type: Array,
      required: false,
    },
    value: {
      type: [Object, null],
      default () {
        return null
      }
    },
    label: {
      type: [String, null],
      required: false
    }
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
  }, 
  methods: {
    primaryAddress() { 
      let selectedA = null
      for (let item of this.addresses) {
        if (item.is_primary) {
          selectedA = item
        }
      }
      this.selected = selectedA
      this.$emit('change', this.selected)
    },
    addressLabel (address) {
      return [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
        return !!addressPart
      }).join(', ')
    }
  },
  watch: {
  }
}
</script>