<template>

  <v-card outlined light>
    <v-card-text>
      <v-tabs v-model="tab" slider-color="transparent">
        <v-tab
            v-for="(item, idx) in carriers"
            :key="idx"
            transition="false"
            :draggable="true"
            @dragstart="onDragStart(idx)"
            @dragover.prevent="onDragOver(idx)"
            @dragleave="onDragLeave"
            @drop="onDrop(idx, carriers); tab = idx"
        >
          <span style="pointer-events: all;">Carrier - {{ idx + 1 }}</span>
          <v-btn style="min-width:0" x-small class="ml-5 px-1" text color="error" @click="remove(idx)">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn v-if="!allowEdit" small color="secondary" @click="add">
          Add new carrier
        </v-btn>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pt-2">
        <v-tab-item v-for="(item, index1) in carriers" :key="index1" eager :transition="false">
          <Item @openRC="$emit('openRC', index1)" :load="load" :carrier="item"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import Item from './Item.vue';
import dragSwapMixin from "@/mixins/dragSwapMixin";

export default {
  name: "Carriers",
  mixins: [dragSwapMixin],
  props: {
    carriers: {
      type: Array,
      required: true
    },
    load: {
      type: Object,
      required: false
    }
  },
  components: { Item },
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    allowEdit () {
      return !this.$store.getters.role.includes('admin') && this.load.status === 'invoiced'
    },
  },
  methods: {
    add() {
      this.carriers.push({
        "load_id": null,
        "carrier_id": null,
        "equipment_type_id": null,
        "carrier_pay_type_id": null,
        "total_fee": null,
        "currency": "USD",
        "rate": null,
        "picks_drops": null,
        "fcs_value": null,
        "fcs_type": "amount",
        "other_charges": [],
        "carrier": null,
        "carrier_pay_type": null,
        "equipment_type": null,
        "load_carrier_shippers": [
          {
            "load_carrier_id": null,
            "shipper_id": null,
            "customs_broker_id": null,
            "location": null,
            "location_coordinates": null,
            "location_data": null,
            "date": null,
            "description": null,
            "type": null,
            "quantity": null,
            "weight": null,
            "cost": null,
            "shipping_notes": null,
            "po_numbers": null,
            "customs_broker": null,
            "shipper": null
          }
        ],
        "load_carrier_consignees": [
          {
            "load_carrier_id": null,
            "consignee_id": null,
            "location": null,
            "location_coordinates": null,
            "location_data": null,
            "date": null,
            "description": null,
            "type": null,
            "quantity": null,
            "weight": null,
            "value": null,
            "delivery_notes": null,
            "po_numbers": null,
            "consignee": null
          }
        ]
      })
    },
    remove(item) {
      this.carriers.splice(item, 1);
    },
  }
}
</script>
