<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-card outlined tile v-if="count">
          <v-card-title><strong>{{ data.count }} $</strong></v-card-title>
          <v-card-subtitle>{{ name }}</v-card-subtitle>
        </v-card>
        <div v-else>
          <strong>{{ name }}</strong>
          <hr>
        </div>
        <div>
          <apexchart type="bar" :height="height" :options="chartOptions" :series="data.series"></apexchart>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      required: true 
    },
    count: {
      type: Boolean,
      required: true
    },
    setColors: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      default: 350
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '100%',
            endingShape: 'rounded'
          }
        },
        colors: this.setColors,
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals

            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          }
        },
        legend: {
          show: false,
        }
      },
    }
  }
}
</script>