<template>
  <div>
    <v-tabs v-model="tab" show-arrows slider-color="transparent">
    <v-btn small text  color="secondary" @click="add" class="mt-1">
      Add new contact
    </v-btn>
    <v-spacer></v-spacer>
      <v-tab v-for="(contact, index) in counterparty['counterparty_contacts']" :key="'cch-' + index">
        {{ contact.type }}
        <v-btn x-small text color="error" @click="remove(index)">
          <v-icon small>mdi-close-box</v-icon>
        </v-btn>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="pt-5" eager v-for="(contact, index) in counterparty['counterparty_contacts']"
        :key="'ccc-' + index"
        :transition="false"
      >
        <v-row>
          <v-col>
            <v-text-field v-model="contact.contact" :rules="validation.required" label="Contact" required class="mini"
              dense outlined></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="contact.contact_person" label="Contact person" class="mini" dense
              outlined></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="contact.type" :items="['phone', 'email', 'fax']" label="Contact type" dense outlined
              persistent-hint class="mini"></v-select>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "CounterpartyContacts",
  props: {
    counterparty: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      validation: {
        required: [
          v => !!v || 'Required'
        ]
      }
    }
  },
  methods: {
    add() {
      this.counterparty.counterparty_contacts.push({
        "id": null,
        "contact": "",
        "contact_person": "",
        "type": "phone"
      });
    },
    remove(item) {
      this.counterparty.counterparty_contacts.splice(item, 1);
    },
  }
}
</script>

<style scoped>

</style>
