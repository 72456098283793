<template>
  <div>
    <v-text-field v-if="internalValue.type === 'string'" v-model="internalValue.value" dense outlined clearable v-bind="{ ...$props, ...$attrs }"></v-text-field>
    <v-textarea v-else-if="internalValue.type === 'text'" v-model="internalValue.value" dense outlined clearable v-bind="{ ...$props, ...$attrs }"></v-textarea>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Note",
  props: {
    value: {
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      default() {
        return null
      }
    },
    valueType: {
      type: String,
      default() {
        return 'string'
      },
      required: false
    }
  },
  created () {
    if (typeof this.value.find(i => i.key === this.valueKey) === 'undefined') {
      this.value.push({
        key: this.valueKey,
        type: this.valueType,
        value: null
      })
    }
  },
  computed: {
    internalValue () {
      if (!Array.isArray(this.value)) {
        return
      }
      return this.value.find(i => i.key === this.valueKey)
    }
  }
}
</script>

<style scoped>

</style>
