<template>
  <div class="px-5" v-if="false">
    <v-row dense>
      <v-col md="4">
      {{ companyLabel }}
      </v-col>
      <v-col md="8">
        <strong>{{ insurance.company_name }}</strong>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="4">
        Policy #
      </v-col>
      <v-col md="3">
        <strong>{{ insurance.policy_number }}</strong>
      </v-col>
      <v-col md="2">
        Exp. Date
      </v-col>
      <v-col md="2">
        <strong>{{ insurance.policy_expiration }}</strong>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="4">
        {{amountLabel}}
      </v-col>
      <v-col md="8">
        <strong>{{ insurance.amount }}</strong>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="4">
        Telephone
      </v-col>
      <v-col md="8">
        <strong>{{ insurance.phone }}</strong>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="4">
        Contact
      </v-col>
      <v-col md="8">
        <strong>{{ insurance.contact_name }}</strong>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-text-field outlined dense
        v-model="insurance.company_name"
        :rules="[]"
        :label="companyLabel" class="mini"
    ></v-text-field>
    <v-row> 
      <v-col>
        <v-text-field outlined dense
            v-model="insurance.policy_number"
            :rules="[]"
            label="Policy #" class="mini" hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined dense
            v-model="insurance.policy_expiration"
            :rules="[]"
            label="Exp. Date" class="mini" hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined dense
            v-model="insurance.amount"
            :rules="[]"
            :label="amountLabel" class="mini" hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field outlined dense
            v-model="insurance.phone"
            :rules="[]"
            label="Telephone" class="mini" hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined dense
            v-model="insurance.contact_name"
            :rules="[]"
            label="Contact" class="mini" hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CounterpartyInsurance",
  props: {
    insurance: {
      type: Object,
      required: true
    }
  },
  computed: {
    companyLabel () {
      switch (this.insurance.type) {
        case 'liability':
          return 'Liability Company';
        case 'auto':
          return 'Auto Insurance Company';
        case 'cargo':
          return 'Cargo Company';
        case 'fmcsa':
          return 'FMCSA Insurance Company';
        default:
          return 'Insurance Company';
      }
    },
    amountLabel () {
      switch (this.insurance.type) {
        case 'liability':
        case 'auto':
          return 'Liability ($)';
        case 'cargo':
          return 'Cargo Ins. ($)';
        case 'fmcsa':
          return 'Coverage ($)';
        default:
          return 'Amount ($)';
      }
    }
  }
}
</script>

<style scoped>

</style>
