<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-card outlined tile v-if="count">
          <v-card-title><strong>{{ data.count }}</strong></v-card-title>
          <v-card-subtitle>{{ name }}</v-card-subtitle>
        </v-card>
        <v-card outlined tile v-else-if="!count && name">
          <v-card-title><strong>{{ name }}</strong></v-card-title>
        </v-card>
        <div>
          <apexchart type="line" :height="height" :options="chartOptions" :series="data.series"></apexchart>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      required: true
    },
    count: {
      type: Boolean,
      required: true
    },
    setColors: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      default: 350
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: this.height,
          type: 'line',
          zoom: {
                enabled: false
              }
        },
        plotOptions: {
          bar: {
            horizontal: false,
          }
        },
        colors: this.setColors,
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
      },
    }
  }
}
</script>