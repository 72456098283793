<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table :headers="headers" :items="$store.state.carrierHistory.carrierHistory.data" :items-per-page="10">
          <template v-slot:item.id="{ item }">

            <a class="link-underline" @click="handleClick(item)">
              #{{ item.id }}
            </a>
          </template>
          <template v-slot:item.date_ship="{ item }">
            <span class="link mini" v-for="dates  in  item.load_carriers[0].load_carrier_shippers">
              {{ formatDate(dates.date) }}
            </span>
          </template>
          <template v-slot:item.date_delivery="{ item }">
            <span class="link mini" v-for="dates  in  item.load_carriers[0].load_carrier_consignees">
              {{ formatDate(dates.date) }}
            </span>
          </template>
          <template v-slot:item.customer="{ item }">

              {{ item.load_finances.customer ? item.load_finances.customer.name : '-' }}
          </template>
          <template v-slot:item.origin="{ item }">
            <div class="load-address" v-if="item.load_carriers.length">
              <div v-if="item.load_carriers[0].load_carrier_shippers.length >= 2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <template v-if="item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0]">
                      <h3 v-bind="attrs" v-on="on">{{
                        formattedAddress(item.load_carriers[0].load_carrier_shippers[0] &&
                          item.load_carriers[0].load_carrier_shippers[0])
                      }} <v-icon class="ml-3" small>mdi-information-outline</v-icon></h3>
                    </template>

                  </template>
                  <ul>
                    <li v-for="shipper in item.load_carriers[0].load_carrier_shippers">
                      <strong>{{ formattedAddress(shipper) }}</strong> <br>
                      <span>{{ shipper.shipper ? shipper.shipper.name : '-' }}</span>
                    </li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else>
                <h3 v-if="item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0]">{{
                  formattedAddress(item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0])
                }}</h3>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.direction="{ item }">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </template>
          <template v-slot:item.destination="{ item }">
            <div class="load-address" v-if="item.load_carriers.length">
              <div v-if="item.load_carriers[0].load_carrier_consignees.length >= 2">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">{{
                      item.load_carriers[0] &&
                      item.load_carriers[0].load_carrier_consignees[0] ?

                      formattedAddress(
                        item.load_carriers.at(-1).load_carrier_consignees.at(-1)
                      )

                      : '-'
                    }} <v-icon class="ml-3" small>mdi-information-outline</v-icon></h3>


                  </template>
                  <ul>
                    <li v-for="consignee in item.load_carriers[0].load_carrier_consignees">
                      <strong>{{ consignee.location }}</strong> <br>
                      <span>{{ consignee.name ?? "" }}</span>
                    </li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else>

                <h3 v-if="item.load_carriers.at(-1) && item.load_carriers.at(-1).load_carrier_consignees.at(-1)">
                  {{
                    formattedAddress(

                      item.load_carriers.at(-1).load_carrier_consignees.at(-1)
                    )
                  }}
                </h3>

              </div>

            </div>
            <div v-else>
              -
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Load />
  </div>
</template>

<script>
import Load from "@/components/layout/Load";
import * as dayjs from 'dayjs'
export default {
  components: {
    Load
  },
  name: "QuickList",
  data() {
    return {
      headers: [
        { text: 'Load #', value: 'id' },
        { text: 'Ship date', value: 'date_ship' },
        { text: 'Delivery date', value: 'date_delivery' },
        { text: 'Customer', value: 'customer' },
        { text: 'Origin', value: 'origin', sortable: false },
        { text: '', value: 'direction', sortable: false },
        { text: 'Destination', value: 'destination', sortable: false },
      ]
    }
  },
  mounted: {

  },
  methods: {
    formattedAddress(item) {
      if (!item.location_parts) {
        return item.location
      }
      const parts = [
        item.location_parts.city,
        item.location_parts.state,
        item.location_parts.country_code,
      ]

      return parts.filter(i => !!i).join(', ')
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
    handleClick(item) {
      this.$store.dispatch('loadLoad', item.id)
    },
  }
}
</script>
