import axios from "axios";
import router from "@/router";

const axiosInstance =  axios.create({
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const { response, message } = error
    if (response) {
        if (response.status === 401) {
            router.push('/login')
        }
        // backend error
        //alert(error.response.data.message)
    } else if (message) { // network error
        //alert(message)
    }
    return Promise.reject(error)
});

export default axiosInstance;
