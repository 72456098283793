<script>
export default {
    name: "TaxSelect",
    props: {
        value: {
            type: [String, Object],
            default: null,
        },
        label: {
            type: String,
            default: 'Taxes'
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        taxes () {
            return this.$store.state.taxes.taxes;
        }
    },
    methods: {
        onInput (value) {
            this.$emit('input', this.returnObject ? value : value.id);
        }
    }
}
</script>

<template>
  <v-select
    :value="value"
    :items="taxes"
    :label="label"
    v-bind="$attrs"
    @change="onInput"
    :required="required"
  >
    <template v-slot:selection="{ item }">
      {{ item.state }} {{ item.rate }}%
    </template>
    <template v-slot:item="{ item }">
      {{ item.state }} {{ item.rate }}%
    </template>
  </v-select>
</template>

<style scoped>

</style>
