export default [
    'open',
    'covered',
    'dispatched',
    'loading',
    'on_route',
    'unloading',
    'in_yard',
    'delivered',
    'completed',
    'refused',
    'claim'
]
