import { render, staticRenderFns } from "./CarrierFee.vue?vue&type=template&id=ef8f9d74&scoped=true"
import script from "./CarrierFee.vue?vue&type=script&lang=js"
export * from "./CarrierFee.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef8f9d74",
  null
  
)

export default component.exports