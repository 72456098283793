<template>
  <v-container fluid>
    <v-breadcrumbs class="pt-0 pl-0 pb-2" :items="breads" divider="/"></v-breadcrumbs>
    <v-card color="primary" dark class=" mb-3">
      <v-card-text>

        <div class="d-flex align-items-center w-100">
          <div class="page-header mr-auto">
            <h4 class="my-0">Accounting manager</h4>
          </div>
          <v-btn-toggle v-model="filtersData.status" mandatory dense borderless background-color="transparent"
            active-class="active-simple" class="btn-filters">
            <v-btn text :value="['delivered']" @click="filtersData.withInvoice = null && invoices == false" small>
              Delivered
            </v-btn>
            <v-btn text :value="['completed']" @click="filtersData.withInvoice = null && invoices == false" small>
              Completed
            </v-btn>
            <v-btn text @click="invoices === true"  small>
              Invoices
            </v-btn>
            <v-btn text :value="['no2']" small>
              Paid/Unpaid
            </v-btn>
          </v-btn-toggle>
          <div class="ml-auto">
            <v-text-field class="mini-search" outlined v-model="filtersData.search" append-icon="mdi-magnify"
              label="Quick search" dense  clearable hide-details></v-text-field>

          </div>

        </div>
      </v-card-text>
    </v-card>
    <div>
      sdsdsds
    </div>
    <v-dialog
      v-model="invoiceDialog"
      fullscreen
      hide-overlay
      transition="none"
    >
    <v-card flat tile>
      <v-toolbar
          dark
          color="black"
          dense
        >
          <v-toolbar-title>Invoice for Load# {{ load ? load.id : '' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          <v-btn
            icon
            dark
            @click="closeInvoice()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      <InvoiceEdit @saved="load = null" v-if="load" :load="load" />
    </v-card>
    </v-dialog>
    <Load @saved="getLoads" />
  </v-container>
</template>

<script>
import config from "@/config";
import axiosInstance from "@/plugins/axios";
import * as dayjs from 'dayjs'
import Load from "@/components/layout/Load";
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import LoadStatusSelect from "@/components/loads/LoadStatusSelect";
import InvoiceEdit from "./InvoiceEdit.vue";
export default {
  components: {
    Load,
    CounterpartySelect,
    LoadStatusSelect,
    InvoiceEdit
},
  data() {
    return {
      invoiceDialog: false,
      load: null,
      loading: false,
      invoices: false,
      apiParams: null,
      headers: [
        {
          text: 'Load #',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Ship date', value: 'date_ship', sortable: false, },
        { text: 'Delivery date', value: 'date_delivery', sortable: false, },
        { text: 'Customer', value: 'customer', sortable: false, },
        { text: 'Driver/Carrier', value: 'carrier', sortable: false, },
        { text: 'Dispatcher', value: 'dispatcher.name', sortable: false, },
        { text: 'Action', value: 'action', sortable: false, },
      ],
      loads: [],
      filtersData: {
        search: null,
        team_id: [],
        dispatcher_id: null,
        shipDates: [],
        deliveryDates: [],
        status: [],
        withInvoice: null
      },
      loadInView: null,
      search: null,
      filters: null,
    }
  },
  computed: {
    breads() {
      return [
        {
          text: 'TRANSIMEX Global',
          disabled: false,
          href: '/',
        },
        {
          text: 'Accounting manager',
          disabled: true,
        },
      ]
    },
    shipDatesRangeText() {
      return this.filtersData.shipDates.join(' ~ ')
    },
    deliveryDatesRangeText() {
      return this.filtersData.deliveryDates.join(' ~ ')
    },
  },
  mounted() {
    this.getLoads()
  },
  methods: {
    closeInvoice () {
      this.invoiceDialog = null
      this.load = null
    },
    invoice(item) {
      this.invoiceDialog = true
      this.load = item
    },
    handleClick(item) {
      this.$store.commit('openLoad', item)
    },
    onOptionUpdate(options) {
      this.apiParams = options
      this.getLoads()
    },
    getLoads() {
      this.loading = true
      axiosInstance.get(config.apiUrl + '/api/load', {
        params: Object.assign(
          {},
          this.apiParams,
          this.filtersData,
          { shipDates: JSON.parse(JSON.stringify(this.filtersData.shipDates)).sort() },
          { deliveryDates: JSON.parse(JSON.stringify(this.filtersData.deliveryDates)).sort() }
        )
      })
        .then(response => {
          this.loads = response.data
          this.loading = false
        })
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
  },
  watch: {
    filtersData: {
      handler() {
        this.getLoads()
      },
      deep: true
    }
  }
}
</script>
