<template>
  <v-combobox
      class="mini"
      hide-details="auto"
      :value="value"
      :items="addresses"
      dense outlined
      item-value="id"
      :item-text="addressLabel"
      return-object
      :label="label ? label : ''"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
  ></v-combobox>
</template>

<script>
import axios from "@/plugins/axios";
import config from "@/config";

export default {
  name: "LoadLocationSelect",
  props: {
    value: {
      type: [String],
      default () {
        return null
      }
    },
    counterpartyId: {
      type: [Number, null],
      required: false
    },
    label: {
      type: [String, null],
      required: false
    }
  },
  data () {
    return {
      addresses: []
    }
  },
  mounted() {
    this.getAddresses()
  },
  methods: {
    getAddresses () {
      if (this.counterpartyId) {
        axios.get(config.apiUrl + '/api/counterparty/' + this.counterpartyId + '/addresses')
            .then(response => {
              this.addresses = response.data
              if (response.data.length === 1) {
                this.$emit('change', response.data[0])
              }
            })
      }
    },
    addressLabel (address) {
      return [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
        return !!addressPart
      }).join(', ')
    }
  },
  watch: {
    counterpartyId (value) {
      if (value === null) {
        this.addresses = []
      } else {
        this.getAddresses()
      }
    }
  }
}
</script>

<style scoped>

</style>
