import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Bank accounts ")]),_c(VCardSubtitle,[_vm._v(" Overview of you bank accounts ")]),_c(VCardText,[_c(VRow,_vm._l((_vm.companies.list),function(company){return _c(VCol,{attrs:{"md":"6"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(company.name)+" : ")]),_c('div',{staticClass:"mb-4"},[_c('small',[_vm._v(_vm._s(company.meta.check))])]),_c(VBtn,{attrs:{"block":"","color":"success"},on:{"click":function($event){return _vm.add(company.id)}}},[_vm._v("Add new account")]),_vm._l((_vm.showAccounts(company.id)),function(account,idx){return _c(VCard,{staticClass:"my-3"},[_c(VCardText,[_c(VRow,[_c(VCol,[_vm._v(" Account name: "),_c(VTextField,{attrs:{"hide-details":""},model:{value:(account.name),callback:function ($$v) {_vm.$set(account, "name", $$v)},expression:"account.name"}}),_c(VBtnToggle,{staticClass:"my-3"},[_c(VBtn,{attrs:{"small":"","text":"","color":"secondary"},on:{"click":function($event){return _vm.update(account)}}},[_vm._v("Update")]),_c(VBtn,{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){account.id ? _vm.remove(account.id) : _vm.removeNoId(idx)}}},[_vm._v("Remove")])],1),_c('div',[_c('small',[_vm._v("Last updated: "+_vm._s(_vm.formatDate(account.updated_at)))])])],1),_c(VCol,[_vm._v(" Balance: "),_c(VTextField,{attrs:{"type":"number","hide-details":""},model:{value:(account.balance),callback:function ($$v) {_vm.$set(account, "balance", $$v)},expression:"account.balance"}}),_c(VSelect,{attrs:{"items":_vm.currencies,"hide-details":""},model:{value:(account.currency),callback:function ($$v) {_vm.$set(account, "currency", $$v)},expression:"account.currency"}})],1)],1)],1)],1)})],2)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }