<template>
  <div class="py-3">
    <h4>Comments:</h4>
    <v-card outlined style="height: 300px; overflow-y: scroll;">
      <v-card-text>
        <i v-if="comments.length == 0">No comments ...</i>
        <v-row v-for="item in comments">
          <div class="d-flex w-100 px-3 align-items-center justify-space-between">
            <v-chip class="my-2">
              <strong>{{ item.created_by.name }}</strong>: {{ item.comment }}
            </v-chip>
            <small>
            {{ formatDate(item.created_at) }}
          </small>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="mt-3 d-flex align-items-center">
      <v-textarea v-model="comment" hide-details rows="1"  placeholder="Comment ..." outlined></v-textarea>
      <blocking-layover :blocking-condition="restrictedEdit" style="width:unset">
        <v-btn @click="sendComment" x-large color="success">Send</v-btn>
      </blocking-layover>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import axiosInstance from '@/plugins/axios';
import * as dayjs from 'dayjs'
import BlockingLayover from '../layout/BlockingLayover.vue';
import loadLayoverMixin from '@/mixins/loadLayoverMixin';
export default {
  name: "Comments",
  components: {
    BlockingLayover
  },
  mixins: [
    loadLayoverMixin,
  ],
  props: {
    loadId: {
      type: Number,
      required: true
    },
    load: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      comments: [],
      comment: null
    }
  },
  mounted() {
    this.getComments()
  },
  computed: {
    restrictedEdit() {
      return this.load ? this.restrictLoadEdit(this.load) : false
    },
  },
  methods: {
    sendComment() {
      axiosInstance.post(config.apiUrl + '/api/load/' + this.loadId + '/comments', {comment: this.comment, created_by: this.$store.state.auth.user.counterparties[0].id}).then((response) => {
        this.getComments()
        this.comment = null
        this.$emit('addedComment')
      })
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : 'Not set'
    },
    getComments() {
      axiosInstance.get(config.apiUrl + '/api/load/' + this.loadId + '/comments').then((response) => {
        this.comments = response.data
      })
    }
  },
  watch: {
    loadId: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.getComments()
      }
    }
  }
}
</script>