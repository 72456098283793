<template>
  <div>
    <v-data-table v-model="selectedLoads" @input="$emit('loads-selected', $event)" :show-select="showSelect" :options="defaultOptions" :headers="headers" :items="loads.data" :loading="loading" item-key="id"
      @update:options="onOptionUpdate" :server-items-length="loads.meta.total" :custom-sort="items => items" :footer-props="{
        'items-per-page-options': [30, 50, 100, 300]
      }" dense :sort-desc.sync="apiParams.sortDesc" class="table-hover table-bordered accounting-table custom-table">
        <template v-slot:item.id="{ item }">

          <div class="d-flex justify-content-between">
            <a class="link-underline" @click="handleClick(item)">
              #{{ item.id }}
            </a>
            <a v-if="item" @click="openNotesModal(item)">
              <v-icon small :color="hasLoadNotes(item) ? 'green' : 'gray'">
                mdi-comment-text-multiple-outline
              </v-icon>
            </a>
          </div>

        </template>

      <template v-slot:item.date_ship="{ item }">
        <span class="link mini" v-for="dates  in  item.load_carriers[0].load_carrier_shippers">
          {{ formatDate(dates.date) }}
        </span>
      </template>
      <template v-slot:item.date_delivery="{ item }">
        <span class="link mini" v-for="dates  in  item.load_carriers[0].load_carrier_consignees">
          {{ formatDate(dates.date) }}
        </span>
      </template>
      <template v-slot:item.customer="{ item }">
          <a class="link" text @click="editItem(item.load_finances.customer_id)">
            {{ item.load_finances.customer ? item.load_finances.customer.name : '-' }}
          </a>
      </template>
      <template v-slot:item.carrier="{ item }">
        <template  class="link" v-if="item.load_carriers.length === 1">
          <a class="link" text @click="editItem(item.load_carriers[0].carrier_id)">
            {{ item.load_carriers[0]?.carrier ? item.load_carriers[0].carrier.name : '' }}
          </a>

        </template>
        <template class="link" v-else-if="item.load_carriers.length > 1">

          <span style="color: green">Multiple carriers</span>
        </template>
        <div style="color: red;" v-else>
          Carrier not assigned
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn color="black" text small @click="handleClick(item)">
          <v-icon small>mdi-square-edit-outline</v-icon> Edit
        </v-btn>
        <v-btn v-if="item.status === 'delivered'" class="ml-3" color="green" text dark @click="updateStatus(item.id)"
          small>
          <v-icon x-small>mdi-check-all</v-icon> To Completed
        </v-btn>
        <v-btn v-if="item.status === 'completed'" class="ml-3" color="green" text dark @click="invoice(item)" small>
          <v-icon x-small>mdi-file-document-outline</v-icon> Create invoice
        </v-btn>
      </template>
    </v-data-table>

    <Load @saved="getLoads" />
    <v-dialog v-model="invoiceDialog" fullscreen hide-overlay transition="none">
      <v-card flat tile>
        <v-toolbar dark color="black" dense>
          <v-toolbar-title>Invoice for Load# {{ load? load.id : '' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

            <v-btn icon dark @click="closeInvoice()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <InvoiceEdit @saved="closeInvoice" v-if="load" :load="load" />
      </v-card>
    </v-dialog>
    <CounterpartyEdit @saved="getApiData()" v-if="$store.state.users.inEdit" :counterparty="$store.state.users.inEdit">
    </CounterpartyEdit>

    <LoadNotes
        v-if="notesOpened"
        :load-id="loadForModal.id"
        @notesUpdated="getLoads"
        @close="notesOpened = false"
        :is-notes-opened="notesOpened"
        :can-save-note="true"
    />
  </div>
</template>

<script>
import config from "@/config";
import axios from "@/plugins/axios";
import * as dayjs from 'dayjs'
import Load from "@/components/layout/Load";
import InvoiceEdit from "../InvoiceEdit.vue";
import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import { EventBus } from "@/helpers/eventBus";
import queryString from 'query-string';
import LoadNotes from "@/components/loads/LoadNotes.vue";
export default {
  props: {
    params: {
      type: Object,
      default: {}
    },
    search: {
      type: String,
      default: null
    },
    showSelect: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoadNotes,
    Load,
    InvoiceEdit,
    CounterpartyEdit
  },
  data () {
    return {
      invoiceDialog: false,
      loading: false,
      inv: null,
      load: null,
      loads: {
        data: [],
        meta: {
          total: null
        }
      },
      apiParams: null,
      headers: [{ text: 'Load #', align: 'start', sortable: true, value: 'id' },
        { text: 'Ship date', value: 'date_ship', sortable: true, },
        { text: 'Delivery date', value: 'date_delivery', sortable: true, },
        { text: 'Customer', value: 'customer', sortable: true, },
        { text: 'Driver/Carrier', value: 'carrier', sortable: false, },
        { text: 'Dispatcher', value: 'dispatcher.name', sortable: false, },
        { text: 'Action', value: 'action', sortable: false, }
      ],
      selectedLoads: [],
      notesOpened: false,
      loadForModal: null,
    }
  },
  created() {
    this.apiParams = this.params
    EventBus.$on('exportAccountingLoads', this.exportLoads)
  },
  computed: {
    defaultOptions() {
      return localStorage.getItem('accountingLoadTableOptions') ? JSON.parse(localStorage.getItem('accountingLoadTableOptions')) : {}
    }
  },
  methods: {
    hasLoadNotes(item) {
      return item.notes.some(obj => obj.value !== null && obj.value)
    },
    openNotesModal(item) {
      this.loadForModal = item
      this.notesOpened = true
    },
    editItem(id) {
      axios.get(config.apiUrl + '/api/counterparty/' + id).then(
        (response) => {
          this.$store.state.users.inEdit = response.data
        }
      )

    },
    updateStatus(id) {
      this.loading = true
      axios.patch(config.apiUrl + '/api/load/' + id + '/attribute', {
        attribute: "status",
        value: "completed"
      }).then((response) => {
        this.getLoads()
        this.loading = false
      }).finally(() => {
        this.$emit('toTab', 1)
      })
    },
    handleClick(item) {
      this.$store.dispatch('loadLoad', item.id)
    },
    onOptionUpdate(options) {
      localStorage.setItem('accountingLoadTableOptions', JSON.stringify(options))
      this.apiParams = options
      this.getLoads()
    },
    exportLoads(options, tabsModel) {
      let q = Object.assign(
          options,
          this.params,
          this.apiParams
      )
      let query = JSON.parse(JSON.stringify(q))
      
      let status

      switch(tabsModel) {
        case 0: {
          status = ['delivered']
          break;
        }
        case 1: {
          status = ['completed']
          break;
        }
        default: {
          throw new Exception("tabsModel recieved unexpected, unsupported value: " + tabsModel)
        }
      }

      if(JSON.stringify(status) == JSON.stringify(q.status)) {
        q.status = status
        window.open(config.apiUrl + '/api/reports/accounting/loads?' + queryString.stringify(query, {arrayFormat: 'bracket'}), '_blank')
      }
    },
    getLoads() {
      this.loading = true
      axios.get(config.apiUrl + '/api/load' ,{
        params: Object.assign(
          {},
          this.params,
          this.apiParams
        )
      })
        .then((response) => {
          this.loads = response.data
        }).finally(() => {
          this.loading = false
        })
    },
    closeInvoice() {
      this.invoiceDialog = null
      this.load = null
      this.getLoads()
    },
    invoice(item) {
      this.invoiceDialog = true
      this.load = item
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
  },
  watch: {
    params: {
      handler () {
        this.getLoads()
      },
      deep: true
    }
  }
}
</script>
