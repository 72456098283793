<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <strong v-if="name">{{ name }}</strong>
      <hr v-if="name">
        <div>
          <apexchart type="bar" :height="height" :options="chartOptions" :series="data.series"></apexchart>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
 
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      required: true
    },
    count: {
      type: Boolean,
      required: true
    },
    setColors: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      default: 350
    },
    setLegend: {
      type: Boolean,
      default: false
    },
    setDatalabels: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            distributed: true,
          }
        },
        colors: this.setColors,
        dataLabels: {
          enabled: this.setDatalabels
        },
        legend: {
          show: this.setLegend,
        },
        xaxis: {
          categories: this.data.categories ?? [],
          floating: true,
          position: 'bottom',
          labels: {
            show: true,
            style: {
              colors: this.setColors,
              fontSize: '12px'
            },
            offsetY: -290,
            rotate: -90,
          }
        }
      },
    }
  }
}
</script>