<template>
  <span>{{ contactIdentifier }}</span>
</template>

<script>
export default {
  name: "CounterpartyContact",
  props: {
    counterparty: {
      type: Object,
      required: true
    },
    contactType: {
      type: String,
      default: null
    }
  },
  computed: {
    contact () {
      if (this.contactType === 'user') {
        return this.counterparty['user'] ? this.counterparty['user']['email'] : '-'
      }
      for (let item of this.counterparty['counterparty_contacts']) {
        if (item.type === this.contactType) {
          return item;
        }
      }
      return typeof this.counterparty['counterparty_contacts'][0] !== "undefined" ? this.counterparty['counterparty_contacts'][0] : null
    },
    contactIdentifier () {
      return this.contact ? this.contact.contact : '-'
    }
  }
}
</script>

<style scoped>

</style>
