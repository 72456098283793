<template>
  <div class="pagination-wrapper">
    <button
        class="prev_btn"
        @click="prevPage" :disabled="currentPage === 1">
      <v-icon x-small>mdi-chevron-left</v-icon>
    </button>
    <button
        v-for="page in pages"
        :key="page"
        @click="changePage(page)"
        :class="{ active: currentPage === page }"
    >
      {{ page }}
    </button>
    <button
        class="next_btn"
        @click="nextPage" :disabled="currentPage === totalPages">
      <v-icon x-small>mdi-chevron-right</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: "Paginator",
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pages() {
      let startPage, endPage;
      if (this.totalPages <= 10) {
        startPage = 1;
        endPage = this.totalPages;
      } else {
        if (this.currentPage <= 6) {
          startPage = 1;
          endPage = 10;
        } else if (this.currentPage + 4 >= this.totalPages) {
          startPage = this.totalPages - 9;
          endPage = this.totalPages;
        } else {
          startPage = this.currentPage - 5;
          endPage = this.currentPage + 4;
        }
      }
      return [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.$emit('page-changed', this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.$emit('page-changed', this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.$emit('page-changed', this.currentPage);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-wrapper {
  align-items: center;

  button {
    margin: 0 2px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
  }
  button.active {
    font-weight: bold;
    color: #112d4e;
    border-bottom: 1px solid #112d4e;
  }
  button:disabled {
    cursor: not-allowed;
  }

  .prev_btn, .next_btn {
    margin: 0 0 2px;
  }

  .prev_btn > i, .next_btn > i {
    border: 1px solid #112d4e;
    border-radius: 100%;
  }

}

</style>
