<template>
  <v-dialog v-if="isActive" :value="true" transition="none" max-width="1000px" @click:outside="closeInvoiceQuickEdit" persistent>
    <v-card flat tile>
      <v-toolbar dark color="black" dense>
        <v-toolbar-title>Invoices bulk edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeInvoiceQuickEdit">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-form ref="form">
          <v-simple-table>
            <tbody>
            <tr v-for="invoice in invoiceData">
              <td>
                <b>{{ invoice.loadId }}</b>
              </td>
              <td>
                <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Invoice date" :rules="[v => !!v || 'Required']" hide-details :value="invoice.invoiceDate" placeholder="Invoice date" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" clearable @click:clear="invoice.invoiceDate = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="invoice.invoiceDate" @change="recalculate(invoice)"></v-date-picker>
                </v-menu>
              </td>
              <td>
                <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Due date" :rules="[v => !!v || 'Required']" hide-details :value="invoice.dueDate" placeholder="Due date" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" clearable @click:clear="invoice.dueDate = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="invoice.dueDate"></v-date-picker>
                </v-menu>
              </td>
              <td>
                <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Date paid" hide-details :value="invoice.datePaid" placeholder="Date paid" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" clearable @click:clear="invoice.datePaid = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="invoice.datePaid"></v-date-picker>
                </v-menu>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-form>
        <div class="text-center mt-5">
          <v-btn :loading="loading" :disabled="loading" color="success" @click="save">SAVE</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import axios from "@/plugins/axios";
import config from "@/config";

export default {
  name: "InvoiceBulkEdit",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    loads: {
      type: Array,
      required: true
    }
  },
  created() {
    this.invoiceData = this.loads.map(load => {
      return {
        loadId: load.id,
        invoiceDate: dayjs().format('YYYY-MM-DD'),
        dueDate: load.load_finances.customer?.customer_data?.payment_term?.days ? dayjs().add(load.load_finances.customer.customer_data.payment_term.days, 'day').format('YYYY-MM-DD') : null,
        datePaid: null,
        days: load.load_finances.customer?.customer_data?.payment_term?.days ?? null
      }
    })
  },
  data() {
    return {
      invoiceData: [],
      loading: false
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true
        axios.post(config.apiUrl + '/api/invoice/batch', this.invoiceData)
            .then((response) => {
              this.$emit('close')
            })
            .catch()
            .finally(() => {
              this.loading = false
            })
      }
    },
    closeInvoiceQuickEdit () {
      if (confirm('Data will be lost!')) {
        this.$emit('close')
      }
    },
    recalculate (invoice) {
      invoice.dueDate = invoice.days ? dayjs(invoice.invoiceDate).add(invoice.days, 'day').format('YYYY-MM-DD') : null
    }
  }
}
</script>

<style scoped>

</style>
