<template>
  <div class="mt-2">
    <v-row align="center" dense>
      <v-col md="3">
        <blocking-layover style="position:unset" :blocking-layover="restrictedEdit">
          <CounterpartySelect :clearable="!restrictedEdit" @input="onCounterpartyChanged" v-model="consignee.consignee" :roles="['consignee']"/>
        </blocking-layover>
      </v-col>
      <v-col md="4">
        <LoadLocationSelect
            :counterpartyId="consignee.consignee ? consignee.consignee.id : null"
            v-model="consignee.location"
            @change="onLocationSelected"
            @input="onLocationInput"
        ></LoadLocationSelect>
      </v-col>
      <v-col md="3">
        <v-menu
            v-model="showDatePickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                hide-details="auto"
                v-model="consignee.date"
                label="Date"
                readonly
                outlined dense clearable
                class="mini"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="consignee.date"
              @input="showDatePickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="2">
        <v-select
            v-model="consignee.time"
          :readonly="timeDisabled ? true : false"
            class="mini"
            label="Time"
            hide-details="auto"
            outlined dense
            :items="times"
            item-text="label"
            item-value="time"
          @click:append="onUseTimeChange"
          :append-icon="appendIcon"
            menu-props="auto"
        ></v-select>
      </v-col>
      <v-col md="5">
        <v-text-field class="mini" v-model="consignee.description" hide-details="auto" label="Description" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field class="mini"  v-model="consignee.type" hide-details="auto" label="Type" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="1">
        <v-text-field class="mini" v-model.number="consignee.quantity" hide-details="auto" label="Qty" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field class="mini" v-model.number="consignee.weight" hide-details="auto" label="Weight" suffix="lbs" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field class="mini" v-model.number="consignee.cost" hide-details="auto" label="Value" suffix="$" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="5">
        <v-text-field class="mini" v-model="consignee.delivery_notes" hide-details="auto" label="Delivery notes" outlined dense clearable>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field class="mini" v-model="consignee.po_numbers" hide-details="auto" label="P.O. Numbers" outlined dense clearable>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import LocationAutocomplete from "@/components/location/LocationAutocomplete";
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import BlockingLayover from '@/components/layout/BlockingLayover.vue';
import LoadLocationSelect from "@/components/loads/LoadLocationSelect";
import time from "@/helpers/time";
import loadLayoverMixin from '@/mixins/loadLayoverMixin';
import dayjs from "dayjs";
export default {
  mixins: [loadLayoverMixin],
  components: {
    LoadLocationSelect,
    CounterpartySelect,
    LocationAutocomplete,
    BlockingLayover,
    datePicker,
  },
  props : {
    consignee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDatePickerMenu: false,
      times: time,
      timeDisabled: true
    }
  },
  mounted () {
    if (!this.consignee.date) {
      this.$nextTick(() => {
      this.consignee.date = dayjs().format('YYYY-MM-DD')
      })
    }
    if (!this.consignee.time) {
      this.consignee.time = null
    } else {
      this.timeDisabled = false
    }
    if (this.timeDisabled) {
      this.consignee.time = null
    }
  },
  computed: {
    restrictedEdit() {
      return this.restrictLoadEdit(this.$store.state.loadInEdit)
    },
    appendIcon () {
      return this.timeDisabled ? 'mdi-alarm-off' : 'mdi-alarm'
    },
  },
  methods: {
    onCounterpartyChanged (value) {
      if (!value) {
        this.consignee.location = null
        this.consignee.location_coordinates = null
        this.consignee.location_data = null
      }
    },
    onLocationSelected (address) {
      if (typeof address === 'string') {
        this.consignee.location = address
      } else if (address === null) {
        this.consignee.location = null
        this.consignee.location_coordinates = null
        this.consignee.location_data = null
      } else {
          this.consignee.location = [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
            return !!addressPart
          }).join(', ')
          this.consignee.country = address.country_code
          this.consignee.state = address.state
          this.consignee.location_parts = address
          if (address && address.location_data && address.location_coordinates) {
            this.consignee.location_coordinates = address.location_coordinates
            this.consignee.location_data = address.location_data
          }
      }
    },
    onLocationInput (address) {
      if (address === null) {
        this.consignee.location = null
        this.consignee.location_coordinates = null
        this.consignee.location_data = null
      } else {
        this.consignee.location = [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
          return !!addressPart
        }).join(', ')
      }
    },
    onUseTimeChange (value) {
      this.timeDisabled = !this.timeDisabled
      if (this.timeDisabled) {
        this.consignee.time = null
      } else {
        this.consignee.time = value ? '12:00:00' : null
      }
    }
  }
}

</script>
