import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTabs,{attrs:{"show-arrows":"","slider-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VBtn,{staticClass:"mt-1",attrs:{"small":"","text":"","color":"secondary"},on:{"click":_vm.add}},[_vm._v(" Add new contact ")]),_c(VSpacer),_vm._l((_vm.counterparty['counterparty_contacts']),function(contact,index){return _c(VTab,{key:'cch-' + index},[_vm._v(" "+_vm._s(contact.type)+" "),_c(VBtn,{attrs:{"x-small":"","text":"","color":"error"},on:{"click":function($event){return _vm.remove(index)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close-box")])],1)],1)})],2),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.counterparty['counterparty_contacts']),function(contact,index){return _c(VTabItem,{key:'ccc-' + index,staticClass:"pt-5",attrs:{"eager":"","transition":false}},[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"mini",attrs:{"rules":_vm.validation.required,"label":"Contact","required":"","dense":"","outlined":""},model:{value:(contact.contact),callback:function ($$v) {_vm.$set(contact, "contact", $$v)},expression:"contact.contact"}})],1),_c(VCol,[_c(VTextField,{staticClass:"mini",attrs:{"label":"Contact person","dense":"","outlined":""},model:{value:(contact.contact_person),callback:function ($$v) {_vm.$set(contact, "contact_person", $$v)},expression:"contact.contact_person"}})],1),_c(VCol,[_c(VSelect,{staticClass:"mini",attrs:{"items":['phone', 'email', 'fax'],"label":"Contact type","dense":"","outlined":"","persistent-hint":""},model:{value:(contact.type),callback:function ($$v) {_vm.$set(contact, "type", $$v)},expression:"contact.type"}})],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }