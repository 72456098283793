<template>

  <v-card outlined>
    <v-card-text>
      <v-tabs v-model="tab" slider-color="transparent">
        <v-tab
            v-for="(item, idx) in consignees"
            :key="idx"
            :draggable="true"
            @dragstart="onDragStart(idx)"
            @dragover.prevent="onDragOver(idx)"
            @dragleave="onDragLeave"
            @drop="onDrop(idx, consignees); tab = idx"
        >
            <span style="pointer-events: all;">Consignee - {{ idx + 1 }}</span>
            <v-icon class="ml-3" color="error" @click="remove(idx)" small>mdi-close</v-icon>
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn v-if="!allowEdit" small color="secondary" @click="add">
          Add new consignee
        </v-btn>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pt-2 px-3">
        <v-tab-item v-for="(item, index) in consignees" :key="index" eager :transition="false">
          <Item :consignee="item"  :index="index" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import Item from './Item.vue';
import dragSwapMixin from "@/mixins/dragSwapMixin";

export default {
  name: "Consignees",
  mixins: [dragSwapMixin],
  components: { Item },
  props:{
    consignees : {
      type : Array,
      required : true,
    },
  },
  computed: {
    allowEdit () {
      return !this.$store.getters.role.includes('admin') && this.loadInEdit.status === 'invoiced'
    },
    loadInEdit () {
      return this.$store.state.loadInEdit;
    },
  },
  data() {
    return {
      tab: null,
    }
  },
  methods: {

    add() {
      this.consignees.push({
        "load_carrier_id": null,
        "consignee_id": null,
        "location": "",
        "location_coordinates": null,
        "location_data": null,
        "date": null,
        "description": null,
        "type": null,
        "quantity": null,
        "weight": null,
        "value": null,
        "delivery_notes": null,
        "po_numbers": null,
        "consignee": null
      })
    },
    remove(idx) {
      this.consignees.splice(idx, 1);
    },
  }
}
</script>
